a[ui-sref], .clickable {
    cursor: pointer;
}

.btn {
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    .semibold();
    font-size: 13px;
    line-height: 1em;
    border: none;
    letter-spacing: 0;
    text-transform: initial;
    background-color: transparent;

    select& {
        appearance: none;
    }

    a& {
        text-decoration: none;
    }

    & + .btn {
        margin-left: 15px;
    }

    &.btn-block {
        display: block;
        width: 100%;
        text-align: center;
        box-sizing: border-box;

        &+.btn {
            margin-left: 0;
        }
    }

    &:focus {
        outline: 0;
    }

    &.btn-1 {
        color: white;
        background: linear-gradient(to right, @dark-blue, @light-blue);
        border-radius: 30px;
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.5);
        padding: 12px 30px;

        &:hover:not([disabled]):not(.disabled) {
            background: linear-gradient(to left, @dark-blue, @light-blue);
            color: @dark;
            animation: push 0.3s linear;
        }

        &.btn-wider {
            padding-left: 50px;
            padding-right: 50px;
        }
    }

    &.btn-outlined {
        background: none;
        border-radius: 30px;
        padding: 10px 28px;
        color: @dark-blue;
        border: 1px solid @dark-blue;
        &.light {
            color: white;
            border: 1px solid white;
        }
        &.black {
            color: black;
            border-color: black;
        }
        &:not([disabled]):not(.disabled) {

            &:hover {
                color: @light-blue;
                border-color: @light-blue;

                &:not(select) {
                    animation: push 0.3s linear;
                }
            }

            &.active, &.active:hover {
                background: @light-blue;
                color: @dark;
                border-color: @light-blue;
            }

        }

        &.light-blue {
            color: @light-blue;
            border-color: @light-blue;
            &:hover:not([disabled]):not(.disabled) {
                color: @dark-blue;
                border-color: @dark-blue;
            }
            &.active, &.active:hover:not([disabled]):not(.disabled) {
                background: @dark-blue;
                color: @dark;
                border-color: @dark-blue;
            }
        }
        &.btn-big {
            border-width: 2px;
        }

        select& {
            background-image: url('../img/pictos/chevron-down-light-grey.svg');
            background-repeat: no-repeat;
            background-position: right 15px top 50%;
            background-size: 1.2em;
        }
    }

    &.btn-big {
        font-size: 16px;
        padding: 18px 50px;
    }

    .btn-icon {
        &:after {
            content: '';
            display: inline-block;
            width: 25px;
            height: 25px;
            text-align: center;
            line-height: 25px;
            border-radius: 50%;
            font-size: 25px;
            background-color: @dark;
            color: white;
            .light();
            margin-left: 10px;
            position: relative;
            top: 3px;
            transition: transform .5s;
            transform: rotate(0deg);
        }
    }
    &.btn-icon-plus {
        .btn-icon();
        &:after {
            content: '+';
        }
        &:hover:not([disabled]):not(.disabled) {
            &:after {
                transform: rotate(90deg);
            }
        }
    }
    &.btn-icon-minus {
        .btn-icon();
        &:after {
            content: '-';
            line-height: 22px;
        }
        &:hover:not([disabled]):not(.disabled) {
            &:after {
                transform: rotate(180deg);
            }
        }
    }
    &.btn-icon-close {
        .btn-icon-plus();
        transform: rotate(45deg);
    }
    &.link-btn {
        color: @grey;

        &:hover:not([disabled]):not(.disabled) {
            text-decoration: underline;
        }

        &.small-link-btn {
            font-size: 12px;
            .regular();
        }

        &.underlined {
            text-decoration: underline;

            &:hover {
                color: @light-blue;
            }
        }

        &.light {
            color: white;

            &.btn-icon-plus:after {
                background-color: white;
                color: @dark;
            }
        }

        &.blue {
            color: @light-blue;
        }
        &.dark-blue {
            color: @dark-blue;
        }

        &.black {
            color: black;
        }

        &.thin {
            .regular();
        }
    }

    &.btn-toggle {
        color: @light-blue;
        .body-m();
        text-transform: uppercase;

        &:after {
            content: '';
            display: inline-block;
            position: relative;
            top: 0.2em;
            width: 1em;
            height: 1em;
            margin-left: 0.3em;
            color: @light-grey;
            background: url('../img/pictos/picto-chevron-down-light-blue.svg') no-repeat center center;
            background-size: contain;
            transition: transform 0.2s;
        }

        &.toggled:after {
            transform: rotate(180deg);
        }
    }

    &[disabled], &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
        &:hover {
            animation: none;
        }
    }

    &.btn-uppercase {
        text-transform: uppercase;
    }

    &.thumbnail-hidden{
        color: white;
        background: linear-gradient(to right, @orange, @red);
        border-radius: 30px;
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.5);
        padding: 12px 30px;

        &:hover:not([disabled]):not(.disabled) {
            background: linear-gradient(to left, @orange, @red);
            color: @dark;
            animation: push 0.3s linear;
        }

        &.btn-wider {
            padding-left: 50px;
            padding-right: 50px;
        }
    }

    &.btn-borderless {
        border: none;
        background: none;
        padding: 0;
        &.white {
            color: white;
        }
        &.light-blue {
            color: @light-blue;
        }
        &.black {
            color: black;
        }
    }
}

.section-light-blue {
    .btn-outlined:hover:not([disabled]):not(.disabled) {
        color: white;
        border-color: white;
    }
}

.buttons-bar {
    display: flex;

    .btn {
        margin: 0 15px;

        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }
}

.alphabet-buttons-grid {
    display: flex;
    flex-wrap: wrap;
    //justify-content: space-between;
    @spacing: 5px;
    margin-left: -@spacing;
    margin-right: -@spacing;
    .btn {
        width: 3em;
        height: 3em;
        box-sizing: content-box;
        padding: 0;
        margin: @spacing;

    }
    // &::after {
    //     content: "";
    //     flex: auto;
    // }
}

a.ghost-link {
    text-decoration: none;
    &:focus {
        outline: none;
    }
}

.btn-switch {
    position: relative;
    display: inline-block;
    padding: 0;
    width: 44px;
    height: 22px;
    background: white;
    border: 1px solid black;
    border-radius: 22px;
    vertical-align: middle;
    cursor: pointer;
    .body-m();

    &:after {
        content: '';
        width: 16px;
        height: 16px;
        background: black;
        position: absolute;
        top: 2px;
        left: 3px;
        border-radius: 50%;
        transition: left 0.1s linear;
    }

    &.switch-on:after {
        background-color: @light-blue;
        left: 23px;
    }

    &:focus {
        outline: none;
    }

    &[disabled] {
        opacity: 0.3;
        cursor: not-allowed;

        & + * {
            opacity: 0.5;
        }
    }
}

.btn-orange {
    background-color: @orange;
    border: none;
}
