.search-results {
    position: absolute;
    top: @header-inner-height + 2 * @header-padding;
    left: 0;
    width: 100%;
    background: white;
    z-index: 6;
    padding: 0 0 @large;
    box-shadow: 0 5px 15px 0 rgba(0,0,0,0.5);

    .results-section {
        padding: @large 0;
        &:not(:first-child) {
            border-top: 1px solid @light-grey;
        }
        .category-title {
            margin: 0  0 @large;
        }
        .section-footer {
            margin-top: @large;
            text-align: right;
        }
    }
    .user-result {
        text-align: center;
        color: inherit;
        text-decoration: none;
        .profile-pic {
            width: 100%;
            height: auto;
        }
        .user-name {
            overflow: hidden;
        }
        &:hover {
            color: @light-blue;
        }
    }
    .no-result {
        .title-5();
        color: @light-blue;
        margin-top: @large;
    }
}