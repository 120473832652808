// temporary
@gutter: 30px;

.pop-up-background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 15px 15px;
    background: rgba(0, 0, 0, 0.6);
    animation: opacity 0.15s;

    @media (max-width: @breakpoint-small) {
        padding-left: 0;
        padding-right: 0;
    }
}

.pop-up {
    .tile();
    width: 700px;
    background-color: white;
    border-radius: 2px;
    padding: @gutter;
    position: relative;
    box-sizing: border-box;
    margin: 50px auto;
    max-width: ~'calc(100% - 40px)' !important;
    animation: opacity-down 0.3s;
    color: @dark;
    z-index: 100;

    .pop-up-title {
        .title-2();
        font-size: 22px;
        margin-top: 20px;
        margin-bottom: 30px;
        color: @dark;

        &.centered {
            text-align: center;
        }
    }

    .pop-up-buttons {
        margin-top: 20px;
        text-align: right;

        .btn {
            width: 30%;
        }

        &.centered {
            text-align: center;
        }
    }

    .btn-close {
        position: absolute;
        top: 12px;
        right: 10px;
    }

    &.page {
        z-index: unset;
    }
}
