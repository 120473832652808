.link-tabs {
    display: flex;
    justify-content: space-between;

    .tab-item {
        color: @grey;
        text-transform: uppercase;
        font-size: 14px;
        text-decoration: none;
        cursor: pointer;
        background: transparent;
        border: none;
        padding: 0 0 5px;
        border-bottom: 2px solid transparent;

        &.no-hover {
            cursor: auto;
        }

        &:hover:not(.no-hover) {
            color: white;
        }

        &:not(.toggle-popin-menu) {
            &.active, &:active, &:focus {
                color: white;
                border-bottom: 2px solid @light-blue;
                padding-bottom: 5px;
                outline: none;
            }
        }
    }

    .tutorial-tab-item {
        color: white;
        text-transform: uppercase;
        font-size: 22px;
        text-decoration: none;
        cursor: pointer;
        background: transparent;
        border: none;
        font-weight: 600;
        padding: 0 0 5px;
        border-bottom: 2px solid transparent;

        &.no-hover {
            cursor: auto;
        }

        &:hover:not(.no-hover) {
            color: @light-blue;
        }

        &:not(.toggle-popin-menu) {
            &.active, &:active, &:focus {
                color: white;
                border-bottom: 2px solid @light-blue;
                padding-bottom: 5px;
                outline: none;
            }
        }
    }

}
