.input, .select, .textarea, .radio, .checkbox {
    box-sizing: border-box;
    margin: 0;
    border-radius: 0;

    &:focus {
        outline: 0;
    }

    &.block {
        width: 100%;
    }
}

.radio-custom {
    display: none;

    & + label {
        position: relative;
        padding-left: 22px;
        cursor: pointer;
        line-height: 18px;
        display: inline-block;
        .body-m();
        color: black;
        margin-right: 1em;

        &:before {
            position: absolute;
            left: 0;
            top: 0;
            content: '';
            display: inline-block;
            width: 16px;
            height: 16px;
            border-radius: 50px;
            border: 1px solid black;
            background: white;
        }

        &:after {
            content: '';
            width: 12px;
            height: 12px;
            background: @light-blue;
            position: absolute;
            top: 3px;
            left: 3px;
            border-radius: 100%;
            opacity: 1;
            transition: all 0.1s linear;
            opacity: 0;
            transform: scale(0);
        }
    }

    &:checked + label:after {
        transform: scale(1);
        opacity: 1;
    }
}

.checkbox-custom {
    display: inline-block;
    line-height: 26px;
    color: @dark;
    cursor: pointer;

    input[type="checkbox"] {
        position: absolute;
        z-index: -1;
        opacity: 0;

        & + .checkbox {
            width: 20px;
            height: 20px;
            background-color: white;
            border-width: 1px;
            border-style: solid;
            border-color: @light-grey;
            border-radius: 2px;
            float: left;
            margin-top: 2px;
            margin-right: 7px;

            & + span {
                font-size: 15px;
                font-weight: bold;
            }

            &.black {
                border-color: black;
            }
        }

        &:checked {
            & + .checkbox {
                background-color: @light-blue;
                background-image: url('../img/white-check.svg');
                background-size: 16px;
                background-position: center center;
                background-repeat: no-repeat;

                &:not(.black) {
                    border-color: @light-blue;
                }
            }
        }
    }

    &.large {
        input[type="checkbox"] + .checkbox {
            width: 22px;
            height: 22px;
            border-width: 2px;

            & + span {
                position: relative;
                top: 2px;
                font-size: 15px;
                font-weight: 500;
            }
        }
    }

}

.textarea.block {
    min-width: 100%;
}

.input, .select, .textarea, .rte {
    max-width: 100%;
    background: #fff;
    color: @dark;
    border: 1px solid @light-grey;

    &.dark {
        border-color: black;
    }

    &.no-border { /* especially for inputs on dark backgrounds */
        border-color: transparent;
    }

    &::placeholder {
        color: @medium-grey;
        opacity: 1; /* Firefox */
    }
}

.input, .select, .textarea {
    overflow: visible;
    -webkit-appearance: none;
    font-size: 13px;
    line-height: 1em;
    padding: 10px 15px;

    &.big {
        padding: 15px 20px;
    }

    &.wide {
        width: 400px;
        max-width: 100%;
    }

    &.bigger-font {
        .body();
    }

    &.line-input {
        border-top: none;
        border-left: none;
        border-right: none;
        padding: 5px 10px;

        &.transparent {
            border-color: @light-grey;
            padding: 8px 0;
        }
    }

    &.dark {
        border-color: @dark;

        &.select:invalid, &.select option[value=""] {
            color: @dark;
        }
    }

    &.transparent {
        background: none;
        color: @light-grey;

        &::placeholder {
            color: @medium-grey;
            opacity: 1; /* Firefox */
        }
    }
}

.input-with-icon {
    display: inline-block;
    position: relative;
    @icon-size: 18px;
    input {
        padding-right: @icon-size + 20px !important;
    }
    .icon {
        width: @icon-size;
        position: absolute;
        top: 50%;
        margin-top: -@icon-size/2;
        right: 10px;
    }
}

img + .line-input {
    margin-left: 10px;
}

.input + .input:not(.block) {
    margin-left: 1px;
}

.input + .btn {
    margin-left: 15px;
}

.input.block + .btn.block {
    margin: 20px 0 0 0;
}

label {
    color: @light-blue;
    font-size: 13px;

    &.label-strong {
        color: @dark;
        .body-s();
        .semibold();
    }

    & + .input.block, & + .uk-grid, & + .select.block {
        margin-top: 0.4em;
    }

    &.black {
        color: black;
    }

    &.blue {
        color: @light-blue;
    }

    & > .btn-switch {
        margin-right: 5px;
    }
}

.rte {
    .pell-actionbar {
        border-bottom-color: @light-grey;

        .pell-button {
            height: 35px;
        }
    }
    h1 {
        .article-inner-title();
    }
    p {
        .article-body();
    }
    .pell-content:empty:before {
        font-size: 13px;
        color: @medium-grey;
    }

    &.tribute .pell-content:empty:before {
        content: 'Rédiger mon hommage';
    }

}

.textarea {
    &.expandable {
        @height: 48px;
        min-height: @height;
        transition: min-height 0.2s;
        line-height: 1em;
        resize: none;
        padding-top: 15px;

        &.expanded {
            min-height: 140px;
        }
    }
    &.no-resize {
        resize: none;
    }
    &.medium {
        height: 100px;
    }
    &.big {
        height: 250px;
    }

    &.textarea-with-mentions {
        textarea {
            border: none;
            padding: 10px 15px;

            &:focus {
                outline: none;
            }
        }
        &.expandable {
            &.expanded {
                min-height: 100px;
                .textarea__control {
                    padding-top: 30px;
                    padding-bottom: 10px;
                }
            }
            textarea {
                padding: 12px 15px;
                overflow-y: visible !important;
            }
        }
        .textarea__suggestions {
            margin-top: calc(14px + 2em) !important;
            max-height: 150px;
            overflow-y: auto;
            border: 1px solid @light-grey;
            left: 50%; // trick positionning issue on react-mention

            li:not(:first-child) {
                border-top: 1px solid @light-grey;
            }
        }
        .mention-user {
            display: flex;
            align-items: center;
            padding: 5px;
            .body-m();
            .profile-pic {
                width: 40px;
                height: 40px;
                margin-right: 10px;
            }
            &:hover {
                background: @lighter-grey;
            }
        }
    }
}

.select {
    line-height: 1.2em;
    background-image: url('../img/pictos/triangle-down.svg');
    background-repeat: no-repeat, repeat;
	background-position: right 1em top 50%, 0 0;
    background-size: 1.2em auto, 100%;
    &:invalid, option[value=""] {
        color: @medium-grey; // same as placeholder style, to simulate it
    }

    &.big {
        padding: 10px 2rem 10px 15px;
    }
}

fieldset {
    border: none;
    padding: 0;

    &[disabled] {
        opacity: 0.3;
    }
}

.collapsible-section-head-container {
    padding-bottom: 1em;
}

.collapsible-section-head {
    position: relative;
    display: block;
    width: 100%;
    .title-5();
    text-transform: uppercase;
    color: black;
    padding: 1em 2em 0 0;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;

    &:after {
        content: '';
        display: inline-block;
        position: absolute;
        right: 0;
        width: 1em;
        height: 1em;
        background: url(../img/pictos/triangle-down.svg) center center no-repeat;
        transform: rotate(-90deg);
        transition: transform 0.1s linear;
    }

    &.opened {
        &:after {
            transform: rotate(0);
        }
    }

    &:focus {
        outline: none;
    }

}

.collapsible-subsection-head {
    .collapsible-section-head;
    color: @light-blue;
    text-transform: none;

    &:after {
        background: url(../img/pictos/triangle-down-blue.svg) center center no-repeat;
    }
}

.collapsible-tags-head {
    .title-5();
    padding-top: 3px;
    background: none;
    cursor: pointer;

    &:after {
        content: '';
        display: inline-block;
        width: 1em;
        height: 1em;
        background: url(../img/pictos/triangle-down-blue.svg) center center no-repeat;
        transform: rotate(-90deg);
        transition: transform 0.1s linear;
    }

    &.opened {
        &:after {
            transform: rotate(0);
        }
    }
    
    &:focus {
        outline: none;
    }
}

.input-btn {
    text-transform: uppercase;

    background: none;
    border-radius: 30px;
    padding: 5px 15px;
    border: 1px solid black;
    color: black;
    .semibold();

    &:not([disabled]):not(.disabled) {
        &:hover {
            cursor: pointer;
            color: @light-blue;
            border-color: @light-blue;
            animation: push 0.3s linear;
        }

        &.active, &.active:hover {
            background: @light-blue;
            color: @dark;
            border-color: @light-blue;
        }
    }

    & > *[type="file"] {
        width: 0;
        height: 0;
        opacity: 0;
    }
}

.collapsible-tribe-head {
    .title-5();
    padding-top: 3px;
    background: none;
    
    &.clickable {
        cursor: pointer;
    }

    &:after {
        content: '';
        display: inline-block;
        width: 1em;
        height: 1em;
        background: url(../img/pictos/triangle-down-blue.svg) center center no-repeat;
        transform: rotate(-90deg);
        transition: transform 0.1s linear;
    }

    &.opened {
        &:after {
            transform: rotate(0);
        }
    }
    
    &:focus {
        outline: none;
    }
}
