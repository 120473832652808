.chip {
    display: inline-block;
    background-color: @dark;
    .light();
    color: @light-blue;
    font-size: 12px;
    padding: 7px 15px;
    border-radius: 25px;

    &.chip-white {
        background-color: white;
        color: @light-blue;
        .semibold();
    }

    .picto {
        height: 1.1em;
        top: 1px;
        margin-right: 1px;
    }
}

.counter-chip {
    @size: 20px;
    width: @size;
    height: @size;
    line-height: @size;
    font-size: @size / 2;
    display: inline-block;
    background-color: @light-blue;
    color: white;
    border-radius: @size;
}

.inspiration-image {
    border-radius: 20px;
    width: 100%;
    margin: 10px 0;
}

.profile-pic {
    position: relative;
    width: 150px;
    height: 150px;
    background-color: @medium-grey;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    .ptn-shaped();

    /* profile pic upload component */
    input[type=file] {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;

        & + label {
            position: absolute;
            bottom: 10px;
            right: 10px;
            height: 15px;
            width: 20px;
            height: 20px;
            cursor: pointer;
            img {
                width: 100%;
            }
        }
    }
}

.round-thumbnail, .square-thumbnail {
    display: inline-block;
    width: 100%;
    text-align: center;
    text-decoration: none;

    .pic {
        display: inline-block;
        width: 100%;
        max-width: 300px;
        margin: 0 auto;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;

        canvas {
            width: 100%;
        }
    }

    p {
        padding-top: 5px;
        color: @dark;
        font-size: 13px;
        .semibold();
    }
}
.round-thumbnail {
    .pic {
        border-radius: 50%;
    }
}

/* POPIN-MENU */
.toggle-popin-menu {
    .toggle-button {
        padding: 5px 0;
        .body-m();
        color: @light-grey;
        line-height: 1em;
        cursor: pointer;

        &.active {
            border-bottom: 2px solid @light-blue;
        }

        &:after {
            content: '';
            display: inline-block;
            position: relative;
            top: 0.2em;
            width: 1em;
            height: 1em;
            margin-left: 0.3em;
            color: @light-grey;
            background: url('../img/pictos/chevron-down-light-grey.svg') no-repeat center center;
            background-size: contain;
            transition: transform 0.2s;
        }
    }
    .popin-menu {
        position: absolute;
        left: 0;
        top: 0;
        visibility: hidden;
        opacity: 0;
        transition: all 0.2s;
    }

    &.opened {
        .toggle-button:after {
            transform: rotate(180deg);
        }
        .popin-menu {
            visibility: visible;
            top: 40px;
            opacity: 1;
        }
    }
}
.popin-menu {
    position: relative;
    background-color: @night-blue;
    padding: 40px;
    display: inline-block;
    z-index: 10;
    box-sizing: border-box;

    .item {
        .btn();
        display: inline-block;
        width: 100%;
        text-align: left;
        font-size: 12px;
        line-height: 2em;
        color: white;
        cursor: pointer;
        text-decoration: none;

        &:hover, &.active {
            color: @light-blue;
        }

        &.disabled {
            color: @grey;
            cursor: not-allowed;
        }
    }

    .chevron {
        @size: 20px;
        content: '';
        position: absolute;
        width: @size;
        height: @size;
        background-color:inherit;
        transform: rotate(45deg);
        top: -@size/2;
        left: 50%;
        margin-left: -@size/2;
    }

    .menu-section-title {
        color: white;
        margin: 40px 0 20px;
        text-transform: none;
        .body-s();
        .semibold();
    }

    &.chevron-right:before {
        .chevron();
        left: auto;
        right: 10%;
    }

    &.chevron-left:before {
        .chevron();
        left: 10%;
    }
}

.gallery {
    position: relative;
    .popin-menu {
        box-sizing: border-box;
        position: absolute;
        width: 100%;
        top: 85px;
        left: 0;
    }
    .load-more {
        margin: 20px 0 30px 0;
    }
}

.gallery-head {
    display: flex;
    align-items: center;
    padding: 25px 0 10px 0;
    margin-bottom: 30px;
    border-bottom: 1px solid white;
}

/* EO POPIN-MENU */

/* FILTER CHIPS */
.active-filter-chip {
    background: none;
    border-radius: 30px;
    padding: 5px 40px 5px 20px;
    color: white;
    border: 1px solid white;
    text-transform: none;

    .btn-icon-close {
        position: absolute;
        right: 0;
        top: -6px;
        z-index: 2;
        &:after {
            background: transparent;
        }
    }

    &:hover {
        color: @light-blue;
        border-color: @light-blue;

        .btn-icon-close {
            color: @light-blue;
        }
    }

    & + & {
        margin-left: 10px;
    }
}

/* INLINE-SLIDE-SELECTOR */
.inline-slide-selector {
    padding: 15px 0 15px 30px;
    .iss-item {
        color: white;
        text-decoration: none;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 400;

        &:not(:first-child) {
            margin-left: 1em;
        }

        &.active, &:hover {
            color: @light-blue;
        }
    }
    .slick-prev, .slick-next {
        width: 30px;
        height: 30px;
        &:hover {
            background-color: @dark;
        }
    }

}
/* EO INLINE-SLIDE-SELECTOR */

/* UPLOAD-PIC */
.custom-upload {
    input[type=file] {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
    label {
        font-size: 16px;
        color: @light-blue;
        cursor: pointer;
        img {
            width: 30px;
        }
    }
    & + .uploaded-img {
        margin-top: 0.5em;
        width: 100%;
    }
}
.upload-box {
    .custom-upload();
    background-color: @light-grey;
    padding: 200px 50px;
    text-align: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;

    &.preview {
        background-color: transparent;
    }
}
.light-upload-box {
    .upload-box();
    background-color: transparent;
    padding: 5px;
    label {
        margin: 0;
    }

    &.preview {
        background-size: cover;
        label {
            opacity: 0;
        }
        &:hover {
            label {
                opacity: 0.8;
            }
        }
    }
}
/* EO UPLOAD-PIC */

/* SLIDE SWITCH */
@slide-switch-height: 40px;
.slide-switch {
    display: inline-block;
    position: relative;
    width: 100%;
    max-width: 500px;
    height: @slide-switch-height;
    border-radius: @slide-switch-height/2;
    background-color: darken(@light-blue, 10%);

    @option-width: 20%;

    .switch-cursor {
        position: absolute;
        display: inline-block;
        left:  @option-width;
        width:  @option-width;
        height: @slide-switch-height;
        border-radius: @slide-switch-height/2;
        background-color: @dark;
        opacity: 0.8;
        z-index: 1;
        transition: all 0.2s;

        &.pos-1 {
            left: 0;
            opacity: 1;
        }

        &.pos-2 {
            left: 50%;
            margin-left: -@option-width/2;
            opacity: 1;
        }

        &.pos-3 {
            left: 100%;
            margin-left: -@option-width;
            opacity: 1;
        }
    }

    .switch-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: space-between;
        height: 100%;
        z-index: 2;
    }

    .switch-option {
        width:  @option-width;
        height: 100%;
        line-height: @slide-switch-height;
        cursor: pointer;

        .icon {
            display: inline-block;
            height: @slide-switch-height - 16px;
            margin-top: 8px;
            transition: transform 0.2s;
        }

        &:hover {
            .icon {
                transform: scale(1.2);
            }
        }

        .switch-caption {
            margin: 0;
            padding: 0;
            font-size: 14px;
            line-height: 1em;
            color: darken(@light-blue, 20%);
            transition: none;

            .main {
                display: inline-block;
                width: 100%;
                font-weight: 600;
            }
        }
    }

    &:not(.static) .switch-option.active {
        .switch-caption {
            color: white;
        }
    }

    &.static {
        .switch-cursor {
            display: none;
        }
        .switch-option {
            cursor: initial;

            &:hover {
                .icon {
                    transform: none;
                }
            }

            &.active {
                color: initial;
            }
        }
    }
}
/* SLIDE SWITCH */

/* STEPS */
.steps {
    .flex-center();

    .step {
        padding: 0 20px;
        text-align: center;
        .thin();
        font-size: 22px;

        &.current {
            .semibold();

            & + .sep {
                visibility: visible;
                opacity: 0.2;
            }
        }

        &.done {
            .semibold();
            color: @light-blue;

            & + .sep {
                visibility: visible;
                background-color: @light-blue;
            }
        }
    }
    .sep {
        width: 200px;
        height: 1px;
        background: @dark;
        visibility: hidden;
    }
}
/* STEPS */

.anonymity-and-certification {
    height: 400px;
    overflow-y: auto;
    .custom-scroll();
}

/* TITLE NAV PREV/NEXT */
/* TODO: refactor cf. slick-slider prev/next */

@arrow-size: 60px;

.title-with-nav {
    position: relative;
    padding-left: @arrow-size;
    padding-right: @arrow-size;
    .arrow {
        display: inline-block;
        width: @arrow-size;
        height: @arrow-size;
        position: absolute;
        top: 50%;
        margin-top: -@arrow-size/2;
        background-size: cover;
        z-index: 2;
        background-color: @night-blue;
        background-position: center center;
        &:before {
            display: none;
        }
        &:hover {
            background-color: @light-blue;
        }
        &.slick-disabled {
            pointer-events: none;
            opacity: 0.2;
        }

        &.prev {
            left: 0;
            background-image: url('../img/slider-prev.png');
            &:hover {
                background-position: -5px;
            }
        }
        &.next {
            right: 0;
            background-image: url('../img/slider-next.png');
            &:hover {
                background-position: 5px;
            }
        }
    }
}

/* PREV/NEXT */

/* PROFILE > PAANTEON PROCESS */
.paanteon-process {
    height: 544px;
    box-sizing: border-box;
    background: @dark-blue-2 url('../img/paanteon-bg.png') bottom center no-repeat;
    background-size: contain;
    color: white;
    padding: 20px;
    padding-top: 5%;

    .title {
        text-align: center;
        .title-2();
        .light();
        margin-bottom: 10px;
    }

    .subtitle {
        text-align: center;
        .light();
        strong {
            .semibold();
            color: @light-blue;
        }
    }

    img {
        display: block;
        margin: 12% auto auto;
        width: 100%;
        max-width: 700px;
    }

    .btn {
        margin-top: 12%;
    }
}
/* PROFILE > PAANTEON PROCESS */


/* LIST OF USERS */
.users-list {
    margin: 20px 0; // TODO: would be better not to be handled here, for it's specific to a single occurence...
    .user-item {
        display: flex;
        align-items: center;
        justify-content: left;
        margin: 10px 0;
        .profile-pic {
            width: 50px;
            height: 50px;
            margin-right: 10px;
        }
        font-size: 14px;
        .semibold();
        color: black;
    }
}
/* LIST OF USERS */

.gallery-grid {
    & > div {
        margin-bottom: 20px;
    }

    &.condensed {
        /* just one row of 3 thumbs displayed for screens <= 1200px (@breakpoint-large) */
        & > div:nth-child(n+4) {
            display: none;
        }
        /* 4 thumbs for bigger screens */
        @media (min-width: @breakpoint-large) {
            & > div:nth-child(4) {
                display: initial;
            }
        }
    }

    .collection-title + & {
        margin-top: 20px;
    }
}

/* find a better place to write this rule?*/
.tribute-quick-actions {
    .h-center();
    .padding-top();

    span {
        padding: 0 20px;
    }
}

/* pending invitation component */
.pending-invitation {
    display: flex;
    align-items: center;
    width: ~"calc(100% - 30px)"; // create room for right top corner's options menu
    .profile-pic {
        flex-shrink: 0;
        width: 60px;
        height: 60px;
    }
    .btn {
        width: 20%;
    }
    .user-info {
        flex-grow: 1;
        .body-s();
        .semibold();
        color: black;
        margin-left: 20px;
        padding-right: 10px;

        & > * {
            width: 100%;
        }
    }
    .bio {
        .margin-top-s();
    }
}

/* options menu component (contextual menu represented by three dots button) */
.options-menu {
    display: inline-block;
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 2;
    transition: border-color 0.3s linear;
    background: white;

    ul {
        display: none;
        color: black;
        list-style-type: none;
        .body-s();
        padding: 7px 100px 7px 20px;

        li {
            margin: 5px 0;
        }
    }

    a {
        text-decoration: none;

        &:hover {
            color: @light-blue;
        }
    }

    .icon {
        content: '';
        display: inline-block;
        position: absolute;
        top: 10px;
        right: 10px;
        width: 20px;
        height: 10px;
        background: url('../img/pictos/picto-options.svg') no-repeat right center;
        background-size: contain;
        cursor: pointer;
        z-index: 6;
        transition: background-image 0.3s linear;
    }

    &.open  {
        border: 1px solid @light-grey;
        ul {
            display: inline-block;
        }
        .icon {
            background-image: url('../img/pictos/picto-close-blue.svg');
        }
    }
}

/* tribute writing bloc (tribute's article edition RTE, optionnal and collapsed by default) */
.tribute-writing-bloc {
    position: relative;
    .btn, .rte {
        /* all these properties are used for animation*/
        position: relative;
        top: 0;
        opacity: 1;
        transition: all 0.5s;
        overflow: hidden;
    }
    &.collapsed {
        .btn, .rte {
            height: 0;
            opacity: 0;
            position: absolute;
            top: -30px;
        }
    }
    .action-icons {
        @icon-width: 20px;
        position: absolute;
        top: 10px;
        right: 20px;
        width: @icon-width;
        text-align: right;

        a, button {
            padding: 0;
            display: inline-block;
             &:not(:first-child) {
                 margin-top: 10px;
             }

             img {
                 width: @icon-width;
             }
        }
    }
}

.load-more {
    width: 300px;
    height: 1px;
    background: @grey;
    display: inline-block;
    position: relative;

    &:after {
        content: '';
        width: 30px;
        height: 30px;
        background: @dark url('../img/pictos/picto-chevron-down-white.svg') no-repeat center center;
        position: absolute;
        top: -15px;
        left: 135px;
        cursor: pointer;
        border-radius: 50%;
    }

    &.reverse:after {
        transform: rotate(180deg);
    }
}

.dark-blue .load-more:after {
    background-color: @dark-blue;
}

.scroll-to-top-container {
    position: fixed;
    right: 20px;
    bottom: 40px;
    .btn();
    z-index: 20;

    .scroll-to-top {
        width: 30px;
        height: 1px;
        display: inline-block;
        position: relative;
        &::after {
            position: absolute;
            width: 30px;
            height: 30px;
            background: transparent url('../img/pictos/picto-chevron-down-white.svg') no-repeat center center;
            transform: rotate(180deg);
            content: '';
        }
    }
}

.drop-menu {
    position: absolute;
    background-color: white;
    padding: 16px;
    border: 1px solid @light-grey;
    min-width: 200px;
    z-index: 2;

    &.top-right {
        top: 44px;
        right: 2px;
    }

    &.top-left {
        top: 2px;
        left: 2px;
    }

    &.closed {
        visibility: hidden;
    }

    .btn-close {
        position: absolute;
        right: 2px;
        top: 4px;

        img {
            height: 1em;
        }
    }

    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
            padding-bottom: 0.3em;
        }
    }

    .link-btn {
        .body-m();
        color: black;
        padding: 10px 0;

        &:hover {
            color: @light-blue;
        }
    }

    .drop-title {
        .title-5();
        border-bottom: 1px solid @light-grey;
        padding-bottom: 12px;
    }

    .drop-footer {
        border-top: 1px solid @light-grey;
        padding-top: 12px;

        .link {
            padding: 0;
            .body-m();
        }
    }

    .drop-body {
        padding: 8px 0;
    }
}

@media (max-width: @breakpoint-medium) {
    .header .navbar .navbar-right .drop-menu.top-right {
        top: 0 !important;
    }

    .header .drop-menu {
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        overflow-y: auto;
        z-index: 30;

        &:not(.closed) {
            .menu-slide-in();
        }
        &.closed {
            .menu-slide-out();
            transition: all 0s ease 0.5s;
            transform: translateX(100vw);
        }

        .drop-inner {
            max-width: 350px;
            margin: 0 auto;
        }

        .drop-picto {
            position: relative;
            display: inline-block;
            margin-right: 10px;
            width: 30px;
            height: 30px;
            top: 7px;

            &.profile-pic {
                background-color: #848484;
                background-position: center center;
                background-size: cover;
                background-repeat: no-repeat;
                border-radius: 50% 50% 0 50%;
            }
        }

        .btn-close {
            position: absolute;
            top: 10px;
            right: 10px;

            img {
                width: 20px;
                height: 20px;
            }
        }

        .drop-title {
            text-align: left;

            .drop-text {
                .title-5();
            }
        }

        .drop-body {
            text-align: left;
        }

        .drop-body-section {
            border-bottom: 1px solid @light-grey;
            padding-left: 40px;
        }

        .drop-footer {
            text-align: right;
            border-top: none;
        }
    }
}

.info {
    background: @dark-blue;
    padding: 10px;
    border-radius: 3px;
    color: white;
    .body-s();

    &.minor {
        background: @light-grey;
    }
}

.caption {
    position: absolute;
    background-color: fade(@dark, 80%);
    width: 300px;

    padding: 30px 35px;

    &.simple-caption {
        text-align: center;
        width: 200px;
        padding-top: 35px;
        padding-bottom: 35px;
    }

    .caption-title {
        text-transform: uppercase;
        .light();
        font-size: 14px;
        color: @light-blue;
        line-height: 1.5em;
        margin-bottom: 7px;
    }
    .caption-content {
        color: white;
        .semibold();
        font-size: 20px;
        line-height: 1.5em;
    }
    .caption-description {
        color: white;
        .light();
        font-size: 14px;
    }

    .caption-footer {
        margin-top: 15px;

        .picto {
            float: right;
            color: white;
            position: absolute;
            top: auto;
            bottom: 30px;
            right: 35px;
            height: 30px;
        }

        .chip .picto {
            clear: both;
            position: static;
            width: 13px;
            height: auto;
            margin-left: 7px;
        }
    }
}
.profile-grid {
    .flex();
    .space-between();
    width: 100%;
    margin-top: 20px;

    .profile-grid-first {
        flex: 1;
        margin-right: 20px;
    }

    .sidebar-block {
        .block-title + p {
            line-height: 1.1em;
            height: 2.2em;
        }
    }
}

/* JODIT RTE overrides */
.jodit-toolbar-button__button {

    // dropdowns options selected style
	&:active:not([disabled]),
	&[aria-pressed='true']:not([disabled]) {
        text-decoration: underline;
        color: @light-blue;
        .semibold();
	}
}

.inspiration-feed-wall {
    .ptn-em a {
        text-decoration: none;

        &:hover {
            color: inherit;
            text-decoration: underline;
        }
    }
}

.slick-slide .slide {
    .bottom-overlay();
}

.img-copyright {
    .body();
    font-size: 10px;
    position: absolute;
    bottom: 10px;
    right: 15px;
    color: #aaa;
}

.cookie-banner {
    position: fixed;
    font-size: 12px;
    bottom: 0;
    padding: 25px 30px;
    background-color: black;
    color: @lighter-grey;
    z-index: 30;
}

.picture-preview {
    position: relative;
    max-width: 500px;
    width: 100%;
    .bottom-overlay();
}

.tuto-slide {

    .tuto-slide-head {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: @large;
        .tuto-slide-title {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: @small 0;

            & > * {
                margin: 0 @xs;
                font-weight: 600;
                font-size: 30px;
            }
            .slide-number {
                color: white;
                background-color: @light-blue;
                border-radius: 50%;
                padding: 2px @small;
            }
            .slide-label {
                color: @light-blue;
            }
        }
    }
    .tuto-slide-body{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        .step1, .step2{
            width: 50%;
        }

        .step1 {
            .tutorial-popin-menu {
                position: relative;
                background-color: transparent;
                display: inline-block;
                z-index: 1;
                box-sizing: border-box;

                .link-tabs {
                    position: relative;
                    background-color: @night-blue;
                    padding: 40px;
                    margin: 0;
                    z-index: 3;
                }
                
                .collection-items{
                    position: relative;
                    display: none;
                    animation: slideIn 0.5s forwards ease-in-out;
                    background-color: @night-blue;
                    padding: 0 40px 40px;
                    z-index: 2;
                    
                    &.opened{
                        display: block;
                    }
                    .item {
                        .btn();
                        display: inline-block;
                        width: 100%;
                        text-align: left;
                        font-size: 12px;
                        line-height: 2em;
                        color: white;
                        cursor: pointer;
                        text-decoration: none;
                
                        &:hover, &.active {
                            color: @light-blue;
                        }
                
                        &.disabled {
                            color: @grey;
                            cursor: not-allowed;
                        }
                    }
                }
            }  
        }

        .step2{
            border-radius: @small;
            padding: 0 @normal;
        }

        @media (max-width : @breakpoint-medium) {
            .step1, .step2{
                width: 90%
            }
        }
    }
}

.rounded-progress-bar {
    --pgPercentage: var(--value);
    margin-bottom: 3px;

    animation: growProgressBar 3s 1 forwards;
    border-radius: 50%;
    display: grid;
    place-items: center;
    background: radial-gradient(closest-side, white 80%, transparent 0 99.9%, white 0),
        conic-gradient(@light-blue calc(var(--pgPercentage) * 1%), 
        @lighter-grey 0);
    color: @light-blue;

    &.small {
        width: 2.5rem;
        height: 2.5rem;
        font-size: 10px;
    }

    &.medium{
        width: 4rem;
        height: 4rem;
        font-size: 14px;
    }

    &.large{
        width: 5rem;
        height: 5rem;
        font-size: 20px;
    }

    .rounded-progress-bar__value::after {
        counter-reset: percentage var(--value);
        content: counter(percentage) '%';
        font-weight: 600;
        color: @light-blue
    }
}

@keyframes slideIn {
    0% {
        transform: translateY(-80%) scaleY(0%);
    }
    100% {
        transform: translateY(0) scaleY(100%);
    }
}

@keyframes growProgressBar {
    0%, 33% { --pgPercentage: 0; }
    100% { --pgPercentage: var(--percentage); }
  }

@property --pgPercentage {
    syntax: '<number>';
    inherits: false;
    initial-value: 0;
}