@keyframes push {
    50% {
        transform: scale(0.8);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes ptn-shape-anim {
    0% {
        .ptn-shaped();
    }
    25% {
        .ptn-shaped();
        border-bottom-right-radius: 50%;
        border-top-right-radius: 0;
    }
    50% {
        .ptn-shaped();
        border-bottom-right-radius: 50%;
        border-top-left-radius: 0;
    }
    75% {
        .ptn-shaped();
        border-bottom-right-radius: 50%;
        border-bottom-left-radius: 0;
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes menu-slide-in {
    0% {
        transform: translateX(100vw);
    }
    100% {
        transform: translateX(0);
    }
}
.menu-slide-in() {
    animation: menu-slide-in 0.5s ease-out;
}

@keyframes menu-slide-out {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(100vw);
    }
}
.menu-slide-out() {
    animation: menu-slide-out 0.5s ease-in;
}
