/* ui-kit framework */
/* ========================================================================
   Component: Container
 ========================================================================== */
/*
 * 1. Box sizing has to be `content-box` so the max-width is always the same and
 *    unaffected by the padding on different breakpoints. It's important for the size modifiers.
 */
.uk-container {
  /* 1 */
  box-sizing: content-box;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}
/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-container {
    padding-left: 30px;
    padding-right: 30px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-container {
    padding-left: 40px;
    padding-right: 40px;
  }
}
/*
 * Micro clearfix
 */
.uk-container::before,
.uk-container::after {
  content: "";
  display: table;
}
.uk-container::after {
  clear: both;
}
/*
 * Remove margin from the last-child
 */
.uk-container > :last-child {
  margin-bottom: 0;
}
/*
 * Remove padding from nested containers
 */
.uk-container .uk-container {
  padding-left: 0;
  padding-right: 0;
}
/* Size modifier
 ========================================================================== */
.uk-container-xsmall {
  max-width: 750px;
}
.uk-container-small {
  max-width: 900px;
}
.uk-container-large {
  max-width: 1600px;
}
.uk-container-expand {
  max-width: none;
}
/* Expand modifier
 ========================================================================== */
/*
 * Expand one side only
 */
.uk-container-expand-left {
  margin-left: 0;
}
.uk-container-expand-right {
  margin-right: 0;
}
/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-container-expand-left.uk-container-xsmall,
  .uk-container-expand-right.uk-container-xsmall {
    max-width: calc(50% + (750px / 2) - 30px);
  }
  .uk-container-expand-left.uk-container-small,
  .uk-container-expand-right.uk-container-small {
    max-width: calc(50% + (900px / 2) - 30px);
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-container-expand-left,
  .uk-container-expand-right {
    max-width: calc(50% + (1200px / 2) - 40px);
  }
  .uk-container-expand-left.uk-container-xsmall,
  .uk-container-expand-right.uk-container-xsmall {
    max-width: calc(50% + (750px / 2) - 40px);
  }
  .uk-container-expand-left.uk-container-small,
  .uk-container-expand-right.uk-container-small {
    max-width: calc(50% + (900px / 2) - 40px);
  }
  .uk-container-expand-left.uk-container-large,
  .uk-container-expand-right.uk-container-large {
    max-width: calc(50% + (1600px / 2) - 40px);
  }
}
/* Item
 ========================================================================== */
/*
 * Utility classes to reset container padding on the left or right side
 * Note: It has to be negative margin on the item, because it's specific to the item.
 */
.uk-container-item-padding-remove-left,
.uk-container-item-padding-remove-right {
  width: calc(100% + 15px);
}
.uk-container-item-padding-remove-left {
  margin-left: -15px;
}
.uk-container-item-padding-remove-right {
  margin-right: -15px;
}
/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-container-item-padding-remove-left,
  .uk-container-item-padding-remove-right {
    width: calc(100% + 30px);
  }
  .uk-container-item-padding-remove-left {
    margin-left: -30px;
  }
  .uk-container-item-padding-remove-right {
    margin-right: -30px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-container-item-padding-remove-left,
  .uk-container-item-padding-remove-right {
    width: calc(100% + 40px);
  }
  .uk-container-item-padding-remove-left {
    margin-left: -40px;
  }
  .uk-container-item-padding-remove-right {
    margin-right: -40px;
  }
}
/* ========================================================================
   Component: Grid
 ========================================================================== */
/*
 * 1. Allow cells to wrap into the next line
 * 2. Reset list
 */
.uk-grid {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none;
}
/*
 * Grid cell
 * Note: Space is allocated solely based on content dimensions, but shrinks: 0 1 auto
 * Reset margin for e.g. paragraphs
 */
.uk-grid > * {
  margin: 0;
}
/*
 * Remove margin from the last-child


.uk-grid > * > :last-child { margin-bottom: 0; }
 */
/* Gutter
 ========================================================================== */
/*
 * Default
 */
/* Horizontal */
.uk-grid {
  margin-left: -30px;
}
.uk-grid > * {
  padding-left: 30px;
}
/* Vertical */
.uk-grid + .uk-grid,
.uk-grid > .uk-grid-margin,
* + .uk-grid-margin {
  margin-top: 30px;
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid {
    margin-left: -40px;
  }
  .uk-grid > * {
    padding-left: 40px;
  }
  /* Vertical */
  .uk-grid + .uk-grid,
  .uk-grid > .uk-grid-margin,
  * + .uk-grid-margin {
    margin-top: 40px;
  }
}
/*
 * Small
 */
/* Horizontal */
.uk-grid-small {
  margin-left: -15px;
}
.uk-grid-small > * {
  padding-left: 15px;
}
/* Vertical */
.uk-grid + .uk-grid-small,
.uk-grid-small > .uk-grid-margin,
* + .uk-grid-margin-small {
  margin-top: 15px;
}
/*
 * Medium
 */
/* Horizontal */
.uk-grid-medium {
  margin-left: -30px;
}
.uk-grid-medium > * {
  padding-left: 30px;
}
/* Vertical */
.uk-grid + .uk-grid-medium,
.uk-grid-medium > .uk-grid-margin,
* + .uk-grid-margin-medium {
  margin-top: 30px;
}
/*
 * Large
 */
/* Horizontal */
.uk-grid-large {
  margin-left: -40px;
}
.uk-grid-large > * {
  padding-left: 40px;
}
/* Vertical */
.uk-grid + .uk-grid-large,
.uk-grid-large > .uk-grid-margin,
* + .uk-grid-margin-large {
  margin-top: 40px;
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid-large {
    margin-left: -70px;
  }
  .uk-grid-large > * {
    padding-left: 70px;
  }
  /* Vertical */
  .uk-grid + .uk-grid-large,
  .uk-grid-large > .uk-grid-margin,
  * + .uk-grid-margin-large {
    margin-top: 70px;
  }
}
/*
 * Collapse
 */
/* Horizontal */
.uk-grid-collapse {
  margin-left: 0;
}
.uk-grid-collapse > * {
  padding-left: 0;
}
/* Vertical */
.uk-grid + .uk-grid-collapse,
.uk-grid-collapse > .uk-grid-margin {
  margin-top: 0;
}
/* Divider
 ========================================================================== */
.uk-grid-divider > * {
  position: relative;
}
.uk-grid-divider > :not(.uk-first-column)::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  border-left: 1px solid #e5e5e5;
}
/* Vertical */
.uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  border-top: 1px solid #e5e5e5;
}
/*
 * Default
 */
/* Horizontal */
.uk-grid-divider {
  margin-left: -60px;
}
.uk-grid-divider > * {
  padding-left: 60px;
}
.uk-grid-divider > :not(.uk-first-column)::before {
  left: 30px;
}
/* Vertical */
.uk-grid-divider.uk-grid-stack > .uk-grid-margin {
  margin-top: 60px;
}
.uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
  top: -30px;
  left: 60px;
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid-divider {
    margin-left: -80px;
  }
  .uk-grid-divider > * {
    padding-left: 80px;
  }
  .uk-grid-divider > :not(.uk-first-column)::before {
    left: 40px;
  }
  /* Vertical */
  .uk-grid-divider.uk-grid-stack > .uk-grid-margin {
    margin-top: 80px;
  }
  .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
    top: -40px;
    left: 80px;
  }
}
/*
 * Small
 */
/* Horizontal */
.uk-grid-divider.uk-grid-small {
  margin-left: -30px;
}
.uk-grid-divider.uk-grid-small > * {
  padding-left: 30px;
}
.uk-grid-divider.uk-grid-small > :not(.uk-first-column)::before {
  left: 15px;
}
/* Vertical */
.uk-grid-divider.uk-grid-small.uk-grid-stack > .uk-grid-margin {
  margin-top: 30px;
}
.uk-grid-divider.uk-grid-small.uk-grid-stack > .uk-grid-margin::before {
  top: -15px;
  left: 30px;
}
/*
 * Medium
 */
/* Horizontal */
.uk-grid-divider.uk-grid-medium {
  margin-left: -60px;
}
.uk-grid-divider.uk-grid-medium > * {
  padding-left: 60px;
}
.uk-grid-divider.uk-grid-medium > :not(.uk-first-column)::before {
  left: 30px;
}
/* Vertical */
.uk-grid-divider.uk-grid-medium.uk-grid-stack > .uk-grid-margin {
  margin-top: 60px;
}
.uk-grid-divider.uk-grid-medium.uk-grid-stack > .uk-grid-margin::before {
  top: -30px;
  left: 60px;
}
/*
 * Large
 */
/* Horizontal */
.uk-grid-divider.uk-grid-large {
  margin-left: -80px;
}
.uk-grid-divider.uk-grid-large > * {
  padding-left: 80px;
}
.uk-grid-divider.uk-grid-large > :not(.uk-first-column)::before {
  left: 40px;
}
/* Vertical */
.uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin {
  margin-top: 80px;
}
.uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin::before {
  top: -40px;
  left: 80px;
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid-divider.uk-grid-large {
    margin-left: -140px;
  }
  .uk-grid-divider.uk-grid-large > * {
    padding-left: 140px;
  }
  .uk-grid-divider.uk-grid-large > :not(.uk-first-column)::before {
    left: 70px;
  }
  /* Vertical */
  .uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin {
    margin-top: 140px;
  }
  .uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin::before {
    top: -70px;
    left: 140px;
  }
}
/* Match child of a grid cell
 ========================================================================== */
/*
 * Behave like a block element
 * 1. Wrap into the next line
 * 2. Take the full width, at least 100%. Only if no class from the Width component is set.
 * 3. Expand width even if larger than 100%, e.g. because of negative margin (Needed for nested grids)
 */
.uk-grid-match > *,
.uk-grid-item-match {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
}
.uk-grid-match > * > :not([class*='uk-width']),
.uk-grid-item-match > :not([class*='uk-width']) {
  /* 2 */
  box-sizing: border-box;
  width: 100%;
  /* 3 */
  flex: auto;
}
/* ========================================================================
   Component: Width
 ========================================================================== */
/* Equal child widths
 ========================================================================== */
[class*='uk-child-width'] > * {
  box-sizing: border-box;
  width: 100%;
}
.uk-child-width-1-2 > * {
  width: 50%;
}
.uk-child-width-1-3 > * {
  width: calc(100% * 1 / 3.001);
}
.uk-child-width-1-4 > * {
  width: 25%;
}
.uk-child-width-1-5 > * {
  width: 20%;
}
.uk-child-width-1-6 > * {
  width: calc(100% * 1 / 6.001);
}
.uk-child-width-auto > * {
  width: auto;
}
/*
 * 1. Reset the `min-width`, which is set to auto by default, because
 *    flex items won't shrink below their minimum intrinsic content size.
 *    Using `1px` instead of `0`, so items still wrap into the next line,
 *    if they have zero width and padding and the predecessor is 100% wide.
 */
.uk-child-width-expand > :not([class*='uk-width']) {
  flex: 1;
  /* 1 */
  min-width: 1px;
}
/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-child-width-1-1\@s > * {
    width: 100%;
  }
  .uk-child-width-1-2\@s > * {
    width: 50%;
  }
  .uk-child-width-1-3\@s > * {
    width: calc(100% * 1 / 3.001);
  }
  .uk-child-width-1-4\@s > * {
    width: 25%;
  }
  .uk-child-width-1-5\@s > * {
    width: 20%;
  }
  .uk-child-width-1-6\@s > * {
    width: calc(100% * 1 / 6.001);
  }
  .uk-child-width-auto\@s > * {
    width: auto;
  }
  .uk-child-width-expand\@s > :not([class*='uk-width']) {
    flex: 1;
    min-width: 1px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-child-width-1-1\@m > * {
    width: 100%;
  }
  .uk-child-width-1-2\@m > * {
    width: 50%;
  }
  .uk-child-width-1-3\@m > * {
    width: calc(100% * 1 / 3.001);
  }
  .uk-child-width-1-4\@m > * {
    width: 25%;
  }
  .uk-child-width-1-5\@m > * {
    width: 20%;
  }
  .uk-child-width-1-6\@m > * {
    width: calc(100% * 1 / 6.001);
  }
  .uk-child-width-auto\@m > * {
    width: auto;
  }
  .uk-child-width-expand\@m > :not([class*='uk-width']) {
    flex: 1;
    min-width: 1px;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-child-width-1-1\@l > * {
    width: 100%;
  }
  .uk-child-width-1-2\@l > * {
    width: 50%;
  }
  .uk-child-width-1-3\@l > * {
    width: calc(100% * 1 / 3.001);
  }
  .uk-child-width-1-4\@l > * {
    width: 25%;
  }
  .uk-child-width-1-5\@l > * {
    width: 20%;
  }
  .uk-child-width-1-6\@l > * {
    width: calc(100% * 1 / 6.001);
  }
  .uk-child-width-auto\@l > * {
    width: auto;
  }
  .uk-child-width-expand\@l > :not([class*='uk-width']) {
    flex: 1;
    min-width: 1px;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-child-width-1-1\@xl > * {
    width: 100%;
  }
  .uk-child-width-1-2\@xl > * {
    width: 50%;
  }
  .uk-child-width-1-3\@xl > * {
    width: calc(100% * 1 / 3.001);
  }
  .uk-child-width-1-4\@xl > * {
    width: 25%;
  }
  .uk-child-width-1-5\@xl > * {
    width: 20%;
  }
  .uk-child-width-1-6\@xl > * {
    width: calc(100% * 1 / 6.001);
  }
  .uk-child-width-auto\@xl > * {
    width: auto;
  }
  .uk-child-width-expand\@xl > :not([class*='uk-width']) {
    flex: 1;
    min-width: 1px;
  }
}
/* Single Widths
 ========================================================================== */
/*
 * 1. `max-width` is needed for the pixel-based classes
 */
[class*='uk-width'] {
  box-sizing: border-box;
  width: 100%;
  /* 1 */
  max-width: 100%;
}
/* Halves */
.uk-width-1-2 {
  width: 50%;
}
/* Thirds */
.uk-width-1-3 {
  width: calc(100% * 1 / 3.001);
}
.uk-width-2-3 {
  width: calc(100% * 2 / 3.001);
}
/* Quarters */
.uk-width-1-4 {
  width: 25%;
}
.uk-width-3-4 {
  width: 75%;
}
/* Fifths */
.uk-width-1-5 {
  width: 20%;
}
.uk-width-2-5 {
  width: 40%;
}
.uk-width-3-5 {
  width: 60%;
}
.uk-width-4-5 {
  width: 80%;
}
/* Sixths */
.uk-width-1-6 {
  width: calc(100% * 1 / 6.001);
}
.uk-width-5-6 {
  width: calc(100% * 5 / 6.001);
}
/* Pixel */
.uk-width-small {
  width: 150px;
}
.uk-width-medium {
  width: 300px;
}
.uk-width-large {
  width: 450px;
}
.uk-width-xlarge {
  width: 600px;
}
.uk-width-xxlarge {
  width: 750px;
}
/* Auto */
.uk-width-auto {
  width: auto;
}
/* Expand */
.uk-width-expand {
  flex: 1;
  min-width: 1px;
}
/* Phone landscape and bigger */
@media (min-width: 640px) {
  /* Whole */
  .uk-width-1-1\@s {
    width: 100%;
  }
  /* Halves */
  .uk-width-1-2\@s {
    width: 50%;
  }
  /* Thirds */
  .uk-width-1-3\@s {
    width: calc(100% * 1 / 3.001);
  }
  .uk-width-2-3\@s {
    width: calc(100% * 2 / 3.001);
  }
  /* Quarters */
  .uk-width-1-4\@s {
    width: 25%;
  }
  .uk-width-3-4\@s {
    width: 75%;
  }
  /* Fifths */
  .uk-width-1-5\@s {
    width: 20%;
  }
  .uk-width-2-5\@s {
    width: 40%;
  }
  .uk-width-3-5\@s {
    width: 60%;
  }
  .uk-width-4-5\@s {
    width: 80%;
  }
  /* Sixths */
  .uk-width-1-6\@s {
    width: calc(100% * 1 / 6.001);
  }
  .uk-width-5-6\@s {
    width: calc(100% * 5 / 6.001);
  }
  /* Pixel */
  .uk-width-small\@s {
    width: 150px;
  }
  .uk-width-medium\@s {
    width: 300px;
  }
  .uk-width-large\@s {
    width: 450px;
  }
  .uk-width-xlarge\@s {
    width: 600px;
  }
  .uk-width-xxlarge\@s {
    width: 750px;
  }
  /* Auto */
  .uk-width-auto\@s {
    width: auto;
  }
  /* Expand */
  .uk-width-expand\@s {
    flex: 1;
    min-width: 1px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  /* Whole */
  .uk-width-1-1\@m {
    width: 100%;
  }
  /* Halves */
  .uk-width-1-2\@m {
    width: 50%;
  }
  /* Thirds */
  .uk-width-1-3\@m {
    width: calc(100% * 1 / 3.001);
  }
  .uk-width-2-3\@m {
    width: calc(100% * 2 / 3.001);
  }
  /* Quarters */
  .uk-width-1-4\@m {
    width: 25%;
  }
  .uk-width-3-4\@m {
    width: 75%;
  }
  /* Fifths */
  .uk-width-1-5\@m {
    width: 20%;
  }
  .uk-width-2-5\@m {
    width: 40%;
  }
  .uk-width-3-5\@m {
    width: 60%;
  }
  .uk-width-4-5\@m {
    width: 80%;
  }
  /* Sixths */
  .uk-width-1-6\@m {
    width: calc(100% * 1 / 6.001);
  }
  .uk-width-5-6\@m {
    width: calc(100% * 5 / 6.001);
  }
  /* Pixel */
  .uk-width-small\@m {
    width: 150px;
  }
  .uk-width-medium\@m {
    width: 300px;
  }
  .uk-width-large\@m {
    width: 450px;
  }
  .uk-width-xlarge\@m {
    width: 600px;
  }
  .uk-width-xxlarge\@m {
    width: 750px;
  }
  /* Auto */
  .uk-width-auto\@m {
    width: auto;
  }
  /* Expand */
  .uk-width-expand\@m {
    flex: 1;
    min-width: 1px;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Whole */
  .uk-width-1-1\@l {
    width: 100%;
  }
  /* Halves */
  .uk-width-1-2\@l {
    width: 50%;
  }
  /* Thirds */
  .uk-width-1-3\@l {
    width: calc(100% * 1 / 3.001);
  }
  .uk-width-2-3\@l {
    width: calc(100% * 2 / 3.001);
  }
  /* Quarters */
  .uk-width-1-4\@l {
    width: 25%;
  }
  .uk-width-3-4\@l {
    width: 75%;
  }
  /* Fifths */
  .uk-width-1-5\@l {
    width: 20%;
  }
  .uk-width-2-5\@l {
    width: 40%;
  }
  .uk-width-3-5\@l {
    width: 60%;
  }
  .uk-width-4-5\@l {
    width: 80%;
  }
  /* Sixths */
  .uk-width-1-6\@l {
    width: calc(100% * 1 / 6.001);
  }
  .uk-width-5-6\@l {
    width: calc(100% * 5 / 6.001);
  }
  /* Pixel */
  .uk-width-small\@l {
    width: 150px;
  }
  .uk-width-medium\@l {
    width: 300px;
  }
  .uk-width-large\@l {
    width: 450px;
  }
  .uk-width-xlarge\@l {
    width: 600px;
  }
  .uk-width-xxlarge\@l {
    width: 750px;
  }
  /* Auto */
  .uk-width-auto\@l {
    width: auto;
  }
  /* Expand */
  .uk-width-expand\@l {
    flex: 1;
    min-width: 1px;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  /* Whole */
  .uk-width-1-1\@xl {
    width: 100%;
  }
  /* Halves */
  .uk-width-1-2\@xl {
    width: 50%;
  }
  /* Thirds */
  .uk-width-1-3\@xl {
    width: calc(100% * 1 / 3.001);
  }
  .uk-width-2-3\@xl {
    width: calc(100% * 2 / 3.001);
  }
  /* Quarters */
  .uk-width-1-4\@xl {
    width: 25%;
  }
  .uk-width-3-4\@xl {
    width: 75%;
  }
  /* Fifths */
  .uk-width-1-5\@xl {
    width: 20%;
  }
  .uk-width-2-5\@xl {
    width: 40%;
  }
  .uk-width-3-5\@xl {
    width: 60%;
  }
  .uk-width-4-5\@xl {
    width: 80%;
  }
  /* Sixths */
  .uk-width-1-6\@xl {
    width: calc(100% * 1 / 6.001);
  }
  .uk-width-5-6\@xl {
    width: calc(100% * 5 / 6.001);
  }
  /* Pixel */
  .uk-width-small\@xl {
    width: 150px;
  }
  .uk-width-medium\@xl {
    width: 300px;
  }
  .uk-width-large\@xl {
    width: 450px;
  }
  .uk-width-xlarge\@xl {
    width: 600px;
  }
  .uk-width-xxlarge\@xl {
    width: 750px;
  }
  /* Auto */
  .uk-width-auto\@xl {
    width: auto;
  }
  /* Expand */
  .uk-width-expand\@xl {
    flex: 1;
    min-width: 1px;
  }
}
/* ========================================================================
   Component: Visibility
 ========================================================================== */
/*
 * Hidden
 * `hidden` attribute also set here to make it stronger
 */
[hidden],
.uk-hidden {
  display: none !important;
}
/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-hidden\@s {
    display: none !important;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-hidden\@m {
    display: none !important;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-hidden\@l {
    display: none !important;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-hidden\@xl {
    display: none !important;
  }
}
/*
 * Visible
 */
/* Phone portrait and smaller */
@media (max-width: 639px) {
  .uk-visible\@s {
    display: none !important;
  }
}
/* Phone landscape and smaller */
@media (max-width: 959px) {
  .uk-visible\@m {
    display: none !important;
  }
}
/* Tablet landscape and smaller */
@media (max-width: 1199px) {
  .uk-visible\@l {
    display: none !important;
  }
}
/* Desktop and smaller */
@media (max-width: 1599px) {
  .uk-visible\@xl {
    display: none !important;
  }
}
/* Visibility
 ========================================================================== */
.uk-invisible {
  visibility: hidden !important;
}
/* Based on the State of the Parent Element
 ========================================================================== */
/*
 * Can't use `display: none` nor `visibility: hidden` because both are not focusable.
 * The target stays visible if any element within receives focus through keyboard.
 */
/*
 * Discard space when hidden.
 */
.uk-visible-toggle:not(:hover):not(:focus) .uk-hidden-hover:not(:focus-within) {
  position: absolute !important;
  width: 0 !important;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden !important;
}
/*
 * Keep space when hidden.
 */
.uk-visible-toggle:not(:hover):not(:focus) .uk-invisible-hover:not(:focus-within) {
  opacity: 0 !important;
}
/* Based on Hover Capability of the Pointing Device
 ========================================================================== */
/*
 * Hover
 */
/* Hide if primary pointing device doesn't support hover, e.g. touch screens. */
@media (hover: none) {
  .uk-hidden-touch {
    display: none !important;
  }
}
/* Hide if primary pointing device supports hover, e.g. mice. */
@media (hover) {
  .uk-hidden-notouch {
    display: none !important;
  }
}
/* Generic */
.sidebar {
  box-sizing: border-box;
  width: 300px;
  float: left;
  padding: 0;
}
.sidebar + .main-content {
  padding-left: 320px;
}
.sidebar.sidebar-right {
  float: right;
}
.sidebar.sidebar-ptn {
  margin-top: -45px;
}
.sidebar.sidebar-collapsible.collapsed {
  width: 45px;
  transition: width 0.2s linear;
}
.sidebar.sidebar-collapsible.collapsed > *:not(.sidebar-profile) {
  display: none;
}
.sidebar.sidebar-collapsible.collapsed .sidebar-profile {
  height: 45px;
  box-sizing: border-box;
}
.sidebar.sidebar-collapsible.collapsed .sidebar-profile > *:not(.collapse-btn) {
  display: none;
}
.sidebar.sidebar-collapsible.collapsed .collapse-btn img {
  transform: rotate(180deg);
}
.sidebar.sidebar-collapsible.collapsed + .main-content {
  padding-left: 65px;
  padding-right: 65px;
}
.main-content.with-sidebar-right {
  padding-right: 320px;
}
.sidebar-head {
  width: 100%;
  height: 250px;
  background-position: center center;
  background-size: cover;
}
.sidebar-block {
  position: relative;
  background-color: white;
  padding: 20px;
  color: #121e2c;
}
.sidebar-block + .sidebar-block {
  margin-top: 20px;
}
.sidebar-block.fixed-ratio .block-ratio {
  width: 100%;
}
.sidebar-block.fixed-ratio .block-content {
  position: absolute;
  top: 20px;
  right: 20px;
  bottom: 20px;
  left: 20px;
  border: 1px solid white;
  padding: 20px;
  display: flex;
  align-items: center;
}
.sidebar-block.fixed-ratio .block-title {
  border: none;
}
.sidebar-block .block-title {
  text-transform: uppercase;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.1em;
  font-weight: 600;
  padding-bottom: 10px;
  border-bottom: 1px solid #121e2c;
  margin-bottom: 10px;
}
.sidebar-block .block-title.no-border {
  border: none;
}
.sidebar-block .block-title.left-aligned {
  text-align: left;
}
.sidebar-block .block-title + .block-title {
  margin-top: 20px;
}
.sidebar-block .block-title a {
  display: block;
  margin: 7px 0;
  font-weight: 400;
  font-size: 16px;
  font-size: 12px;
  letter-spacing: initial;
  text-transform: none;
  text-decoration: none;
}
.sidebar-block .block-title a.smaller,
.sidebar-block .block-title a .smaller {
  font-weight: 100;
}
.sidebar-block .block-title + .content-card {
  margin-top: 20px;
}
.sidebar-block.dark {
  background-color: #121e2c;
  color: white;
}
.sidebar-block.dark .block-title {
  border-color: white;
}
.sidebar-block.dark-blue {
  background-color: #121e2c;
  color: white;
  background-color: #074377;
}
.sidebar-block.dark-blue .block-title {
  border-color: white;
}
.sidebar-block.night-blue {
  background-color: #121e2c;
  color: white;
  background-color: #070f18;
}
.sidebar-block.night-blue .block-title {
  border-color: white;
}
.sidebar-block.secondary {
  text-align: center;
  padding: 40px;
}
.sidebar-block.secondary .block-title {
  color: #37a8db;
  border-color: #37a8db;
  margin-bottom: 20px;
}
.sidebar-block.secondary p {
  font-size: 14px;
  color: block;
  font-weight: 400;
  margin-bottom: 20px;
}
.sidebar-block .content-card:not(:first-of-type) {
  margin-top: 20px;
}
.sidebar-block .content-card + .btn {
  margin-top: 20px;
}
.sidebar-sep {
  height: 1px;
  width: 100%;
  background-color: #ddd;
  margin: 20px 0;
}
.sidebar-profile {
  position: relative;
  z-index: 2;
}
.sidebar-profile .profile-pic {
  margin: -40% auto 20px;
}
.sidebar-profile .collapse-btn {
  display: inline-block;
  position: absolute;
  padding: 0;
  top: 8px;
  right: 6px;
  cursor: pointer;
}
.sidebar-profile .collapse-btn img {
  height: 25px !important;
  transform: rotate(0);
  transition: transform 0.3s linear;
}
.sidebar-profile .name {
  font-weight: 600;
  font-size: 18px;
  text-align: center;
}
.sidebar-profile .name img {
  padding-left: 7px;
  width: 1rem;
  height: 1rem;
}
.sidebar-profile .username {
  text-align: center;
  font-size: 14px;
  color: grey;
  margin: 4px;
}
.sidebar-profile .city {
  font-size: 14px;
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
}
.sidebar-profile .desc {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5em;
}
.sidebar-profile .socials {
  margin-top: 14px;
}
.sidebar-profile .socials > div {
  margin: 5px 0;
}
.sidebar-profile .socials a {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 400;
  font-size: 16px;
  font-size: 14px;
}
.sidebar-profile .socials a:not(:first-child) {
  margin-left: 12px;
}
.sidebar-profile .socials img {
  width: 24px;
}
.sidebar-profile .socials span {
  display: inline-block;
  width: 100%;
}
.sidebar-profile .stats {
  margin-bottom: 40px;
}
.sidebar-profile .stats p {
  color: #074377;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5em;
  margin: 0;
}
.sidebar-profile .stats a {
  text-decoration: none;
}
.sidebar-profile .affinity {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  font-size: 14px;
}
.sidebar-profile .affinity .icon {
  width: 1em;
  height: 1em;
  margin: 0 5px;
}
.main-content {
  padding-top: 20px;
  padding-bottom: 40px;
}
.main-content-s {
  padding-top: 20px;
  padding-bottom: 40px;
  max-width: 700px;
}
.page-head {
  position: relative;
  background-position: center center;
  background-size: cover;
  height: 300px;
  background-color: #d2d3d5;
  /* profile cover image upload component */
}
.page-head:not(.simple-head):before {
  content: '';
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 70px;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
}
.page-head h1 {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 300px;
  width: calc( 100% - 300px );
  text-align: center;
  color: white;
  font-size: 40px;
  letter-spacing: 0.1em;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 15px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
}
.page-head h1 .text-finish:after {
  bottom: 10px;
}
.page-head h1 .ptn-of {
  font-weight: 100;
  text-transform: initial;
  font-size: 0.6em;
}
.page-head input[type=file] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.page-head input[type=file] + label {
  position: absolute;
  top: 15px;
  left: 15px;
  height: 15px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.page-head input[type=file] + label img {
  width: 100%;
}
.page-head.simple-head {
  background-color: #121e2c;
  height: 200px;
}
.page-head.simple-head h1 {
  font-weight: 100;
  font-size: 40px;
  bottom: 20%;
  left: 0;
  width: 100%;
}
.page-head .caption {
  bottom: 30px;
  left: 30px;
}
.section {
  padding: 60px 0;
  background-color: white;
  color: #070f18;
}
.section.section-condensed {
  padding-top: 30px;
}
.section.section-dark {
  background-color: #121e2c;
  color: white;
}
.section.section-dark.with-gradient {
  background-image: radial-gradient(rgba(55, 168, 219, 0.25), rgba(255, 255, 255, 0) 70%);
}
.section.section-dark .section-title:before {
  border-color: white;
}
.section.section-light-blue {
  background-color: #37a8db;
  color: white;
}
.section.small-section {
  padding: 20px 0;
}
.section + .section:not(.force-padding) {
  padding-top: 0;
}
.section.--no-select {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.main-font {
  font-family: 'Work Sans', Helvetica, Arial, sans-serif;
}
.thin {
  font-weight: 100;
}
.light {
  font-weight: 300;
}
.regular {
  font-weight: 400;
}
.semibold {
  font-weight: 600;
}
.bold {
  font-weight: 800;
}
.title-1 {
  text-align: center;
  font-weight: 400;
  font-size: 50px;
  letter-spacing: 2px;
}
.title-1.smaller,
.title-1 .smaller {
  font-weight: 100;
  font-size: 0.7em;
}
.title-1-light {
  text-align: center;
  font-weight: 400;
  font-size: 50px;
  letter-spacing: 2px;
  font-weight: 300;
}
.title-1-light.smaller,
.title-1-light .smaller {
  font-weight: 100;
  font-size: 0.7em;
}
.title-2 {
  font-weight: 600;
  font-size: 30px;
  letter-spacing: 1px;
}
.title-3 {
  font-weight: 800;
  font-size: 22px;
  letter-spacing: 2px;
}
.title-4 {
  font-weight: 600;
  font-size: 18px;
  border-bottom: 1px solid black;
  border-color: inherit;
  padding-bottom: 0.8em;
}
.title-5 {
  font-weight: 600;
  font-size: 18px;
  border-bottom: 1px solid black;
  border-color: inherit;
  padding-bottom: 0.8em;
  border: none;
  padding-bottom: 0;
}
.title-6 {
  font-weight: 600;
  font-size: 18px;
  border-bottom: 1px solid black;
  border-color: inherit;
  padding-bottom: 0.8em;
  border: none;
  padding-bottom: 0;
  font-weight: 400;
  line-height: 2em;
}
.title-7 {
  font-weight: 800;
  font-size: 20px;
}
.pre-title {
  display: block;
  font-size: 0.5em;
  font-weight: 300;
}
.body {
  font-weight: 400;
  font-size: 16px;
}
.body-m {
  font-weight: 400;
  font-size: 16px;
  font-size: 14px;
}
.body-s {
  font-weight: 400;
  font-size: 16px;
  font-size: 12px;
}
.body-s.smaller,
.body-s .smaller {
  font-weight: 100;
}
.body-l {
  font-weight: 400;
  font-size: 16px;
  font-size: 18px;
}
.headline {
  font-weight: 400;
  font-size: 22px;
  line-height: 1.5em;
  margin: 1em;
}
blockquote,
q,
.quote {
  position: relative;
  font-weight: 400;
  font-size: 16px;
  font-style: italic;
  padding-left: 50px;
  padding-top: 30px;
  margin-inline-start: 0;
  quotes: "“" "”";
}
blockquote:before,
q:before,
.quote:before {
  position: relative;
  margin-left: -50px;
  font-size: 80px;
  line-height: 0;
  color: #37a8db;
  content: open-quote;
  display: block;
}
.spaced-body p {
  margin-bottom: 1em;
}
.article-title {
  font-weight: 600;
  font-size: 30px;
  letter-spacing: 1px;
  margin-bottom: 0.7em;
}
.article-inner-title {
  font-size: 20px;
  font-weight: 600;
  color: #074377;
}
.article-body {
  font-weight: 400;
  font-size: 16px;
}
.section-title {
  display: inline-block;
  margin: 40px 0 70px !important;
  width: auto;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 32px;
  line-height: 1.6em;
  letter-spacing: 0.2em;
  position: relative;
}
.section-title:before {
  content: '';
  position: absolute;
  right: 0;
  bottom: -30px;
  display: inline-block;
  width: 5000px;
  height: 1px;
  border-bottom: 1px solid #d2d3d5;
}
.section-title:after {
  content: '';
  width: 32px;
  height: 10px;
  background-color: #37a8db;
  position: absolute;
  z-index: 1;
  bottom: 3px;
  right: -45px;
  transform: skew(-20deg);
}
.section-title:after {
  bottom: 15px;
}
.page-title {
  text-align: center;
  font-weight: 400;
  font-size: 50px;
  letter-spacing: 2px;
  padding-top: 0.5em;
  padding-bottom: 0.2em;
}
.page-title.smaller,
.page-title .smaller {
  font-weight: 100;
  font-size: 0.7em;
}
.page-title .thin,
.page-title .light {
  font-size: 0.8em;
}
.page-title + .page-subtitle {
  font-weight: 800;
  font-size: 22px;
  letter-spacing: 2px;
  font-weight: 100;
  text-align: center;
  margin-top: -2em;
  margin-bottom: 2em;
}
.tile-title {
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.1em;
  color: white;
}
.tile-title:after:not(.no-after) {
  content: '';
  display: block;
  width: 150px;
  border-bottom: 1px solid white;
  margin: 20px auto;
}
.section-subtitle {
  position: relative;
  font-size: 16px;
  font-weight: 300;
  top: -80px;
}
.gallery-title {
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.2em;
  flex: 1;
}
.gallery-title .gallery-subtitle {
  font-size: 16px;
  font-weight: 300;
  text-transform: none;
  display: block;
  text-align: center;
  letter-spacing: 0;
}
.collection-title {
  font-weight: 300;
  text-transform: uppercase;
  font-size: 20px;
  color: #37a8db;
  margin: 40px 0 2px;
}
.collection-subtitle {
  font-weight: 300;
  font-size: 12px;
  margin-bottom: 24px;
  padding-bottom: 12px;
  border-bottom: 1px solid white;
}
.category-title {
  font-weight: 400;
  font-size: 16px;
  font-size: 14px;
  text-transform: uppercase;
}
.text-finish {
  position: relative;
}
.text-finish:after {
  content: '';
  width: 32px;
  height: 10px;
  background-color: #37a8db;
  position: absolute;
  z-index: 1;
  bottom: 3px;
  right: -45px;
  transform: skew(-20deg);
}
.liveinspired {
  font-size: 26px;
}
.liveinspired span:first-child {
  font-weight: 100;
}
.liveinspired span:nth-child(2) {
  font-weight: 400;
}
.liveinspired.dark {
  color: #074377;
}
.liveinspired.dark.boxed {
  border: solid 1px #37a8db;
}
*,
html,
body {
  font-family: 'Work Sans', Helvetica, Arial, sans-serif;
}
p {
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  padding: 0;
}
a {
  color: inherit;
  cursor: pointer;
}
a,
a > strong {
  transition: none;
}
a:hover {
  color: #37a8db;
}
.ptn-em {
  /* special ptn emphasize style. keep default <em> for RTE edition style */
  color: #37a8db;
  font-weight: 600;
  font-style: normal;
}
strong,
b {
  font-weight: 600;
}
.link {
  font-weight: 400;
  font-size: 16px;
  color: #37a8db;
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}
.link.light {
  font-weight: 300;
}
.grey-label {
  color: #6a7581;
  font-size: 13px;
}
.help-section p {
  text-align: justify;
}
.text-red {
  color: #cd5c5c;
}
.text-green {
  color: #00A86B;
}
.text-light-blue {
  color: #37a8db;
}
.text-center {
  text-align: center;
}
.text-dark {
  color: #121e2c;
}
.text-right {
  text-align: right;
}
.text-uppercase {
  text-transform: uppercase;
}
ol ::marker {
  color: #37a8db;
  font-weight: bold;
}
.text-underline {
  text-decoration: underline;
}
a[ui-sref],
.clickable {
  cursor: pointer;
}
.btn {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  font-size: 13px;
  line-height: 1em;
  border: none;
  letter-spacing: 0;
  text-transform: initial;
  background-color: transparent;
}
select.btn {
  appearance: none;
}
a.btn {
  text-decoration: none;
}
.btn + .btn {
  margin-left: 15px;
}
.btn.btn-block {
  display: block;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
}
.btn.btn-block + .btn {
  margin-left: 0;
}
.btn:focus {
  outline: 0;
}
.btn.btn-1 {
  color: white;
  background: linear-gradient(to right, #074377, #37a8db);
  border-radius: 30px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
  padding: 12px 30px;
}
.btn.btn-1:hover:not([disabled]):not(.disabled) {
  background: linear-gradient(to left, #074377, #37a8db);
  color: #121e2c;
  animation: push 0.3s linear;
}
.btn.btn-1.btn-wider {
  padding-left: 50px;
  padding-right: 50px;
}
.btn.btn-outlined {
  background: none;
  border-radius: 30px;
  padding: 10px 28px;
  color: #074377;
  border: 1px solid #074377;
}
.btn.btn-outlined.light {
  color: white;
  border: 1px solid white;
}
.btn.btn-outlined.black {
  color: black;
  border-color: black;
}
.btn.btn-outlined:not([disabled]):not(.disabled):hover {
  color: #37a8db;
  border-color: #37a8db;
}
.btn.btn-outlined:not([disabled]):not(.disabled):hover:not(select) {
  animation: push 0.3s linear;
}
.btn.btn-outlined:not([disabled]):not(.disabled).active,
.btn.btn-outlined:not([disabled]):not(.disabled).active:hover {
  background: #37a8db;
  color: #121e2c;
  border-color: #37a8db;
}
.btn.btn-outlined.light-blue {
  color: #37a8db;
  border-color: #37a8db;
}
.btn.btn-outlined.light-blue:hover:not([disabled]):not(.disabled) {
  color: #074377;
  border-color: #074377;
}
.btn.btn-outlined.light-blue.active,
.btn.btn-outlined.light-blue.active:hover:not([disabled]):not(.disabled) {
  background: #074377;
  color: #121e2c;
  border-color: #074377;
}
.btn.btn-outlined.btn-big {
  border-width: 2px;
}
select.btn.btn-outlined {
  background-image: url('../img/pictos/chevron-down-light-grey.svg');
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
  background-size: 1.2em;
}
.btn.btn-big {
  font-size: 16px;
  padding: 18px 50px;
}
.btn .btn-icon:after {
  content: '';
  display: inline-block;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  border-radius: 50%;
  font-size: 25px;
  background-color: #121e2c;
  color: white;
  font-weight: 300;
  margin-left: 10px;
  position: relative;
  top: 3px;
  transition: transform 0.5s;
  transform: rotate(0deg);
}
.btn.btn-icon-plus:after {
  content: '';
  display: inline-block;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  border-radius: 50%;
  font-size: 25px;
  background-color: #121e2c;
  color: white;
  font-weight: 300;
  margin-left: 10px;
  position: relative;
  top: 3px;
  transition: transform 0.5s;
  transform: rotate(0deg);
}
.btn.btn-icon-plus:after {
  content: '+';
}
.btn.btn-icon-plus:hover:not([disabled]):not(.disabled):after {
  transform: rotate(90deg);
}
.btn.btn-icon-minus:after {
  content: '';
  display: inline-block;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  border-radius: 50%;
  font-size: 25px;
  background-color: #121e2c;
  color: white;
  font-weight: 300;
  margin-left: 10px;
  position: relative;
  top: 3px;
  transition: transform 0.5s;
  transform: rotate(0deg);
}
.btn.btn-icon-minus:after {
  content: '-';
  line-height: 22px;
}
.btn.btn-icon-minus:hover:not([disabled]):not(.disabled):after {
  transform: rotate(180deg);
}
.btn.btn-icon-close {
  transform: rotate(45deg);
}
.btn.btn-icon-close:after {
  content: '';
  display: inline-block;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  border-radius: 50%;
  font-size: 25px;
  background-color: #121e2c;
  color: white;
  font-weight: 300;
  margin-left: 10px;
  position: relative;
  top: 3px;
  transition: transform 0.5s;
  transform: rotate(0deg);
}
.btn.btn-icon-close:after {
  content: '+';
}
.btn.btn-icon-close:hover:not([disabled]):not(.disabled):after {
  transform: rotate(90deg);
}
.btn.link-btn {
  color: #6a7581;
}
.btn.link-btn:hover:not([disabled]):not(.disabled) {
  text-decoration: underline;
}
.btn.link-btn.small-link-btn {
  font-size: 12px;
  font-weight: 400;
}
.btn.link-btn.underlined {
  text-decoration: underline;
}
.btn.link-btn.underlined:hover {
  color: #37a8db;
}
.btn.link-btn.light {
  color: white;
}
.btn.link-btn.light.btn-icon-plus:after {
  background-color: white;
  color: #121e2c;
}
.btn.link-btn.blue {
  color: #37a8db;
}
.btn.link-btn.dark-blue {
  color: #074377;
}
.btn.link-btn.black {
  color: black;
}
.btn.link-btn.thin {
  font-weight: 400;
}
.btn.btn-toggle {
  color: #37a8db;
  font-weight: 400;
  font-size: 16px;
  font-size: 14px;
  text-transform: uppercase;
}
.btn.btn-toggle:after {
  content: '';
  display: inline-block;
  position: relative;
  top: 0.2em;
  width: 1em;
  height: 1em;
  margin-left: 0.3em;
  color: #d2d3d5;
  background: url('../img/pictos/picto-chevron-down-light-blue.svg') no-repeat center center;
  background-size: contain;
  transition: transform 0.2s;
}
.btn.btn-toggle.toggled:after {
  transform: rotate(180deg);
}
.btn[disabled],
.btn.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.btn[disabled]:hover,
.btn.disabled:hover {
  animation: none;
}
.btn.btn-uppercase {
  text-transform: uppercase;
}
.btn.thumbnail-hidden {
  color: white;
  background: linear-gradient(to right, #c7994a, #cd5c5c);
  border-radius: 30px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
  padding: 12px 30px;
}
.btn.thumbnail-hidden:hover:not([disabled]):not(.disabled) {
  background: linear-gradient(to left, #c7994a, #cd5c5c);
  color: #121e2c;
  animation: push 0.3s linear;
}
.btn.thumbnail-hidden.btn-wider {
  padding-left: 50px;
  padding-right: 50px;
}
.btn.btn-borderless {
  border: none;
  background: none;
  padding: 0;
}
.btn.btn-borderless.white {
  color: white;
}
.btn.btn-borderless.light-blue {
  color: #37a8db;
}
.btn.btn-borderless.black {
  color: black;
}
.section-light-blue .btn-outlined:hover:not([disabled]):not(.disabled) {
  color: white;
  border-color: white;
}
.buttons-bar {
  display: flex;
}
.buttons-bar .btn {
  margin: 0 15px;
}
.buttons-bar .btn:first-child {
  margin-left: 0;
}
.buttons-bar .btn:last-child {
  margin-right: 0;
}
.alphabet-buttons-grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
}
.alphabet-buttons-grid .btn {
  width: 3em;
  height: 3em;
  box-sizing: content-box;
  padding: 0;
  margin: 5px;
}
a.ghost-link {
  text-decoration: none;
}
a.ghost-link:focus {
  outline: none;
}
.btn-switch {
  position: relative;
  display: inline-block;
  padding: 0;
  width: 44px;
  height: 22px;
  background: white;
  border: 1px solid black;
  border-radius: 22px;
  vertical-align: middle;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  font-size: 14px;
}
.btn-switch:after {
  content: '';
  width: 16px;
  height: 16px;
  background: black;
  position: absolute;
  top: 2px;
  left: 3px;
  border-radius: 50%;
  transition: left 0.1s linear;
}
.btn-switch.switch-on:after {
  background-color: #37a8db;
  left: 23px;
}
.btn-switch:focus {
  outline: none;
}
.btn-switch[disabled] {
  opacity: 0.3;
  cursor: not-allowed;
}
.btn-switch[disabled] + * {
  opacity: 0.5;
}
.btn-orange {
  background-color: #c7994a;
  border: none;
}
.input,
.select,
.textarea,
.radio,
.checkbox {
  box-sizing: border-box;
  margin: 0;
  border-radius: 0;
}
.input:focus,
.select:focus,
.textarea:focus,
.radio:focus,
.checkbox:focus {
  outline: 0;
}
.input.block,
.select.block,
.textarea.block,
.radio.block,
.checkbox.block {
  width: 100%;
}
.radio-custom {
  display: none;
}
.radio-custom + label {
  position: relative;
  padding-left: 22px;
  cursor: pointer;
  line-height: 18px;
  display: inline-block;
  font-weight: 400;
  font-size: 16px;
  font-size: 14px;
  color: black;
  margin-right: 1em;
}
.radio-custom + label:before {
  position: absolute;
  left: 0;
  top: 0;
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  border: 1px solid black;
  background: white;
}
.radio-custom + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #37a8db;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  opacity: 1;
  transition: all 0.1s linear;
  opacity: 0;
  transform: scale(0);
}
.radio-custom:checked + label:after {
  transform: scale(1);
  opacity: 1;
}
.checkbox-custom {
  display: inline-block;
  line-height: 26px;
  color: #121e2c;
  cursor: pointer;
}
.checkbox-custom input[type="checkbox"] {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.checkbox-custom input[type="checkbox"] + .checkbox {
  width: 20px;
  height: 20px;
  background-color: white;
  border-width: 1px;
  border-style: solid;
  border-color: #d2d3d5;
  border-radius: 2px;
  float: left;
  margin-top: 2px;
  margin-right: 7px;
}
.checkbox-custom input[type="checkbox"] + .checkbox + span {
  font-size: 15px;
  font-weight: bold;
}
.checkbox-custom input[type="checkbox"] + .checkbox.black {
  border-color: black;
}
.checkbox-custom input[type="checkbox"]:checked + .checkbox {
  background-color: #37a8db;
  background-image: url('../img/white-check.svg');
  background-size: 16px;
  background-position: center center;
  background-repeat: no-repeat;
}
.checkbox-custom input[type="checkbox"]:checked + .checkbox:not(.black) {
  border-color: #37a8db;
}
.checkbox-custom.large input[type="checkbox"] + .checkbox {
  width: 22px;
  height: 22px;
  border-width: 2px;
}
.checkbox-custom.large input[type="checkbox"] + .checkbox + span {
  position: relative;
  top: 2px;
  font-size: 15px;
  font-weight: 500;
}
.textarea.block {
  min-width: 100%;
}
.input,
.select,
.textarea,
.rte {
  max-width: 100%;
  background: #fff;
  color: #121e2c;
  border: 1px solid #d2d3d5;
}
.input.dark,
.select.dark,
.textarea.dark,
.rte.dark {
  border-color: black;
}
.input.no-border,
.select.no-border,
.textarea.no-border,
.rte.no-border {
  /* especially for inputs on dark backgrounds */
  border-color: transparent;
}
.input::placeholder,
.select::placeholder,
.textarea::placeholder,
.rte::placeholder {
  color: #848484;
  opacity: 1;
  /* Firefox */
}
.input,
.select,
.textarea {
  overflow: visible;
  -webkit-appearance: none;
  font-size: 13px;
  line-height: 1em;
  padding: 10px 15px;
}
.input.big,
.select.big,
.textarea.big {
  padding: 15px 20px;
}
.input.wide,
.select.wide,
.textarea.wide {
  width: 400px;
  max-width: 100%;
}
.input.bigger-font,
.select.bigger-font,
.textarea.bigger-font {
  font-weight: 400;
  font-size: 16px;
}
.input.line-input,
.select.line-input,
.textarea.line-input {
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 5px 10px;
}
.input.line-input.transparent,
.select.line-input.transparent,
.textarea.line-input.transparent {
  border-color: #d2d3d5;
  padding: 8px 0;
}
.input.dark,
.select.dark,
.textarea.dark {
  border-color: #121e2c;
}
.input.dark.select:invalid,
.select.dark.select:invalid,
.textarea.dark.select:invalid,
.input.dark.select option[value=""],
.select.dark.select option[value=""],
.textarea.dark.select option[value=""] {
  color: #121e2c;
}
.input.transparent,
.select.transparent,
.textarea.transparent {
  background: none;
  color: #d2d3d5;
}
.input.transparent::placeholder,
.select.transparent::placeholder,
.textarea.transparent::placeholder {
  color: #848484;
  opacity: 1;
  /* Firefox */
}
.input-with-icon {
  display: inline-block;
  position: relative;
}
.input-with-icon input {
  padding-right: 38px !important;
}
.input-with-icon .icon {
  width: 18px;
  position: absolute;
  top: 50%;
  margin-top: -9px;
  right: 10px;
}
img + .line-input {
  margin-left: 10px;
}
.input + .input:not(.block) {
  margin-left: 1px;
}
.input + .btn {
  margin-left: 15px;
}
.input.block + .btn.block {
  margin: 20px 0 0 0;
}
label {
  color: #37a8db;
  font-size: 13px;
}
label.label-strong {
  color: #121e2c;
  font-weight: 400;
  font-size: 16px;
  font-size: 12px;
  font-weight: 600;
}
label.label-strong.smaller,
label.label-strong .smaller {
  font-weight: 100;
}
label + .input.block,
label + .uk-grid,
label + .select.block {
  margin-top: 0.4em;
}
label.black {
  color: black;
}
label.blue {
  color: #37a8db;
}
label > .btn-switch {
  margin-right: 5px;
}
.rte .pell-actionbar {
  border-bottom-color: #d2d3d5;
}
.rte .pell-actionbar .pell-button {
  height: 35px;
}
.rte h1 {
  font-size: 20px;
  font-weight: 600;
  color: #074377;
}
.rte p {
  font-weight: 400;
  font-size: 16px;
}
.rte .pell-content:empty:before {
  font-size: 13px;
  color: #848484;
}
.rte.tribute .pell-content:empty:before {
  content: 'Rédiger mon hommage';
}
.textarea.expandable {
  min-height: 48px;
  transition: min-height 0.2s;
  line-height: 1em;
  resize: none;
  padding-top: 15px;
}
.textarea.expandable.expanded {
  min-height: 140px;
}
.textarea.no-resize {
  resize: none;
}
.textarea.medium {
  height: 100px;
}
.textarea.big {
  height: 250px;
}
.textarea.textarea-with-mentions textarea {
  border: none;
  padding: 10px 15px;
}
.textarea.textarea-with-mentions textarea:focus {
  outline: none;
}
.textarea.textarea-with-mentions.expandable.expanded {
  min-height: 100px;
}
.textarea.textarea-with-mentions.expandable.expanded .textarea__control {
  padding-top: 30px;
  padding-bottom: 10px;
}
.textarea.textarea-with-mentions.expandable textarea {
  padding: 12px 15px;
  overflow-y: visible !important;
}
.textarea.textarea-with-mentions .textarea__suggestions {
  margin-top: calc(14px + 2em) !important;
  max-height: 150px;
  overflow-y: auto;
  border: 1px solid #d2d3d5;
  left: 50%;
}
.textarea.textarea-with-mentions .textarea__suggestions li:not(:first-child) {
  border-top: 1px solid #d2d3d5;
}
.textarea.textarea-with-mentions .mention-user {
  display: flex;
  align-items: center;
  padding: 5px;
  font-weight: 400;
  font-size: 16px;
  font-size: 14px;
}
.textarea.textarea-with-mentions .mention-user .profile-pic {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.textarea.textarea-with-mentions .mention-user:hover {
  background: #e0e4ee;
}
.select {
  line-height: 1.2em;
  background-image: url('../img/pictos/triangle-down.svg');
  background-repeat: no-repeat, repeat;
  background-position: right 1em top 50%, 0 0;
  background-size: 1.2em auto, 100%;
}
.select:invalid,
.select option[value=""] {
  color: #848484;
}
.select.big {
  padding: 10px 2rem 10px 15px;
}
fieldset {
  border: none;
  padding: 0;
}
fieldset[disabled] {
  opacity: 0.3;
}
.collapsible-section-head-container {
  padding-bottom: 1em;
}
.collapsible-section-head {
  position: relative;
  display: block;
  width: 100%;
  font-weight: 600;
  font-size: 18px;
  border-bottom: 1px solid black;
  border-color: inherit;
  padding-bottom: 0.8em;
  padding-bottom: 0;
  text-transform: uppercase;
  color: black;
  padding: 1em 2em 0 0;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
}
.collapsible-section-head:after {
  content: '';
  display: inline-block;
  position: absolute;
  right: 0;
  width: 1em;
  height: 1em;
  background: url(../img/pictos/triangle-down.svg) center center no-repeat;
  transform: rotate(-90deg);
  transition: transform 0.1s linear;
}
.collapsible-section-head.opened:after {
  transform: rotate(0);
}
.collapsible-section-head:focus {
  outline: none;
}
.collapsible-subsection-head {
  position: relative;
  display: block;
  width: 100%;
  font-weight: 600;
  font-size: 18px;
  border-bottom: 1px solid black;
  border-color: inherit;
  padding-bottom: 0.8em;
  padding-bottom: 0;
  text-transform: uppercase;
  color: black;
  padding: 1em 2em 0 0;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  color: #37a8db;
  text-transform: none;
}
.collapsible-subsection-head:after {
  content: '';
  display: inline-block;
  position: absolute;
  right: 0;
  width: 1em;
  height: 1em;
  background: url(../img/pictos/triangle-down.svg) center center no-repeat;
  transform: rotate(-90deg);
  transition: transform 0.1s linear;
}
.collapsible-subsection-head.opened:after {
  transform: rotate(0);
}
.collapsible-subsection-head:focus {
  outline: none;
}
.collapsible-subsection-head:after {
  background: url(../img/pictos/triangle-down-blue.svg) center center no-repeat;
}
.collapsible-tags-head {
  font-weight: 600;
  font-size: 18px;
  border-bottom: 1px solid black;
  border-color: inherit;
  padding-bottom: 0.8em;
  border: none;
  padding-bottom: 0;
  padding-top: 3px;
  background: none;
  cursor: pointer;
}
.collapsible-tags-head:after {
  content: '';
  display: inline-block;
  width: 1em;
  height: 1em;
  background: url(../img/pictos/triangle-down-blue.svg) center center no-repeat;
  transform: rotate(-90deg);
  transition: transform 0.1s linear;
}
.collapsible-tags-head.opened:after {
  transform: rotate(0);
}
.collapsible-tags-head:focus {
  outline: none;
}
.input-btn {
  text-transform: uppercase;
  background: none;
  border-radius: 30px;
  padding: 5px 15px;
  border: 1px solid black;
  color: black;
  font-weight: 600;
}
.input-btn:not([disabled]):not(.disabled):hover {
  cursor: pointer;
  color: #37a8db;
  border-color: #37a8db;
  animation: push 0.3s linear;
}
.input-btn:not([disabled]):not(.disabled).active,
.input-btn:not([disabled]):not(.disabled).active:hover {
  background: #37a8db;
  color: #121e2c;
  border-color: #37a8db;
}
.input-btn > *[type="file"] {
  width: 0;
  height: 0;
  opacity: 0;
}
.collapsible-tribe-head {
  font-weight: 600;
  font-size: 18px;
  border-bottom: 1px solid black;
  border-color: inherit;
  padding-bottom: 0.8em;
  border: none;
  padding-bottom: 0;
  padding-top: 3px;
  background: none;
}
.collapsible-tribe-head.clickable {
  cursor: pointer;
}
.collapsible-tribe-head:after {
  content: '';
  display: inline-block;
  width: 1em;
  height: 1em;
  background: url(../img/pictos/triangle-down-blue.svg) center center no-repeat;
  transform: rotate(-90deg);
  transition: transform 0.1s linear;
}
.collapsible-tribe-head.opened:after {
  transform: rotate(0);
}
.collapsible-tribe-head:focus {
  outline: none;
}
.h-center {
  text-align: center;
}
.block {
  width: 100%;
}
.block-m {
  width: 50%;
}
.block-l {
  width: 75%;
}
.break-word {
  word-break: break-word;
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.flex {
  display: flex;
}
.flex-colomn {
  display: flex;
  flex-direction: column;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-baseline {
  align-items: baseline;
}
.space-between {
  justify-content: space-between;
}
.align-center {
  align-items: center;
}
.pointer {
  cursor: pointer;
}
.right {
  text-align: right;
}
.dark-bg {
  background-color: #121e2c;
  color: white;
}
.dark-bg .tile-body {
  color: black;
}
.black-bg {
  background-color: black;
  color: white;
}
.ptn-shaped {
  border-radius: 50%;
  border-bottom-right-radius: 0;
}
.margin {
  margin-top: 20px !important;
  margin-bottom: 20px;
}
.margin-xs {
  margin-top: 5px;
  margin-bottom: 5px;
}
.margin-s {
  margin-top: 10px;
  margin-bottom: 10px;
}
.margin-l {
  margin-top: 40px;
  margin-bottom: 40px;
}
.margin-xl {
  margin-top: 60px;
  margin-bottom: 60px;
}
.margin-top {
  margin-top: 20px !important;
}
.margin-top-m {
  margin-top: 15px !important;
}
.margin-top-s {
  margin-top: 10px !important;
}
.margin-top-l {
  margin-top: 40px;
}
.margin-top-xl {
  margin-top: 60px;
}
.margin-bottom-s {
  margin-bottom: 10px;
}
.margin-bottom {
  margin-bottom: 20px;
}
.margin-bottom-m {
  margin-bottom: 30px;
}
.margin-bottom-xl {
  margin-bottom: 60px;
}
.margin-left-s {
  margin-left: 10px;
}
.margin-left {
  margin-left: 20px;
}
.margin-right {
  margin-right: 20px;
}
.margin-right-l {
  margin-right: 40px;
}
.margin-large {
  margin-top: 40px;
  margin-bottom: 40px;
}
.margin-large-top {
  margin-top: 40px;
}
.margin-large-bottom {
  margin-bottom: 40px;
}
.padding-v-xs {
  padding-top: 5px;
  padding-bottom: 5px;
}
.padding-h {
  padding-left: 20px;
  padding-right: 20px;
}
.padding-h-xl {
  padding-left: 60px;
  padding-right: 60px;
}
.no-margin {
  margin: 0 !important;
}
.no-margin-top {
  margin-top: 0 !important;
}
.no-margin-bottom {
  margin-bottom: 0 !important;
}
.padding-top {
  padding-top: 20px;
}
.padding-left {
  padding-left: 20px;
}
.no-padding {
  padding: 0 !important;
}
.no-padding-top {
  padding-top: 0 !important;
}
.no-padding-bottom {
  padding-bottom: 0 !important;
}
.padding-bottom {
  padding-bottom: 20px;
}
.padding {
  padding-top: 20px;
  padding-bottom: 20px;
}
.bottom-overlay:before {
  content: '';
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 70px;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
}
.custom-scroll {
  padding-right: 10px;
  scrollbar-color: #37a8db #eee;
  /* Track */
  /* Handle */
}
.custom-scroll::-webkit-scrollbar {
  width: 8px;
}
.custom-scroll::-webkit-scrollbar-track {
  background: #eee;
  border-radius: 5px;
}
.custom-scroll::-webkit-scrollbar-thumb {
  background: #37a8db;
  border-radius: 5px;
}
.cols-3 {
  columns: 3;
}
.cap-first-letter {
  display: inline-block;
}
.cap-first-letter:first-letter {
  text-transform: uppercase;
}
@media (max-width: 639px) {
  .no-scroll-xs {
    overflow-y: hidden;
  }
}
.text-blue {
  color: #37a8db;
}
@keyframes push {
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes ptn-shape-anim {
  0% {
    border-radius: 50%;
    border-bottom-right-radius: 0;
  }
  25% {
    border-radius: 50%;
    border-bottom-right-radius: 0;
    border-bottom-right-radius: 50%;
    border-top-right-radius: 0;
  }
  50% {
    border-radius: 50%;
    border-bottom-right-radius: 0;
    border-bottom-right-radius: 50%;
    border-top-left-radius: 0;
  }
  75% {
    border-radius: 50%;
    border-bottom-right-radius: 0;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 0;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes menu-slide-in {
  0% {
    transform: translateX(100vw);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes menu-slide-out {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100vw);
  }
}
/* slick js library overrides*/
.slick-slider div {
  transition: none;
}
.slick-slider .slick-prev,
.slick-slider .slick-next {
  background-size: cover;
  z-index: 2;
  width: 60px;
  height: 60px;
  background-color: #121e2c;
  background-position: center center;
}
.slick-slider .slick-prev:before,
.slick-slider .slick-next:before {
  display: none;
}
.slick-slider .slick-prev:hover,
.slick-slider .slick-next:hover {
  background-color: #37a8db;
}
.slick-slider .slick-prev.slick-disabled,
.slick-slider .slick-next.slick-disabled {
  pointer-events: none;
  opacity: 0.2;
}
.slick-slider .slick-prev {
  left: 0;
  background-image: url('../img/slider-prev.png');
}
.slick-slider .slick-prev:hover {
  background-position: -5px;
}
.slick-slider .slick-next {
  right: 0;
  background-image: url('../img/slider-next.png');
}
.slick-slider .slick-next:hover {
  background-position: 5px;
}
.slick-slider.slick-dotted {
  margin-bottom: 80px;
}
.slick-slider .slick-dots {
  bottom: -40px;
}
.slick-slider .slick-dots li {
  width: 10px;
  height: 10px;
  border: 1px solid white;
  border-radius: 50%;
}
.slick-slider .slick-dots li:hover {
  background: white;
}
.slick-slider .slick-dots li.slick-active {
  background: #37a8db;
  border-color: #37a8db;
}
.slick-slider .slick-dots li button:before {
  display: none;
}
.slick-slider.block-content-slider {
  padding: 0 10px;
}
.slick-slider.block-content-slider .slick-prev,
.slick-slider.block-content-slider .slick-next {
  background-color: transparent;
}
.slick-slider.block-content-slider .slick-prev {
  left: -35px;
}
.slick-slider.block-content-slider .slick-next {
  right: -35px;
}
.slick-slider.block-content-slider .slick-slide {
  padding: 0 5px 20px;
}
.slick-slider.block-content-slider .cta {
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.featured-carousel {
  position: relative;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.5);
  height: 540px;
  overflow: hidden;
  /* slick js library overrides*/
}
.featured-carousel .caption {
  left: 80px;
  top: 50%;
  margin-top: -30px;
}
.featured-carousel .caption.simple-caption {
  bottom: 30px;
  left: 40px;
  top: auto;
  margin-top: 0;
}
.featured-carousel .slick-prev,
.featured-carousel .slick-next {
  opacity: 0.6;
}
.featured-carousel .slick-prev:hover,
.featured-carousel .slick-next:hover {
  opacity: 1;
}
.featured-carousel.slick-dotted {
  margin-bottom: 0;
}
.featured-carousel .slide {
  position: relative;
  height: 540px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.featured-carousel .uk-container {
  position: relative;
  height: 100%;
}
.featured-carousel .slick-dots {
  bottom: 30px;
  z-index: 1;
}
.thumbs-carousel .card + .card {
  margin-left: 20px;
}
.thumbs-carousel .slick-track {
  padding-bottom: 5px;
  padding-top: 20px;
}
.card {
  display: inline-block;
  position: relative;
  top: 0;
  width: 250px;
  height: 350px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 25px;
  box-shadow: 0 3px 5px -2px rgba(0, 0, 0, 0.7);
  transition: box-shadow 0.3s, top 0.3s;
}
.card .chip {
  position: absolute;
  top: 10px;
  right: 10px;
}
.card:hover {
  top: -5px;
  box-shadow: 0 5px 8px -1px rgba(0, 0, 0, 0.5);
}
.card .card-footer {
  position: absolute;
  bottom: -2px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  background-color: #121e2c;
  padding: 14px 20px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  font-size: 16px;
}
.card .card-footer .card-name {
  color: white;
  font-weight: 400;
  line-height: 1.4em;
  margin: 0;
  display: inline-block;
  hyphens: auto;
  box-sizing: border-box;
  width: 100%;
  word-break: break-word;
}
.card .card-footer .card-name:first-letter {
  text-transform: uppercase;
}
.card .card-footer .card-title {
  color: #37a8db;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1.4em;
  margin: 0;
}
.card .card-footer .card-title.big {
  font-size: 16px;
}
.card.small .card-footer {
  font-size: 13px;
  padding: 8px 14px 10px;
}
.card.small .card-footer .card-name {
  line-height: 1.2em;
}
.card.blue .card-footer {
  background-color: #074377;
}
.card.empty {
  box-shadow: none;
  background-color: #0e1721;
}
.card.no-hover-anim:hover {
  top: 0;
}
.card canvas {
  display: none;
}
.card.expand {
  width: 100%;
  height: 100%;
}
.card.expand canvas {
  display: block;
  width: 100%;
}
.card:focus {
  outline: none;
}
.plus-card {
  display: inline-block;
  border: none;
  border-radius: 25px;
  width: 100px;
  height: 150px;
  background-color: #121e2c;
  cursor: pointer;
}
.plus-card:after {
  content: '+';
  display: inline-block;
  line-height: 150px;
  font-size: 60px;
  color: #d2d3d5;
  font-weight: 100;
  transform: rotate(0deg);
  transition: transform 0.2s;
}
.plus-card.small {
  width: 70px;
  height: 100px;
  background: #d2d3d5;
  border-radius: 16.66666667px;
}
.plus-card.small:after {
  line-height: 100px;
  color: white;
}
.plus-card:hover:after {
  transform: rotate(90deg);
}
.plus-card:focus {
  outline: none;
}
.framed-pic-card {
  position: relative;
  display: inline-block;
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  outline: 1px solid white;
  outline-offset: -15px;
  margin-bottom: 80px;
  box-shadow: 0 3px 5px -2px rgba(0, 0, 0, 0.7);
  transition: outline-offset 0.2s;
}
.framed-pic-card .card-ratio {
  width: 100%;
}
.framed-pic-card .card-footer {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  margin-top: 7px;
  color: #121e2c;
  text-align: center;
  font-size: 14px;
}
.framed-pic-card .card-footer .card-title,
.framed-pic-card .card-footer .card-subtitle {
  margin: 2px 0;
}
.framed-pic-card .card-footer .card-subtitle {
  font-weight: 100;
}
.framed-pic-card:hover {
  outline-color: #37a8db;
  outline-offset: -25px;
}
.section-dark .framed-pic-card .card-footer,
.sidebar-block.night-blue .framed-pic-card .card-footer {
  color: white;
}
.tile {
  position: relative;
  background-color: white;
  color: black;
}
.tile.sep + .tile.sep {
  margin-top: 1px;
}
.tile .tile-head {
  padding: 20px 30px;
  background-color: #074377;
  color: white;
}
.tile .tile-head a {
  color: white;
  text-decoration: none;
  font-size: 13px;
  line-height: 2em;
}
.tile .tile-head a:hover {
  color: #37a8db;
}
.tile .tile-body {
  position: relative;
  padding: 20px 30px;
}
.tile .tile-body.compact {
  padding: 15px;
}
.tile.tile-notif .picto-notif {
  flex: 0;
  width: 30px;
  height: 30px;
  margin: 15px 20px 0 10px;
}
.tile.tile-notif .tile-body {
  display: flex;
  padding: 10px;
}
.tile.tile-notif .notif-body {
  flex: 1;
}
.tile.tile-notif .notif-date {
  font-weight: 400;
  font-size: 16px;
  font-size: 14px;
  margin: 5px 0 7px 15px;
}
.tile.tile-notif .notif-desc {
  margin-left: 15px;
  font-weight: 400;
  font-size: 16px;
}
.tile.tile-notif .notif-content {
  color: black;
  font-weight: 400;
  font-size: 16px;
  font-size: 14px;
  margin-top: 2px;
  white-space: pre-wrap;
}
.tile.tile-notif .notif-content:before {
  content: '«';
  display: inline-block;
  margin-right: 5px;
}
.tile.tile-notif .notif-content:after {
  content: '»';
  display: inline-block;
  margin-left: 5px;
}
.tile.tile-notif a {
  /* special ptn emphasize style. keep default <em> for RTE edition style */
  color: #37a8db;
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
}
.tile.tile-notif a:hover {
  text-decoration: underline;
}
.tile.tile-notif a:hover .notif-content {
  color: #37a8db;
}
.tile.tile-notif .profile-pic {
  width: 60px;
  height: 60px;
}
.tile.tile-notif.unread {
  background: #e0e4ee;
}
.tile.dark-blue {
  background-color: #074377;
}
.tile.dark-blue:not(.switchable) .tile-head {
  background-color: white;
  color: #074377;
}
.tile.dark-blue:not(.switchable) .tile-head a {
  color: #074377;
}
.tile.dark-blue .tile-title {
  color: white;
}
.tile.dark-blue .tile-title:after {
  border-color: white;
}
.tile.dark-blue.edit-mode .tile-head .tile-title:after {
  display: none;
}
.tile.dark-blue.edit-mode .tile-head + .tile-body {
  padding-top: 30px;
  padding-bottom: 30px;
}
.tile.dark-blue.edit-mode .tile-body {
  background: white;
  color: #121e2c;
}
.tile.light-blue {
  background-color: #37a8db;
}
.tile.light-blue:not(.switchable) .tile-head {
  background-color: white;
  color: #37a8db;
}
.tile.light-blue:not(.switchable) .tile-head a {
  color: #37a8db;
}
.tile.light-blue .tile-title {
  color: white;
}
.tile.light-blue .tile-title:after {
  border-color: white;
}
.tile:not(.shared-feed) .tile {
  border: 1px solid #d2d3d5;
}
.tile .info {
  margin: 20px 0;
}
.tile .feed-notice {
  padding-left: 80px;
  padding-bottom: 0;
  color: #848484;
}
.tile .feed-notice .picto {
  margin-left: -28px;
}
.tile .feed-notice + .tile .tile-body {
  padding-top: 0;
}
.tile .feed-notice + .info {
  margin-left: 80px;
}
.tile.shared-feed .tile .tile-body {
  padding-left: 0;
  padding-right: 0;
}
.tile.content-detail-tile {
  height: 370px;
}
.tile.parent .tile {
  border-radius: 10px;
}
.tile.parent .tile .options-menu {
  z-index: 1;
}
.tile.condensed .tile-body {
  padding: 12px;
}
.tile.condensed .tile-body .feed-notice {
  margin-bottom: 10px;
  padding-left: 60px;
}
.tile.condensed .tile-body .feed-notice + .tile.condensed .tile-body {
  padding-left: 0;
  padding-right: 0;
}
.tile.condensed .tile-body .feed-notice + .tile.condensed .tile-body .feed-item .profile-pic {
  width: 50px;
  height: 50px;
}
.tile.condensed .tile-body .feed-notice + .tile.condensed .tile-body .feed-item > * {
  width: calc(100% - 60px);
}
.tile.condensed .tile-body .feed-notice + .tile.condensed .tile-body .feed-footer .tile-body {
  padding-left: 10px;
  padding-right: 10px;
}
.tile.condensed .tile-body .feed-item {
  margin-top: 0;
  margin-bottom: 0;
}
.tile.condensed .tile-body .feed-item .profile-pic {
  width: 50px;
  height: 50px;
}
.tile.condensed .tile-body .feed-item > * {
  width: calc(100% - 60px);
}
.tile.condensed .tile-body .feed-footer .tile-body {
  padding: 10px;
}
.tile.condensed .tile-body .feed-footer .tile-body .feed-item {
  overflow: visible;
}
.tile.condensed .tile-body .feed-footer .tile-body .feed-item .profile-pic {
  margin-top: 5px;
}
.tile.condensed .tile-body .feed-footer .tile-body .feed-item .options-menu {
  top: 0;
  right: -5px;
}
.tile.condensed .tile-body .feed-footer .tile-body .feed-item .options-menu.open {
  right: 0;
}
.tile.condensed .tile-body .feed-footer .tile-body .feed-item .feed-item {
  /* nested feed-item (comments history in inspiration feed for example */
  margin: 10px 0;
}
.tile.condensed .tile-body .tile-body {
  /* nested feeds */
  padding: 10px;
}
.tile.condensed .tile-body .tile-body .feed-item .profile-pic {
  width: 40px;
  height: 40px;
}
.tile.condensed .tile-body .tile-body .feed-item > * {
  width: calc(100% - 47px);
}
.tile.condensed .options-menu.open {
  right: 15px;
}
.tile.tile-padding-xl {
  padding: 10px 60px;
}
.tile.tile-padding-xl .tile-body > div + * {
  margin-top: 20px;
}
.help-section .tile:not(:last-child) {
  border-bottom: 1px solid #121e2c;
}
.my-ptn-tile {
  color: #121e2c;
  text-align: center;
}
.my-ptn-tile .tile-title {
  text-align: center;
  font-weight: 400;
  font-size: 50px;
  letter-spacing: 2px;
  font-size: 32px;
  padding: 40px 0 5px;
  line-height: 1em;
  color: #121e2c;
}
.my-ptn-tile .tile-title.smaller,
.my-ptn-tile .tile-title .smaller {
  font-weight: 100;
  font-size: 0.7em;
}
.my-ptn-tile .tile-title:after {
  display: none;
}
.my-ptn-tile .tile-subtitle {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 20px;
}
.my-ptn-tile .tile-body {
  padding-left: 100px;
  padding-right: 100px;
}
.my-ptn-tile .thumbs-grid {
  display: flex;
  justify-content: center;
  margin-bottom: 48px;
}
.my-ptn-tile .thumbs-grid .card {
  max-width: 25%;
}
.my-ptn-tile .thumbs-grid .card + .card {
  margin-left: 20px;
}
.my-ptn-tile .tile-footer {
  text-align: center;
  padding: 20px 0 10px;
}
.user-tile {
  text-align: center;
}
.user-tile .profile-pic {
  display: inline-block;
  width: 75px;
  height: 75px;
}
.user-tile .user-name {
  font-weight: 400;
  font-size: 16px;
  font-size: 14px;
  font-weight: 600;
  color: black;
  margin: 10px 0;
}
.parameters-tile .tile-title {
  color: black;
  text-align: left;
  border-bottom: 1px solid #848484;
  padding-bottom: 20px;
  margin: 40px 0 0 0;
}
.parameters-tile .parameter-list-item {
  padding-top: 20px;
  padding-bottom: 20px;
}
.parameters-tile .parameter-list-item:not(:last-child) {
  border-bottom: 1px solid #d2d3d5;
}
.parameters-tile .parameter-name {
  color: black;
  text-align: left;
  padding: 10px 0;
  margin: 0;
  border: none;
}
.parameters-tile .parameter-name .picto {
  margin-left: 0;
}
.parameters-tile .uk-grid > [class^="uk-width"] {
  padding-top: 5px;
  padding-bottom: 5px;
}
.content-card {
  background: white;
  padding: 20px;
  color: black;
  text-align: left;
  position: relative;
}
.content-card .picto.action {
  position: absolute;
  top: 10px;
  right: 5px;
}
.content-card .thumbnail {
  display: block;
  height: 130px;
  text-align: center;
  margin-bottom: 20px;
}
.content-card .thumbnail > * {
  display: inline-block;
  max-height: 100%;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.1);
}
.content-card .thumbnail.no-fixed-height {
  height: auto;
}
.content-card .thumbnail.no-fixed-height img {
  max-height: 130px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.content-card .card-title {
  font-weight: 600;
  font-size: 18px;
  border-bottom: 1px solid black;
  border-color: inherit;
  padding-bottom: 0.8em;
  font-size: 16px;
  padding: 0;
  border: none;
}
.content-card .card-title:after {
  content: "";
  display: block;
  width: 80px;
  height: 0;
  margin: 0.5em 0 10px;
  border-top: 1px solid black;
}
.content-card .category {
  font-weight: 400;
  font-size: 16px;
  text-transform: uppercase;
  color: #37a8db;
  margin: 0;
}
.content-card a {
  color: #37a8db;
}
.content-card .author {
  font-weight: 400;
  font-size: 16px;
  font-size: 14px;
  margin: 0;
}
.content-card .text {
  margin-top: 10px;
  white-space: pre-wrap;
}
.content-card .card-footer {
  box-sizing: border-box;
  position: relative;
  margin-top: 20px;
}
.content-card .card-footer > * {
  font-weight: 400;
  font-size: 16px;
  font-size: 14px;
}
.content-card .card-footer .action {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: absolute;
  margin: 0;
  bottom: 0;
  top: auto;
  right: 0;
}
.content-card .card-footer .action .picto {
  position: relative;
  height: 23.2px;
  top: -1px;
  margin: 0;
}
.content-card .card-footer .action *:not(:first-child) {
  margin-left: 3px;
}
.content-card .card-footer .btn-icon-plus {
  position: absolute;
  padding: 0;
  bottom: 0;
  left: 50%;
  margin-bottom: -10px;
  margin-left: -12px;
}
.content-card .card-footer .btn-icon-plus:after {
  margin-left: 0;
}
.content-card.fixed-size {
  padding-bottom: 78px;
}
.content-card.fixed-size .card-body {
  height: 350px;
  overflow: hidden;
}
.content-card.fixed-size .card-footer {
  height: 58px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 20px;
}
.content-card.fixed-size .card-footer:before {
  content: '';
  display: block;
  position: absolute;
  height: 80px;
  width: 100%;
  left: 0;
  top: -80px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
}
.content-card.fixed-size .card-footer .action {
  bottom: 10px;
  right: 20px;
}
.content-card.list-display {
  display: flex;
  margin-bottom: 1px;
}
.content-card.list-display .thumbnail {
  width: 25%;
  max-width: 250px;
  min-width: 150px;
  height: auto;
  margin: 0 20px 0 0;
  display: flex;
  justify-content: center;
}
.content-card.list-display .thumbnail > * {
  max-width: 100%;
  max-height: 130px;
}
.content-card.list-display .card-body {
  width: 100%;
}
.content-card.list-display .card-title {
  margin-top: 0;
}
.content-card.list-display .card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.content-card.block-display {
  display: flex;
  height: 370px;
  position: relative;
  overflow-y: auto;
  padding-right: 10px;
  scrollbar-color: #37a8db #eee;
  /* Track */
  /* Handle */
}
.content-card.block-display::-webkit-scrollbar {
  width: 8px;
}
.content-card.block-display::-webkit-scrollbar-track {
  background: #eee;
  border-radius: 5px;
}
.content-card.block-display::-webkit-scrollbar-thumb {
  background: #37a8db;
  border-radius: 5px;
}
.content-card.block-display .picto.action {
  right: 70px;
}
.content-card.block-display .options-menu {
  right: 40px;
}
.content-card.block-display .btn-icon-close {
  position: absolute;
  top: 0;
  right: 5px;
}
.content-card.block-display .card-head {
  width: 25%;
  max-width: 250px;
  min-width: 150px;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
}
.content-card.block-display .card-head .thumbnail {
  margin: 0 auto 10px;
  height: auto;
  max-height: 130px;
  max-width: 100%;
}
.content-card.block-display .card-head .author,
.content-card.block-display .card-head .btn {
  margin-bottom: 20px;
}
.content-card.block-display .card-body {
  padding: 20px;
}
.content-card.block-display .content {
  padding-bottom: 20px;
}
.payment-card {
  background-color: rgba(55, 168, 219, 0.05);
  padding: 1rem;
}
.payment-card > * {
  border: 2px solid rgba(114, 146, 160, 0.15);
  border-top: 0;
  padding: 1rem;
}
.payment-card > *:last-child {
  border-radius: 0 0 5px 5px;
}
.payment-card .card-header {
  font-weight: 600;
  font-size: 18px;
  border-bottom: 1px solid black;
  border-color: inherit;
  padding-bottom: 0.8em;
  border: none;
  padding-bottom: 0;
  border: 2px solid rgba(114, 146, 160, 0.15);
  border-radius: 5px 5px 0 0;
  padding: 1rem;
}
.payment-card .StripeElement {
  background-color: #fff;
  border: 1px solid rgba(114, 146, 160, 0.25);
  padding: 1rem ;
}
.tribe-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  padding: 0 30px;
  justify-content: space-between;
}
.tribe-card .tribe-card-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  background: linear-gradient(to right, #074377, #37a8db);
  border-radius: 25px;
  width: 100%;
  padding: 30px;
}
.tribe-card .tribe-card-head.active {
  border-bottom: solid 3px #070f18;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.tribe-card .tribe-card-head .tribe-name {
  font-size: 24px;
  font-weight: 600;
  margin: 0 20px;
}
.tribe-card .tribe-card-head .tribe-nb-members {
  font-size: 24px;
}
.tribe-card .tribe-card-head .member-picto {
  width: 24px;
  height: 24px;
  margin: 0 5px;
}
.tribe-card .tribe-card-body {
  width: 100%;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  background-color: white;
  padding: 0 30px 30px;
}
.tribe-card .tribe-card-body .tribe-card-member-list {
  margin: 0 20px;
  max-height: 400px;
}
.tribe-card .tribe-card-body .tribe-card-member-list .tile-body {
  padding: 20px 10px;
  width: 7em;
}
.tribe-card .tribe-card-body .tribe-card-member-list .user-name {
  text-decoration: none;
}
.tribe-card .tribe-card-body .tribe-card-member-list .user-name:hover p {
  color: #37a8db;
}
.tribe-card .tribe-card-body .tribe-card-member-list .affinity {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tribe-card .tribe-card-body .tribe-card-member-list .affinity .icon {
  width: 1em;
  height: 1em;
  margin: 0 5px;
}
.tribe-card .tribe-card-body .tribe-card-member-list.scroll {
  overflow-y: scroll;
}
.link-tabs {
  display: flex;
  justify-content: space-between;
}
.link-tabs .tab-item {
  color: #6a7581;
  text-transform: uppercase;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0 0 5px;
  border-bottom: 2px solid transparent;
}
.link-tabs .tab-item.no-hover {
  cursor: auto;
}
.link-tabs .tab-item:hover:not(.no-hover) {
  color: white;
}
.link-tabs .tab-item:not(.toggle-popin-menu).active,
.link-tabs .tab-item:not(.toggle-popin-menu):active,
.link-tabs .tab-item:not(.toggle-popin-menu):focus {
  color: white;
  border-bottom: 2px solid #37a8db;
  padding-bottom: 5px;
  outline: none;
}
.link-tabs .tutorial-tab-item {
  color: white;
  text-transform: uppercase;
  font-size: 22px;
  text-decoration: none;
  cursor: pointer;
  background: transparent;
  border: none;
  font-weight: 600;
  padding: 0 0 5px;
  border-bottom: 2px solid transparent;
}
.link-tabs .tutorial-tab-item.no-hover {
  cursor: auto;
}
.link-tabs .tutorial-tab-item:hover:not(.no-hover) {
  color: #37a8db;
}
.link-tabs .tutorial-tab-item:not(.toggle-popin-menu).active,
.link-tabs .tutorial-tab-item:not(.toggle-popin-menu):active,
.link-tabs .tutorial-tab-item:not(.toggle-popin-menu):focus {
  color: white;
  border-bottom: 2px solid #37a8db;
  padding-bottom: 5px;
  outline: none;
}
.chip {
  display: inline-block;
  background-color: #121e2c;
  font-weight: 300;
  color: #37a8db;
  font-size: 12px;
  padding: 7px 15px;
  border-radius: 25px;
}
.chip.chip-white {
  background-color: white;
  color: #37a8db;
  font-weight: 600;
}
.chip .picto {
  height: 1.1em;
  top: 1px;
  margin-right: 1px;
}
.counter-chip {
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 10px;
  display: inline-block;
  background-color: #37a8db;
  color: white;
  border-radius: 20px;
}
.inspiration-image {
  border-radius: 20px;
  width: 100%;
  margin: 10px 0;
}
.profile-pic {
  position: relative;
  width: 150px;
  height: 150px;
  background-color: #848484;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  border-bottom-right-radius: 0;
  /* profile pic upload component */
}
.profile-pic input[type=file] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.profile-pic input[type=file] + label {
  position: absolute;
  bottom: 10px;
  right: 10px;
  height: 15px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.profile-pic input[type=file] + label img {
  width: 100%;
}
.round-thumbnail,
.square-thumbnail {
  display: inline-block;
  width: 100%;
  text-align: center;
  text-decoration: none;
}
.round-thumbnail .pic,
.square-thumbnail .pic {
  display: inline-block;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.round-thumbnail .pic canvas,
.square-thumbnail .pic canvas {
  width: 100%;
}
.round-thumbnail p,
.square-thumbnail p {
  padding-top: 5px;
  color: #121e2c;
  font-size: 13px;
  font-weight: 600;
}
.round-thumbnail .pic {
  border-radius: 50%;
}
/* POPIN-MENU */
.toggle-popin-menu .toggle-button {
  padding: 5px 0;
  font-weight: 400;
  font-size: 16px;
  font-size: 14px;
  color: #d2d3d5;
  line-height: 1em;
  cursor: pointer;
}
.toggle-popin-menu .toggle-button.active {
  border-bottom: 2px solid #37a8db;
}
.toggle-popin-menu .toggle-button:after {
  content: '';
  display: inline-block;
  position: relative;
  top: 0.2em;
  width: 1em;
  height: 1em;
  margin-left: 0.3em;
  color: #d2d3d5;
  background: url('../img/pictos/chevron-down-light-grey.svg') no-repeat center center;
  background-size: contain;
  transition: transform 0.2s;
}
.toggle-popin-menu .popin-menu {
  position: absolute;
  left: 0;
  top: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s;
}
.toggle-popin-menu.opened .toggle-button:after {
  transform: rotate(180deg);
}
.toggle-popin-menu.opened .popin-menu {
  visibility: visible;
  top: 40px;
  opacity: 1;
}
.popin-menu {
  position: relative;
  background-color: #070f18;
  padding: 40px;
  display: inline-block;
  z-index: 10;
  box-sizing: border-box;
}
.popin-menu .item {
  font-weight: 600;
  font-size: 13px;
  line-height: 1em;
  border: none;
  letter-spacing: 0;
  text-transform: initial;
  background-color: transparent;
  display: inline-block;
  width: 100%;
  text-align: left;
  font-size: 12px;
  line-height: 2em;
  color: white;
  cursor: pointer;
  text-decoration: none;
}
select.popin-menu .item {
  appearance: none;
}
a.popin-menu .item {
  text-decoration: none;
}
.popin-menu .item + .btn {
  margin-left: 15px;
}
.popin-menu .item.btn-block {
  display: block;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
}
.popin-menu .item.btn-block + .btn {
  margin-left: 0;
}
.popin-menu .item:focus {
  outline: 0;
}
.popin-menu .item.btn-1 {
  color: white;
  background: linear-gradient(to right, #074377, #37a8db);
  border-radius: 30px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
  padding: 12px 30px;
}
.popin-menu .item.btn-1:hover:not([disabled]):not(.disabled) {
  background: linear-gradient(to left, #074377, #37a8db);
  color: #121e2c;
  animation: push 0.3s linear;
}
.popin-menu .item.btn-1.btn-wider {
  padding-left: 50px;
  padding-right: 50px;
}
.popin-menu .item.btn-outlined {
  background: none;
  border-radius: 30px;
  padding: 10px 28px;
  color: #074377;
  border: 1px solid #074377;
}
.popin-menu .item.btn-outlined.light {
  color: white;
  border: 1px solid white;
}
.popin-menu .item.btn-outlined.black {
  color: black;
  border-color: black;
}
.popin-menu .item.btn-outlined:not([disabled]):not(.disabled):hover {
  color: #37a8db;
  border-color: #37a8db;
}
.popin-menu .item.btn-outlined:not([disabled]):not(.disabled):hover:not(select) {
  animation: push 0.3s linear;
}
.popin-menu .item.btn-outlined:not([disabled]):not(.disabled).active,
.popin-menu .item.btn-outlined:not([disabled]):not(.disabled).active:hover {
  background: #37a8db;
  color: #121e2c;
  border-color: #37a8db;
}
.popin-menu .item.btn-outlined.light-blue {
  color: #37a8db;
  border-color: #37a8db;
}
.popin-menu .item.btn-outlined.light-blue:hover:not([disabled]):not(.disabled) {
  color: #074377;
  border-color: #074377;
}
.popin-menu .item.btn-outlined.light-blue.active,
.popin-menu .item.btn-outlined.light-blue.active:hover:not([disabled]):not(.disabled) {
  background: #074377;
  color: #121e2c;
  border-color: #074377;
}
.popin-menu .item.btn-outlined.btn-big {
  border-width: 2px;
}
select.popin-menu .item.btn-outlined {
  background-image: url('../img/pictos/chevron-down-light-grey.svg');
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
  background-size: 1.2em;
}
.popin-menu .item.btn-big {
  font-size: 16px;
  padding: 18px 50px;
}
.popin-menu .item .btn-icon:after {
  content: '';
  display: inline-block;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  border-radius: 50%;
  font-size: 25px;
  background-color: #121e2c;
  color: white;
  font-weight: 300;
  margin-left: 10px;
  position: relative;
  top: 3px;
  transition: transform 0.5s;
  transform: rotate(0deg);
}
.popin-menu .item.btn-icon-plus:after {
  content: '';
  display: inline-block;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  border-radius: 50%;
  font-size: 25px;
  background-color: #121e2c;
  color: white;
  font-weight: 300;
  margin-left: 10px;
  position: relative;
  top: 3px;
  transition: transform 0.5s;
  transform: rotate(0deg);
}
.popin-menu .item.btn-icon-plus:after {
  content: '+';
}
.popin-menu .item.btn-icon-plus:hover:not([disabled]):not(.disabled):after {
  transform: rotate(90deg);
}
.popin-menu .item.btn-icon-minus:after {
  content: '';
  display: inline-block;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  border-radius: 50%;
  font-size: 25px;
  background-color: #121e2c;
  color: white;
  font-weight: 300;
  margin-left: 10px;
  position: relative;
  top: 3px;
  transition: transform 0.5s;
  transform: rotate(0deg);
}
.popin-menu .item.btn-icon-minus:after {
  content: '-';
  line-height: 22px;
}
.popin-menu .item.btn-icon-minus:hover:not([disabled]):not(.disabled):after {
  transform: rotate(180deg);
}
.popin-menu .item.btn-icon-close {
  transform: rotate(45deg);
}
.popin-menu .item.btn-icon-close:after {
  content: '';
  display: inline-block;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  border-radius: 50%;
  font-size: 25px;
  background-color: #121e2c;
  color: white;
  font-weight: 300;
  margin-left: 10px;
  position: relative;
  top: 3px;
  transition: transform 0.5s;
  transform: rotate(0deg);
}
.popin-menu .item.btn-icon-close:after {
  content: '+';
}
.popin-menu .item.btn-icon-close:hover:not([disabled]):not(.disabled):after {
  transform: rotate(90deg);
}
.popin-menu .item.link-btn {
  color: #6a7581;
}
.popin-menu .item.link-btn:hover:not([disabled]):not(.disabled) {
  text-decoration: underline;
}
.popin-menu .item.link-btn.small-link-btn {
  font-size: 12px;
  font-weight: 400;
}
.popin-menu .item.link-btn.underlined {
  text-decoration: underline;
}
.popin-menu .item.link-btn.underlined:hover {
  color: #37a8db;
}
.popin-menu .item.link-btn.light {
  color: white;
}
.popin-menu .item.link-btn.light.btn-icon-plus:after {
  background-color: white;
  color: #121e2c;
}
.popin-menu .item.link-btn.blue {
  color: #37a8db;
}
.popin-menu .item.link-btn.dark-blue {
  color: #074377;
}
.popin-menu .item.link-btn.black {
  color: black;
}
.popin-menu .item.link-btn.thin {
  font-weight: 400;
}
.popin-menu .item.btn-toggle {
  color: #37a8db;
  font-weight: 400;
  font-size: 16px;
  font-size: 14px;
  text-transform: uppercase;
}
.popin-menu .item.btn-toggle:after {
  content: '';
  display: inline-block;
  position: relative;
  top: 0.2em;
  width: 1em;
  height: 1em;
  margin-left: 0.3em;
  color: #d2d3d5;
  background: url('../img/pictos/picto-chevron-down-light-blue.svg') no-repeat center center;
  background-size: contain;
  transition: transform 0.2s;
}
.popin-menu .item.btn-toggle.toggled:after {
  transform: rotate(180deg);
}
.popin-menu .item[disabled],
.popin-menu .item.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.popin-menu .item[disabled]:hover,
.popin-menu .item.disabled:hover {
  animation: none;
}
.popin-menu .item.btn-uppercase {
  text-transform: uppercase;
}
.popin-menu .item.thumbnail-hidden {
  color: white;
  background: linear-gradient(to right, #c7994a, #cd5c5c);
  border-radius: 30px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
  padding: 12px 30px;
}
.popin-menu .item.thumbnail-hidden:hover:not([disabled]):not(.disabled) {
  background: linear-gradient(to left, #c7994a, #cd5c5c);
  color: #121e2c;
  animation: push 0.3s linear;
}
.popin-menu .item.thumbnail-hidden.btn-wider {
  padding-left: 50px;
  padding-right: 50px;
}
.popin-menu .item.btn-borderless {
  border: none;
  background: none;
  padding: 0;
}
.popin-menu .item.btn-borderless.white {
  color: white;
}
.popin-menu .item.btn-borderless.light-blue {
  color: #37a8db;
}
.popin-menu .item.btn-borderless.black {
  color: black;
}
.popin-menu .item:hover,
.popin-menu .item.active {
  color: #37a8db;
}
.popin-menu .item.disabled {
  color: #6a7581;
  cursor: not-allowed;
}
.popin-menu .chevron {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: inherit;
  transform: rotate(45deg);
  top: -10px;
  left: 50%;
  margin-left: -10px;
}
.popin-menu .menu-section-title {
  color: white;
  margin: 40px 0 20px;
  text-transform: none;
  font-weight: 400;
  font-size: 16px;
  font-size: 12px;
  font-weight: 600;
}
.popin-menu .menu-section-title.smaller,
.popin-menu .menu-section-title .smaller {
  font-weight: 100;
}
.popin-menu.chevron-right:before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: inherit;
  transform: rotate(45deg);
  top: -10px;
  left: 50%;
  margin-left: -10px;
  left: auto;
  right: 10%;
}
.popin-menu.chevron-left:before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: inherit;
  transform: rotate(45deg);
  top: -10px;
  left: 50%;
  margin-left: -10px;
  left: 10%;
}
.gallery {
  position: relative;
}
.gallery .popin-menu {
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 85px;
  left: 0;
}
.gallery .load-more {
  margin: 20px 0 30px 0;
}
.gallery-head {
  display: flex;
  align-items: center;
  padding: 25px 0 10px 0;
  margin-bottom: 30px;
  border-bottom: 1px solid white;
}
/* EO POPIN-MENU */
/* FILTER CHIPS */
.active-filter-chip {
  background: none;
  border-radius: 30px;
  padding: 5px 40px 5px 20px;
  color: white;
  border: 1px solid white;
  text-transform: none;
}
.active-filter-chip .btn-icon-close {
  position: absolute;
  right: 0;
  top: -6px;
  z-index: 2;
}
.active-filter-chip .btn-icon-close:after {
  background: transparent;
}
.active-filter-chip:hover {
  color: #37a8db;
  border-color: #37a8db;
}
.active-filter-chip:hover .btn-icon-close {
  color: #37a8db;
}
.active-filter-chip + .active-filter-chip {
  margin-left: 10px;
}
/* INLINE-SLIDE-SELECTOR */
.inline-slide-selector {
  padding: 15px 0 15px 30px;
}
.inline-slide-selector .iss-item {
  color: white;
  text-decoration: none;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 400;
}
.inline-slide-selector .iss-item:not(:first-child) {
  margin-left: 1em;
}
.inline-slide-selector .iss-item.active,
.inline-slide-selector .iss-item:hover {
  color: #37a8db;
}
.inline-slide-selector .slick-prev,
.inline-slide-selector .slick-next {
  width: 30px;
  height: 30px;
}
.inline-slide-selector .slick-prev:hover,
.inline-slide-selector .slick-next:hover {
  background-color: #121e2c;
}
/* EO INLINE-SLIDE-SELECTOR */
/* UPLOAD-PIC */
.custom-upload input[type=file] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.custom-upload label {
  font-size: 16px;
  color: #37a8db;
  cursor: pointer;
}
.custom-upload label img {
  width: 30px;
}
.custom-upload + .uploaded-img {
  margin-top: 0.5em;
  width: 100%;
}
.upload-box {
  background-color: #d2d3d5;
  padding: 200px 50px;
  text-align: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.upload-box input[type=file] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.upload-box label {
  font-size: 16px;
  color: #37a8db;
  cursor: pointer;
}
.upload-box label img {
  width: 30px;
}
.upload-box + .uploaded-img {
  margin-top: 0.5em;
  width: 100%;
}
.upload-box.preview {
  background-color: transparent;
}
.light-upload-box {
  background-color: #d2d3d5;
  padding: 200px 50px;
  text-align: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  padding: 5px;
}
.light-upload-box input[type=file] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.light-upload-box label {
  font-size: 16px;
  color: #37a8db;
  cursor: pointer;
}
.light-upload-box label img {
  width: 30px;
}
.light-upload-box + .uploaded-img {
  margin-top: 0.5em;
  width: 100%;
}
.light-upload-box.preview {
  background-color: transparent;
}
.light-upload-box label {
  margin: 0;
}
.light-upload-box.preview {
  background-size: cover;
}
.light-upload-box.preview label {
  opacity: 0;
}
.light-upload-box.preview:hover label {
  opacity: 0.8;
}
/* EO UPLOAD-PIC */
/* SLIDE SWITCH */
.slide-switch {
  display: inline-block;
  position: relative;
  width: 100%;
  max-width: 500px;
  height: 40px;
  border-radius: 20px;
  background-color: #228dbd;
}
.slide-switch .switch-cursor {
  position: absolute;
  display: inline-block;
  left: 20%;
  width: 20%;
  height: 40px;
  border-radius: 20px;
  background-color: #121e2c;
  opacity: 0.8;
  z-index: 1;
  transition: all 0.2s;
}
.slide-switch .switch-cursor.pos-1 {
  left: 0;
  opacity: 1;
}
.slide-switch .switch-cursor.pos-2 {
  left: 50%;
  margin-left: -10%;
  opacity: 1;
}
.slide-switch .switch-cursor.pos-3 {
  left: 100%;
  margin-left: -20%;
  opacity: 1;
}
.slide-switch .switch-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  height: 100%;
  z-index: 2;
}
.slide-switch .switch-option {
  width: 20%;
  height: 100%;
  line-height: 40px;
  cursor: pointer;
}
.slide-switch .switch-option .icon {
  display: inline-block;
  height: 24px;
  margin-top: 8px;
  transition: transform 0.2s;
}
.slide-switch .switch-option:hover .icon {
  transform: scale(1.2);
}
.slide-switch .switch-option .switch-caption {
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1em;
  color: #1a6d92;
  transition: none;
}
.slide-switch .switch-option .switch-caption .main {
  display: inline-block;
  width: 100%;
  font-weight: 600;
}
.slide-switch:not(.static) .switch-option.active .switch-caption {
  color: white;
}
.slide-switch.static .switch-cursor {
  display: none;
}
.slide-switch.static .switch-option {
  cursor: initial;
}
.slide-switch.static .switch-option:hover .icon {
  transform: none;
}
.slide-switch.static .switch-option.active {
  color: initial;
}
/* SLIDE SWITCH */
/* STEPS */
.steps {
  display: flex;
  align-items: center;
  justify-content: center;
}
.steps .step {
  padding: 0 20px;
  text-align: center;
  font-weight: 100;
  font-size: 22px;
}
.steps .step.current {
  font-weight: 600;
}
.steps .step.current + .sep {
  visibility: visible;
  opacity: 0.2;
}
.steps .step.done {
  font-weight: 600;
  color: #37a8db;
}
.steps .step.done + .sep {
  visibility: visible;
  background-color: #37a8db;
}
.steps .sep {
  width: 200px;
  height: 1px;
  background: #121e2c;
  visibility: hidden;
}
/* STEPS */
.anonymity-and-certification {
  height: 400px;
  overflow-y: auto;
  padding-right: 10px;
  scrollbar-color: #37a8db #eee;
  /* Track */
  /* Handle */
}
.anonymity-and-certification::-webkit-scrollbar {
  width: 8px;
}
.anonymity-and-certification::-webkit-scrollbar-track {
  background: #eee;
  border-radius: 5px;
}
.anonymity-and-certification::-webkit-scrollbar-thumb {
  background: #37a8db;
  border-radius: 5px;
}
/* TITLE NAV PREV/NEXT */
/* TODO: refactor cf. slick-slider prev/next */
.title-with-nav {
  position: relative;
  padding-left: 60px;
  padding-right: 60px;
}
.title-with-nav .arrow {
  display: inline-block;
  width: 60px;
  height: 60px;
  position: absolute;
  top: 50%;
  margin-top: -30px;
  background-size: cover;
  z-index: 2;
  background-color: #070f18;
  background-position: center center;
}
.title-with-nav .arrow:before {
  display: none;
}
.title-with-nav .arrow:hover {
  background-color: #37a8db;
}
.title-with-nav .arrow.slick-disabled {
  pointer-events: none;
  opacity: 0.2;
}
.title-with-nav .arrow.prev {
  left: 0;
  background-image: url('../img/slider-prev.png');
}
.title-with-nav .arrow.prev:hover {
  background-position: -5px;
}
.title-with-nav .arrow.next {
  right: 0;
  background-image: url('../img/slider-next.png');
}
.title-with-nav .arrow.next:hover {
  background-position: 5px;
}
/* PREV/NEXT */
/* PROFILE > PAANTEON PROCESS */
.paanteon-process {
  height: 544px;
  box-sizing: border-box;
  background: #082a49 url('../img/paanteon-bg.png') bottom center no-repeat;
  background-size: contain;
  color: white;
  padding: 20px;
  padding-top: 5%;
}
.paanteon-process .title {
  text-align: center;
  font-weight: 600;
  font-size: 30px;
  letter-spacing: 1px;
  font-weight: 300;
  margin-bottom: 10px;
}
.paanteon-process .subtitle {
  text-align: center;
  font-weight: 300;
}
.paanteon-process .subtitle strong {
  font-weight: 600;
  color: #37a8db;
}
.paanteon-process img {
  display: block;
  margin: 12% auto auto;
  width: 100%;
  max-width: 700px;
}
.paanteon-process .btn {
  margin-top: 12%;
}
/* PROFILE > PAANTEON PROCESS */
/* LIST OF USERS */
.users-list {
  margin: 20px 0;
}
.users-list .user-item {
  display: flex;
  align-items: center;
  justify-content: left;
  margin: 10px 0;
  font-size: 14px;
  font-weight: 600;
  color: black;
}
.users-list .user-item .profile-pic {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}
/* LIST OF USERS */
.gallery-grid > div {
  margin-bottom: 20px;
}
.gallery-grid.condensed {
  /* just one row of 3 thumbs displayed for screens <= 1200px (@breakpoint-large) */
  /* 4 thumbs for bigger screens */
}
.gallery-grid.condensed > div:nth-child(n+4) {
  display: none;
}
@media (min-width: 1200px) {
  .gallery-grid.condensed > div:nth-child(4) {
    display: initial;
  }
}
.collection-title + .gallery-grid {
  margin-top: 20px;
}
/* find a better place to write this rule?*/
.tribute-quick-actions {
  text-align: center;
  padding-top: 20px;
}
.tribute-quick-actions span {
  padding: 0 20px;
}
/* pending invitation component */
.pending-invitation {
  display: flex;
  align-items: center;
  width: calc(100% - 30px);
}
.pending-invitation .profile-pic {
  flex-shrink: 0;
  width: 60px;
  height: 60px;
}
.pending-invitation .btn {
  width: 20%;
}
.pending-invitation .user-info {
  flex-grow: 1;
  font-weight: 400;
  font-size: 16px;
  font-size: 12px;
  font-weight: 600;
  color: black;
  margin-left: 20px;
  padding-right: 10px;
}
.pending-invitation .user-info.smaller,
.pending-invitation .user-info .smaller {
  font-weight: 100;
}
.pending-invitation .user-info > * {
  width: 100%;
}
.pending-invitation .bio {
  margin-top: 10px !important;
}
/* options menu component (contextual menu represented by three dots button) */
.options-menu {
  display: inline-block;
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 2;
  transition: border-color 0.3s linear;
  background: white;
}
.options-menu ul {
  display: none;
  color: black;
  list-style-type: none;
  font-weight: 400;
  font-size: 16px;
  font-size: 12px;
  padding: 7px 100px 7px 20px;
}
.options-menu ul.smaller,
.options-menu ul .smaller {
  font-weight: 100;
}
.options-menu ul li {
  margin: 5px 0;
}
.options-menu a {
  text-decoration: none;
}
.options-menu a:hover {
  color: #37a8db;
}
.options-menu .icon {
  content: '';
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 10px;
  background: url('../img/pictos/picto-options.svg') no-repeat right center;
  background-size: contain;
  cursor: pointer;
  z-index: 6;
  transition: background-image 0.3s linear;
}
.options-menu.open {
  border: 1px solid #d2d3d5;
}
.options-menu.open ul {
  display: inline-block;
}
.options-menu.open .icon {
  background-image: url('../img/pictos/picto-close-blue.svg');
}
/* tribute writing bloc (tribute's article edition RTE, optionnal and collapsed by default) */
.tribute-writing-bloc {
  position: relative;
}
.tribute-writing-bloc .btn,
.tribute-writing-bloc .rte {
  /* all these properties are used for animation*/
  position: relative;
  top: 0;
  opacity: 1;
  transition: all 0.5s;
  overflow: hidden;
}
.tribute-writing-bloc.collapsed .btn,
.tribute-writing-bloc.collapsed .rte {
  height: 0;
  opacity: 0;
  position: absolute;
  top: -30px;
}
.tribute-writing-bloc .action-icons {
  position: absolute;
  top: 10px;
  right: 20px;
  width: 20px;
  text-align: right;
}
.tribute-writing-bloc .action-icons a,
.tribute-writing-bloc .action-icons button {
  padding: 0;
  display: inline-block;
}
.tribute-writing-bloc .action-icons a:not(:first-child),
.tribute-writing-bloc .action-icons button:not(:first-child) {
  margin-top: 10px;
}
.tribute-writing-bloc .action-icons a img,
.tribute-writing-bloc .action-icons button img {
  width: 20px;
}
.load-more {
  width: 300px;
  height: 1px;
  background: #6a7581;
  display: inline-block;
  position: relative;
}
.load-more:after {
  content: '';
  width: 30px;
  height: 30px;
  background: #121e2c url('../img/pictos/picto-chevron-down-white.svg') no-repeat center center;
  position: absolute;
  top: -15px;
  left: 135px;
  cursor: pointer;
  border-radius: 50%;
}
.load-more.reverse:after {
  transform: rotate(180deg);
}
.dark-blue .load-more:after {
  background-color: #074377;
}
.scroll-to-top-container {
  position: fixed;
  right: 20px;
  bottom: 40px;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  font-size: 13px;
  line-height: 1em;
  border: none;
  letter-spacing: 0;
  text-transform: initial;
  background-color: transparent;
  z-index: 20;
}
select.scroll-to-top-container {
  appearance: none;
}
a.scroll-to-top-container {
  text-decoration: none;
}
.scroll-to-top-container + .btn {
  margin-left: 15px;
}
.scroll-to-top-container.btn-block {
  display: block;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
}
.scroll-to-top-container.btn-block + .btn {
  margin-left: 0;
}
.scroll-to-top-container:focus {
  outline: 0;
}
.scroll-to-top-container.btn-1 {
  color: white;
  background: linear-gradient(to right, #074377, #37a8db);
  border-radius: 30px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
  padding: 12px 30px;
}
.scroll-to-top-container.btn-1:hover:not([disabled]):not(.disabled) {
  background: linear-gradient(to left, #074377, #37a8db);
  color: #121e2c;
  animation: push 0.3s linear;
}
.scroll-to-top-container.btn-1.btn-wider {
  padding-left: 50px;
  padding-right: 50px;
}
.scroll-to-top-container.btn-outlined {
  background: none;
  border-radius: 30px;
  padding: 10px 28px;
  color: #074377;
  border: 1px solid #074377;
}
.scroll-to-top-container.btn-outlined.light {
  color: white;
  border: 1px solid white;
}
.scroll-to-top-container.btn-outlined.black {
  color: black;
  border-color: black;
}
.scroll-to-top-container.btn-outlined:not([disabled]):not(.disabled):hover {
  color: #37a8db;
  border-color: #37a8db;
}
.scroll-to-top-container.btn-outlined:not([disabled]):not(.disabled):hover:not(select) {
  animation: push 0.3s linear;
}
.scroll-to-top-container.btn-outlined:not([disabled]):not(.disabled).active,
.scroll-to-top-container.btn-outlined:not([disabled]):not(.disabled).active:hover {
  background: #37a8db;
  color: #121e2c;
  border-color: #37a8db;
}
.scroll-to-top-container.btn-outlined.light-blue {
  color: #37a8db;
  border-color: #37a8db;
}
.scroll-to-top-container.btn-outlined.light-blue:hover:not([disabled]):not(.disabled) {
  color: #074377;
  border-color: #074377;
}
.scroll-to-top-container.btn-outlined.light-blue.active,
.scroll-to-top-container.btn-outlined.light-blue.active:hover:not([disabled]):not(.disabled) {
  background: #074377;
  color: #121e2c;
  border-color: #074377;
}
.scroll-to-top-container.btn-outlined.btn-big {
  border-width: 2px;
}
select.scroll-to-top-container.btn-outlined {
  background-image: url('../img/pictos/chevron-down-light-grey.svg');
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
  background-size: 1.2em;
}
.scroll-to-top-container.btn-big {
  font-size: 16px;
  padding: 18px 50px;
}
.scroll-to-top-container .btn-icon:after {
  content: '';
  display: inline-block;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  border-radius: 50%;
  font-size: 25px;
  background-color: #121e2c;
  color: white;
  font-weight: 300;
  margin-left: 10px;
  position: relative;
  top: 3px;
  transition: transform 0.5s;
  transform: rotate(0deg);
}
.scroll-to-top-container.btn-icon-plus:after {
  content: '';
  display: inline-block;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  border-radius: 50%;
  font-size: 25px;
  background-color: #121e2c;
  color: white;
  font-weight: 300;
  margin-left: 10px;
  position: relative;
  top: 3px;
  transition: transform 0.5s;
  transform: rotate(0deg);
}
.scroll-to-top-container.btn-icon-plus:after {
  content: '+';
}
.scroll-to-top-container.btn-icon-plus:hover:not([disabled]):not(.disabled):after {
  transform: rotate(90deg);
}
.scroll-to-top-container.btn-icon-minus:after {
  content: '';
  display: inline-block;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  border-radius: 50%;
  font-size: 25px;
  background-color: #121e2c;
  color: white;
  font-weight: 300;
  margin-left: 10px;
  position: relative;
  top: 3px;
  transition: transform 0.5s;
  transform: rotate(0deg);
}
.scroll-to-top-container.btn-icon-minus:after {
  content: '-';
  line-height: 22px;
}
.scroll-to-top-container.btn-icon-minus:hover:not([disabled]):not(.disabled):after {
  transform: rotate(180deg);
}
.scroll-to-top-container.btn-icon-close {
  transform: rotate(45deg);
}
.scroll-to-top-container.btn-icon-close:after {
  content: '';
  display: inline-block;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  border-radius: 50%;
  font-size: 25px;
  background-color: #121e2c;
  color: white;
  font-weight: 300;
  margin-left: 10px;
  position: relative;
  top: 3px;
  transition: transform 0.5s;
  transform: rotate(0deg);
}
.scroll-to-top-container.btn-icon-close:after {
  content: '+';
}
.scroll-to-top-container.btn-icon-close:hover:not([disabled]):not(.disabled):after {
  transform: rotate(90deg);
}
.scroll-to-top-container.link-btn {
  color: #6a7581;
}
.scroll-to-top-container.link-btn:hover:not([disabled]):not(.disabled) {
  text-decoration: underline;
}
.scroll-to-top-container.link-btn.small-link-btn {
  font-size: 12px;
  font-weight: 400;
}
.scroll-to-top-container.link-btn.underlined {
  text-decoration: underline;
}
.scroll-to-top-container.link-btn.underlined:hover {
  color: #37a8db;
}
.scroll-to-top-container.link-btn.light {
  color: white;
}
.scroll-to-top-container.link-btn.light.btn-icon-plus:after {
  background-color: white;
  color: #121e2c;
}
.scroll-to-top-container.link-btn.blue {
  color: #37a8db;
}
.scroll-to-top-container.link-btn.dark-blue {
  color: #074377;
}
.scroll-to-top-container.link-btn.black {
  color: black;
}
.scroll-to-top-container.link-btn.thin {
  font-weight: 400;
}
.scroll-to-top-container.btn-toggle {
  color: #37a8db;
  font-weight: 400;
  font-size: 16px;
  font-size: 14px;
  text-transform: uppercase;
}
.scroll-to-top-container.btn-toggle:after {
  content: '';
  display: inline-block;
  position: relative;
  top: 0.2em;
  width: 1em;
  height: 1em;
  margin-left: 0.3em;
  color: #d2d3d5;
  background: url('../img/pictos/picto-chevron-down-light-blue.svg') no-repeat center center;
  background-size: contain;
  transition: transform 0.2s;
}
.scroll-to-top-container.btn-toggle.toggled:after {
  transform: rotate(180deg);
}
.scroll-to-top-container[disabled],
.scroll-to-top-container.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.scroll-to-top-container[disabled]:hover,
.scroll-to-top-container.disabled:hover {
  animation: none;
}
.scroll-to-top-container.btn-uppercase {
  text-transform: uppercase;
}
.scroll-to-top-container.thumbnail-hidden {
  color: white;
  background: linear-gradient(to right, #c7994a, #cd5c5c);
  border-radius: 30px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
  padding: 12px 30px;
}
.scroll-to-top-container.thumbnail-hidden:hover:not([disabled]):not(.disabled) {
  background: linear-gradient(to left, #c7994a, #cd5c5c);
  color: #121e2c;
  animation: push 0.3s linear;
}
.scroll-to-top-container.thumbnail-hidden.btn-wider {
  padding-left: 50px;
  padding-right: 50px;
}
.scroll-to-top-container.btn-borderless {
  border: none;
  background: none;
  padding: 0;
}
.scroll-to-top-container.btn-borderless.white {
  color: white;
}
.scroll-to-top-container.btn-borderless.light-blue {
  color: #37a8db;
}
.scroll-to-top-container.btn-borderless.black {
  color: black;
}
.scroll-to-top-container .scroll-to-top {
  width: 30px;
  height: 1px;
  display: inline-block;
  position: relative;
}
.scroll-to-top-container .scroll-to-top::after {
  position: absolute;
  width: 30px;
  height: 30px;
  background: transparent url('../img/pictos/picto-chevron-down-white.svg') no-repeat center center;
  transform: rotate(180deg);
  content: '';
}
.drop-menu {
  position: absolute;
  background-color: white;
  padding: 16px;
  border: 1px solid #d2d3d5;
  min-width: 200px;
  z-index: 2;
}
.drop-menu.top-right {
  top: 44px;
  right: 2px;
}
.drop-menu.top-left {
  top: 2px;
  left: 2px;
}
.drop-menu.closed {
  visibility: hidden;
}
.drop-menu .btn-close {
  position: absolute;
  right: 2px;
  top: 4px;
}
.drop-menu .btn-close img {
  height: 1em;
}
.drop-menu ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.drop-menu ul li {
  padding-bottom: 0.3em;
}
.drop-menu .link-btn {
  font-weight: 400;
  font-size: 16px;
  font-size: 14px;
  color: black;
  padding: 10px 0;
}
.drop-menu .link-btn:hover {
  color: #37a8db;
}
.drop-menu .drop-title {
  font-weight: 600;
  font-size: 18px;
  border-bottom: 1px solid black;
  border-color: inherit;
  padding-bottom: 0.8em;
  border: none;
  padding-bottom: 0;
  border-bottom: 1px solid #d2d3d5;
  padding-bottom: 12px;
}
.drop-menu .drop-footer {
  border-top: 1px solid #d2d3d5;
  padding-top: 12px;
}
.drop-menu .drop-footer .link {
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  font-size: 14px;
}
.drop-menu .drop-body {
  padding: 8px 0;
}
@media (max-width: 960px) {
  .header .navbar .navbar-right .drop-menu.top-right {
    top: 0 !important;
  }
  .header .drop-menu {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    z-index: 30;
  }
  .header .drop-menu:not(.closed) {
    animation: menu-slide-in 0.5s ease-out;
  }
  .header .drop-menu.closed {
    animation: menu-slide-out 0.5s ease-in;
    transition: all 0s ease 0.5s;
    transform: translateX(100vw);
  }
  .header .drop-menu .drop-inner {
    max-width: 350px;
    margin: 0 auto;
  }
  .header .drop-menu .drop-picto {
    position: relative;
    display: inline-block;
    margin-right: 10px;
    width: 30px;
    height: 30px;
    top: 7px;
  }
  .header .drop-menu .drop-picto.profile-pic {
    background-color: #848484;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 50% 50% 0 50%;
  }
  .header .drop-menu .btn-close {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .header .drop-menu .btn-close img {
    width: 20px;
    height: 20px;
  }
  .header .drop-menu .drop-title {
    text-align: left;
  }
  .header .drop-menu .drop-title .drop-text {
    font-weight: 600;
    font-size: 18px;
    border-bottom: 1px solid black;
    border-color: inherit;
    padding-bottom: 0.8em;
    border: none;
    padding-bottom: 0;
  }
  .header .drop-menu .drop-body {
    text-align: left;
  }
  .header .drop-menu .drop-body-section {
    border-bottom: 1px solid #d2d3d5;
    padding-left: 40px;
  }
  .header .drop-menu .drop-footer {
    text-align: right;
    border-top: none;
  }
}
.info {
  background: #074377;
  padding: 10px;
  border-radius: 3px;
  color: white;
  font-weight: 400;
  font-size: 16px;
  font-size: 12px;
}
.info.smaller,
.info .smaller {
  font-weight: 100;
}
.info.minor {
  background: #d2d3d5;
}
.caption {
  position: absolute;
  background-color: rgba(18, 30, 44, 0.8);
  width: 300px;
  padding: 30px 35px;
}
.caption.simple-caption {
  text-align: center;
  width: 200px;
  padding-top: 35px;
  padding-bottom: 35px;
}
.caption .caption-title {
  text-transform: uppercase;
  font-weight: 300;
  font-size: 14px;
  color: #37a8db;
  line-height: 1.5em;
  margin-bottom: 7px;
}
.caption .caption-content {
  color: white;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.5em;
}
.caption .caption-description {
  color: white;
  font-weight: 300;
  font-size: 14px;
}
.caption .caption-footer {
  margin-top: 15px;
}
.caption .caption-footer .picto {
  float: right;
  color: white;
  position: absolute;
  top: auto;
  bottom: 30px;
  right: 35px;
  height: 30px;
}
.caption .caption-footer .chip .picto {
  clear: both;
  position: static;
  width: 13px;
  height: auto;
  margin-left: 7px;
}
.profile-grid {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}
.profile-grid .profile-grid-first {
  flex: 1;
  margin-right: 20px;
}
.profile-grid .sidebar-block .block-title + p {
  line-height: 1.1em;
  height: 2.2em;
}
/* JODIT RTE overrides */
.jodit-toolbar-button__button:active:not([disabled]),
.jodit-toolbar-button__button[aria-pressed='true']:not([disabled]) {
  text-decoration: underline;
  color: #37a8db;
  font-weight: 600;
}
.inspiration-feed-wall .ptn-em a {
  text-decoration: none;
}
.inspiration-feed-wall .ptn-em a:hover {
  color: inherit;
  text-decoration: underline;
}
.slick-slide .slide:before {
  content: '';
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 70px;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
}
.img-copyright {
  font-weight: 400;
  font-size: 16px;
  font-size: 10px;
  position: absolute;
  bottom: 10px;
  right: 15px;
  color: #aaa;
}
.cookie-banner {
  position: fixed;
  font-size: 12px;
  bottom: 0;
  padding: 25px 30px;
  background-color: black;
  color: #e0e4ee;
  z-index: 30;
}
.picture-preview {
  position: relative;
  max-width: 500px;
  width: 100%;
}
.picture-preview:before {
  content: '';
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 70px;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
}
.tuto-slide .tuto-slide-head {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.tuto-slide .tuto-slide-head .tuto-slide-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}
.tuto-slide .tuto-slide-head .tuto-slide-title > * {
  margin: 0 5px;
  font-weight: 600;
  font-size: 30px;
}
.tuto-slide .tuto-slide-head .tuto-slide-title .slide-number {
  color: white;
  background-color: #37a8db;
  border-radius: 50%;
  padding: 2px 10px;
}
.tuto-slide .tuto-slide-head .tuto-slide-title .slide-label {
  color: #37a8db;
}
.tuto-slide .tuto-slide-body {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.tuto-slide .tuto-slide-body .step1,
.tuto-slide .tuto-slide-body .step2 {
  width: 50%;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu {
  position: relative;
  background-color: transparent;
  display: inline-block;
  z-index: 1;
  box-sizing: border-box;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .link-tabs {
  position: relative;
  background-color: #070f18;
  padding: 40px;
  margin: 0;
  z-index: 3;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items {
  position: relative;
  display: none;
  animation: slideIn 0.5s forwards ease-in-out;
  background-color: #070f18;
  padding: 0 40px 40px;
  z-index: 2;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items.opened {
  display: block;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item {
  font-weight: 600;
  font-size: 13px;
  line-height: 1em;
  border: none;
  letter-spacing: 0;
  text-transform: initial;
  background-color: transparent;
  display: inline-block;
  width: 100%;
  text-align: left;
  font-size: 12px;
  line-height: 2em;
  color: white;
  cursor: pointer;
  text-decoration: none;
}
select.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item {
  appearance: none;
}
a.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item {
  text-decoration: none;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item + .btn {
  margin-left: 15px;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.btn-block {
  display: block;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.btn-block + .btn {
  margin-left: 0;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item:focus {
  outline: 0;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.btn-1 {
  color: white;
  background: linear-gradient(to right, #074377, #37a8db);
  border-radius: 30px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
  padding: 12px 30px;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.btn-1:hover:not([disabled]):not(.disabled) {
  background: linear-gradient(to left, #074377, #37a8db);
  color: #121e2c;
  animation: push 0.3s linear;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.btn-1.btn-wider {
  padding-left: 50px;
  padding-right: 50px;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.btn-outlined {
  background: none;
  border-radius: 30px;
  padding: 10px 28px;
  color: #074377;
  border: 1px solid #074377;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.btn-outlined.light {
  color: white;
  border: 1px solid white;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.btn-outlined.black {
  color: black;
  border-color: black;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.btn-outlined:not([disabled]):not(.disabled):hover {
  color: #37a8db;
  border-color: #37a8db;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.btn-outlined:not([disabled]):not(.disabled):hover:not(select) {
  animation: push 0.3s linear;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.btn-outlined:not([disabled]):not(.disabled).active,
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.btn-outlined:not([disabled]):not(.disabled).active:hover {
  background: #37a8db;
  color: #121e2c;
  border-color: #37a8db;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.btn-outlined.light-blue {
  color: #37a8db;
  border-color: #37a8db;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.btn-outlined.light-blue:hover:not([disabled]):not(.disabled) {
  color: #074377;
  border-color: #074377;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.btn-outlined.light-blue.active,
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.btn-outlined.light-blue.active:hover:not([disabled]):not(.disabled) {
  background: #074377;
  color: #121e2c;
  border-color: #074377;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.btn-outlined.btn-big {
  border-width: 2px;
}
select.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.btn-outlined {
  background-image: url('../img/pictos/chevron-down-light-grey.svg');
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
  background-size: 1.2em;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.btn-big {
  font-size: 16px;
  padding: 18px 50px;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item .btn-icon:after {
  content: '';
  display: inline-block;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  border-radius: 50%;
  font-size: 25px;
  background-color: #121e2c;
  color: white;
  font-weight: 300;
  margin-left: 10px;
  position: relative;
  top: 3px;
  transition: transform 0.5s;
  transform: rotate(0deg);
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.btn-icon-plus:after {
  content: '';
  display: inline-block;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  border-radius: 50%;
  font-size: 25px;
  background-color: #121e2c;
  color: white;
  font-weight: 300;
  margin-left: 10px;
  position: relative;
  top: 3px;
  transition: transform 0.5s;
  transform: rotate(0deg);
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.btn-icon-plus:after {
  content: '+';
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.btn-icon-plus:hover:not([disabled]):not(.disabled):after {
  transform: rotate(90deg);
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.btn-icon-minus:after {
  content: '';
  display: inline-block;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  border-radius: 50%;
  font-size: 25px;
  background-color: #121e2c;
  color: white;
  font-weight: 300;
  margin-left: 10px;
  position: relative;
  top: 3px;
  transition: transform 0.5s;
  transform: rotate(0deg);
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.btn-icon-minus:after {
  content: '-';
  line-height: 22px;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.btn-icon-minus:hover:not([disabled]):not(.disabled):after {
  transform: rotate(180deg);
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.btn-icon-close {
  transform: rotate(45deg);
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.btn-icon-close:after {
  content: '';
  display: inline-block;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  border-radius: 50%;
  font-size: 25px;
  background-color: #121e2c;
  color: white;
  font-weight: 300;
  margin-left: 10px;
  position: relative;
  top: 3px;
  transition: transform 0.5s;
  transform: rotate(0deg);
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.btn-icon-close:after {
  content: '+';
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.btn-icon-close:hover:not([disabled]):not(.disabled):after {
  transform: rotate(90deg);
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.link-btn {
  color: #6a7581;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.link-btn:hover:not([disabled]):not(.disabled) {
  text-decoration: underline;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.link-btn.small-link-btn {
  font-size: 12px;
  font-weight: 400;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.link-btn.underlined {
  text-decoration: underline;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.link-btn.underlined:hover {
  color: #37a8db;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.link-btn.light {
  color: white;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.link-btn.light.btn-icon-plus:after {
  background-color: white;
  color: #121e2c;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.link-btn.blue {
  color: #37a8db;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.link-btn.dark-blue {
  color: #074377;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.link-btn.black {
  color: black;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.link-btn.thin {
  font-weight: 400;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.btn-toggle {
  color: #37a8db;
  font-weight: 400;
  font-size: 16px;
  font-size: 14px;
  text-transform: uppercase;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.btn-toggle:after {
  content: '';
  display: inline-block;
  position: relative;
  top: 0.2em;
  width: 1em;
  height: 1em;
  margin-left: 0.3em;
  color: #d2d3d5;
  background: url('../img/pictos/picto-chevron-down-light-blue.svg') no-repeat center center;
  background-size: contain;
  transition: transform 0.2s;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.btn-toggle.toggled:after {
  transform: rotate(180deg);
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item[disabled],
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item[disabled]:hover,
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.disabled:hover {
  animation: none;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.btn-uppercase {
  text-transform: uppercase;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.thumbnail-hidden {
  color: white;
  background: linear-gradient(to right, #c7994a, #cd5c5c);
  border-radius: 30px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
  padding: 12px 30px;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.thumbnail-hidden:hover:not([disabled]):not(.disabled) {
  background: linear-gradient(to left, #c7994a, #cd5c5c);
  color: #121e2c;
  animation: push 0.3s linear;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.thumbnail-hidden.btn-wider {
  padding-left: 50px;
  padding-right: 50px;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.btn-borderless {
  border: none;
  background: none;
  padding: 0;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.btn-borderless.white {
  color: white;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.btn-borderless.light-blue {
  color: #37a8db;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.btn-borderless.black {
  color: black;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item:hover,
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.active {
  color: #37a8db;
}
.tuto-slide .tuto-slide-body .step1 .tutorial-popin-menu .collection-items .item.disabled {
  color: #6a7581;
  cursor: not-allowed;
}
.tuto-slide .tuto-slide-body .step2 {
  border-radius: 10px;
  padding: 0 20px;
}
@media (max-width: 960px) {
  .tuto-slide .tuto-slide-body .step1,
  .tuto-slide .tuto-slide-body .step2 {
    width: 90%;
  }
}
.rounded-progress-bar {
  --pgPercentage: var(--value);
  margin-bottom: 3px;
  animation: growProgressBar 3s 1 forwards;
  border-radius: 50%;
  display: grid;
  place-items: center;
  background: radial-gradient(closest-side, white 80%, transparent 0 99.9%, white 0), conic-gradient(#37a8db calc(var(--pgPercentage) * 1%), #e0e4ee 0);
  color: #37a8db;
}
.rounded-progress-bar.small {
  width: 2.5rem;
  height: 2.5rem;
  font-size: 10px;
}
.rounded-progress-bar.medium {
  width: 4rem;
  height: 4rem;
  font-size: 14px;
}
.rounded-progress-bar.large {
  width: 5rem;
  height: 5rem;
  font-size: 20px;
}
.rounded-progress-bar .rounded-progress-bar__value::after {
  counter-reset: percentage var(--value);
  content: counter(percentage) '%';
  font-weight: 600;
  color: #37a8db;
}
@keyframes slideIn {
  0% {
    transform: translateY(-80%) scaleY(0%);
  }
  100% {
    transform: translateY(0) scaleY(100%);
  }
}
@keyframes growProgressBar {
  0%,
  33% {
    --pgPercentage: 0;
  }
  100% {
    --pgPercentage: var(--percentage);
  }
}
@property --pgPercentage {
  syntax: '<number>';
  inherits: false;
  initial-value: 0;
}
.picto {
  display: inline-block !important;
  position: relative;
  height: 1.3em;
  margin: 0 0.4em;
  top: 0.2em;
}
.picto.small {
  height: 0.8em;
  top: 2px;
}
.picto.blue {
  fill: #37a8db;
}
.flex-center .picto {
  top: 0;
}
.banner-img {
  height: 400px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  text-align: center;
}
.banner-img.cover {
  background-size: cover;
}
.banner-img-2 {
  text-align: center;
}
.banner-img-2 .img-container {
  position: relative;
  display: inline-block;
}
.banner-img-2 .img-container .picto {
  position: absolute;
  display: inline-block;
  width: 20px;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.banner-img-2 .main-img {
  max-height: 500px;
  max-width: 100%;
}
.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(18, 30, 44, 0.9);
  z-index: 1000;
  animation: fadein 0.2s linear;
  cursor: pointer;
  padding: 10vh 10vw;
  text-align: center;
}
.lightbox .main-img {
  display: inline-block;
  max-height: 80vh;
  max-width: 80vw;
}
.lightbox .close {
  position: absolute;
  top: 40px;
  right: 40px;
  font-size: 20px;
}
.social-logo-bar {
  display: inline-block;
}
.social-logo-bar .social-logo {
  margin-left: 10px;
  width: 30px;
  margin-bottom: -10px;
}
.article {
  color: black;
}
.article .published {
  font-size: 13px;
  color: #074377;
  margin-bottom: 2em;
}
.article h1 {
  font-size: 20px;
  font-weight: 600;
  color: #074377;
}
.article p {
  font-weight: 400;
  font-size: 16px;
}
.article .article-footer {
  border-top: 1px solid #d2d3d5;
  margin-top: 30px;
  padding-top: 1em;
  text-align: center;
}
.article .article-footer > span {
  padding: 1em 3em 0;
}
.feed-item {
  position: relative;
  display: flex;
  justify-content: space-between;
  color: black;
  margin: 20px 0;
  overflow: hidden;
  /* TODO: mutualize (cf. tribute-writing-bloc for example) ? */
}
.feed-item.let-overflow {
  overflow: visible;
}
.feed-item > * {
  width: calc(100% - 80px);
}
.feed-item .action-icons {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  text-align: right;
}
.feed-item .action-icons a,
.feed-item .action-icons button {
  padding: 0;
  display: inline-block;
}
.feed-item .action-icons a:not(:first-child),
.feed-item .action-icons button:not(:first-child) {
  margin-top: 10px;
}
.feed-item .action-icons a img,
.feed-item .action-icons button img {
  width: 20px;
}
.feed-item .certification {
  padding-left: 7px;
  width: 1rem;
  height: 1rem;
}
.feed-item.write-comment {
  margin-bottom: 20px;
  overflow: visible;
}
.feed-item.write-comment .textarea {
  width: 100%;
  font-size: 16px;
  margin-top: 3px;
}
.feed-item.write-comment .comment-form {
  position: relative;
}
.feed-item.write-comment .comment-form .custom-upload {
  /*position: absolute;
                top: 14px;
                right: 14px;
                width: 25px;*/
  margin-top: 4px;
  height: 25px;
}
.feed-item .profile-pic {
  width: 60px;
  height: 60px;
}
.feed-item .username {
  margin-left: 8px;
  color: #6a7581;
}
.feed-item .item-title {
  font-weight: 600;
  font-size: 18px;
  border-bottom: 1px solid black;
  border-color: inherit;
  padding-bottom: 0.8em;
  border: none;
  margin-bottom: 0;
}
.feed-item p {
  margin: 4px 0;
  white-space: pre-wrap;
}
.feed-item p + .item-title {
  margin-top: 0;
}
.feed-item a:hover {
  color: #074377;
}
.feed-item .feed-footer {
  margin-top: 10px;
  border-top: 1px solid black;
  padding-top: 10px;
}
.feed-item .feed-footer > *:not(:last-child) {
  display: inline-block;
  margin-right: 70px;
}
.feed-item .feed-footer .tile {
  /* an embedded tile in feed-footer = comments -> reduce padding to get more room for content*/
  margin-top: 10px;
  box-sizing: border-box;
}
.feed-item .feed-footer .tile .tile-body {
  padding: 0 15px;
}
.feed-item.small {
  margin: 0;
  justify-content: flex-start;
}
.feed-item.small .profile-pic {
  width: 35px;
  height: 35px;
  margin-right: 10px;
}
.feed-item.small p {
  margin-bottom: 0;
}
.feed-item.small p + p {
  margin-top: 0;
}
.feed-item.card-display {
  display: block;
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  font-size: 14px;
}
.feed-item.card-display > * {
  width: 100%;
}
.feed-item.card-display .item-head {
  display: flex;
  align-items: center;
}
.feed-item.card-display .item-head .profile-pic {
  margin-right: 10px;
}
.feed-item.card-display .item-title {
  font-size: 16px;
}
.feed-item.card-display p {
  font-weight: 400;
  font-size: 16px;
  font-size: 14px;
}
.feed-item.card-display .feed-footer {
  display: flex;
  justify-content: space-between;
  border-color: #d2d3d5;
}
.feed-item.card-display .feed-footer > * {
  margin: 0;
}
.feed-list .feed-item {
  position: relative;
  background: white;
  padding: 20px;
  margin: 0 0 1px;
}
.feed-list > a:hover {
  text-decoration: none;
}
.bio {
  font-weight: 400;
  font-size: 16px;
  font-size: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.bio.smaller,
.bio .smaller {
  font-weight: 100;
}
.pop-up-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 15px 15px;
  background: rgba(0, 0, 0, 0.6);
  animation: opacity 0.15s;
}
@media (max-width: 640px) {
  .pop-up-background {
    padding-left: 0;
    padding-right: 0;
  }
}
.pop-up {
  color: black;
  width: 700px;
  background-color: white;
  border-radius: 2px;
  padding: 30px;
  position: relative;
  box-sizing: border-box;
  margin: 50px auto;
  max-width: calc(100% - 40px) !important;
  animation: opacity-down 0.3s;
  color: #121e2c;
  z-index: 100;
}
.pop-up.sep + .pop-up.sep {
  margin-top: 1px;
}
.pop-up .tile-head {
  padding: 20px 30px;
  background-color: #074377;
  color: white;
}
.pop-up .tile-head a {
  color: white;
  text-decoration: none;
  font-size: 13px;
  line-height: 2em;
}
.pop-up .tile-head a:hover {
  color: #37a8db;
}
.pop-up .tile-body {
  position: relative;
  padding: 20px 30px;
}
.pop-up .tile-body.compact {
  padding: 15px;
}
.pop-up.tile-notif .picto-notif {
  flex: 0;
  width: 30px;
  height: 30px;
  margin: 15px 20px 0 10px;
}
.pop-up.tile-notif .tile-body {
  display: flex;
  padding: 10px;
}
.pop-up.tile-notif .notif-body {
  flex: 1;
}
.pop-up.tile-notif .notif-date {
  font-weight: 400;
  font-size: 16px;
  font-size: 14px;
  margin: 5px 0 7px 15px;
}
.pop-up.tile-notif .notif-desc {
  margin-left: 15px;
  font-weight: 400;
  font-size: 16px;
}
.pop-up.tile-notif .notif-content {
  color: black;
  font-weight: 400;
  font-size: 16px;
  font-size: 14px;
  margin-top: 2px;
  white-space: pre-wrap;
}
.pop-up.tile-notif .notif-content:before {
  content: '«';
  display: inline-block;
  margin-right: 5px;
}
.pop-up.tile-notif .notif-content:after {
  content: '»';
  display: inline-block;
  margin-left: 5px;
}
.pop-up.tile-notif a {
  /* special ptn emphasize style. keep default <em> for RTE edition style */
  color: #37a8db;
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
}
.pop-up.tile-notif a:hover {
  text-decoration: underline;
}
.pop-up.tile-notif a:hover .notif-content {
  color: #37a8db;
}
.pop-up.tile-notif .profile-pic {
  width: 60px;
  height: 60px;
}
.pop-up.tile-notif.unread {
  background: #e0e4ee;
}
.pop-up.dark-blue {
  background-color: #074377;
}
.pop-up.dark-blue:not(.switchable) .tile-head {
  background-color: white;
  color: #074377;
}
.pop-up.dark-blue:not(.switchable) .tile-head a {
  color: #074377;
}
.pop-up.dark-blue .tile-title {
  color: white;
}
.pop-up.dark-blue .tile-title:after {
  border-color: white;
}
.pop-up.dark-blue.edit-mode .tile-head .tile-title:after {
  display: none;
}
.pop-up.dark-blue.edit-mode .tile-head + .tile-body {
  padding-top: 30px;
  padding-bottom: 30px;
}
.pop-up.dark-blue.edit-mode .tile-body {
  background: white;
  color: #121e2c;
}
.pop-up.light-blue {
  background-color: #37a8db;
}
.pop-up.light-blue:not(.switchable) .tile-head {
  background-color: white;
  color: #37a8db;
}
.pop-up.light-blue:not(.switchable) .tile-head a {
  color: #37a8db;
}
.pop-up.light-blue .tile-title {
  color: white;
}
.pop-up.light-blue .tile-title:after {
  border-color: white;
}
.pop-up:not(.shared-feed) .tile {
  border: 1px solid #d2d3d5;
}
.pop-up .info {
  margin: 20px 0;
}
.pop-up .feed-notice {
  padding-left: 80px;
  padding-bottom: 0;
  color: #848484;
}
.pop-up .feed-notice .picto {
  margin-left: -28px;
}
.pop-up .feed-notice + .tile .tile-body {
  padding-top: 0;
}
.pop-up .feed-notice + .info {
  margin-left: 80px;
}
.pop-up.shared-feed .tile .tile-body {
  padding-left: 0;
  padding-right: 0;
}
.pop-up.content-detail-tile {
  height: 370px;
}
.pop-up.parent .tile {
  border-radius: 10px;
}
.pop-up.parent .tile .options-menu {
  z-index: 1;
}
.pop-up.condensed .tile-body {
  padding: 12px;
}
.pop-up.condensed .tile-body .feed-notice {
  margin-bottom: 10px;
  padding-left: 60px;
}
.pop-up.condensed .tile-body .feed-notice + .tile.condensed .tile-body {
  padding-left: 0;
  padding-right: 0;
}
.pop-up.condensed .tile-body .feed-notice + .tile.condensed .tile-body .feed-item .profile-pic {
  width: 50px;
  height: 50px;
}
.pop-up.condensed .tile-body .feed-notice + .tile.condensed .tile-body .feed-item > * {
  width: calc(100% - 60px);
}
.pop-up.condensed .tile-body .feed-notice + .tile.condensed .tile-body .feed-footer .tile-body {
  padding-left: 10px;
  padding-right: 10px;
}
.pop-up.condensed .tile-body .feed-item {
  margin-top: 0;
  margin-bottom: 0;
}
.pop-up.condensed .tile-body .feed-item .profile-pic {
  width: 50px;
  height: 50px;
}
.pop-up.condensed .tile-body .feed-item > * {
  width: calc(100% - 60px);
}
.pop-up.condensed .tile-body .feed-footer .tile-body {
  padding: 10px;
}
.pop-up.condensed .tile-body .feed-footer .tile-body .feed-item {
  overflow: visible;
}
.pop-up.condensed .tile-body .feed-footer .tile-body .feed-item .profile-pic {
  margin-top: 5px;
}
.pop-up.condensed .tile-body .feed-footer .tile-body .feed-item .options-menu {
  top: 0;
  right: -5px;
}
.pop-up.condensed .tile-body .feed-footer .tile-body .feed-item .options-menu.open {
  right: 0;
}
.pop-up.condensed .tile-body .feed-footer .tile-body .feed-item .feed-item {
  /* nested feed-item (comments history in inspiration feed for example */
  margin: 10px 0;
}
.pop-up.condensed .tile-body .tile-body {
  /* nested feeds */
  padding: 10px;
}
.pop-up.condensed .tile-body .tile-body .feed-item .profile-pic {
  width: 40px;
  height: 40px;
}
.pop-up.condensed .tile-body .tile-body .feed-item > * {
  width: calc(100% - 47px);
}
.pop-up.condensed .options-menu.open {
  right: 15px;
}
.pop-up.tile-padding-xl {
  padding: 10px 60px;
}
.pop-up.tile-padding-xl .tile-body > div + * {
  margin-top: 20px;
}
.pop-up .pop-up-title {
  font-weight: 600;
  font-size: 30px;
  letter-spacing: 1px;
  font-size: 22px;
  margin-top: 20px;
  margin-bottom: 30px;
  color: #121e2c;
}
.pop-up .pop-up-title.centered {
  text-align: center;
}
.pop-up .pop-up-buttons {
  margin-top: 20px;
  text-align: right;
}
.pop-up .pop-up-buttons .btn {
  width: 30%;
}
.pop-up .pop-up-buttons.centered {
  text-align: center;
}
.pop-up .btn-close {
  position: absolute;
  top: 12px;
  right: 10px;
}
.pop-up.page {
  z-index: unset;
}
/* Specific */
.ui-kit .dark-bg {
  margin-top: 20px;
  background-color: #121e2c;
  padding: 30px 20px;
  color: white;
}
.ui-kit .night-bg {
  margin-top: 20px;
  background-color: #121e2c;
  padding: 30px 20px;
  color: white;
  background-color: #070f18;
}
.ui-kit .ui-kit-logo {
  width: 400px;
}
.ui-kit pre {
  font-weight: 600;
  margin: 5px 0 0;
  font-size: 14px;
}
.ui-kit pre + pre {
  margin-top: 0;
  font-weight: normal;
}
.ui-kit .component {
  display: inline-block;
  text-align: center;
  margin-right: 10px;
}
.ui-kit .color-sample {
  width: 100px;
  height: 100px;
}
.ui-kit .color-sample.dark {
  background-color: #121e2c;
}
.ui-kit .color-sample.grey {
  background-color: #6a7581;
}
.ui-kit .color-sample.medium-grey {
  background-color: #848484;
}
.ui-kit .color-sample.light-blue {
  background-color: #37a8db;
}
.ui-kit .color-sample.dark-blue {
  background-color: #074377;
}
.ui-kit .color-sample.night-blue {
  background-color: #070f18;
}
.ui-kit .button {
  display: inline-block;
  text-align: center;
}
.shadowed-header {
  position: relative;
  z-index: 3;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.5);
}
.header {
  background-color: #121e2c;
  padding: 10px 0;
}
.header .navbar {
  display: flex;
  position: relative;
}
.header .navbar-left,
.header .navbar-right,
.header .navbar-center {
  display: flex;
  align-items: center;
}
.header .navbar-center {
  position: relative;
  margin: 0 auto;
  padding: 0 5px;
}
.header .navbar-right {
  margin-left: auto;
}
.header .navbar-item {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.header img.logo {
  height: 34px;
}
.header .picto-nav {
  display: inline-block;
  position: relative;
  width: 80px;
  text-align: center;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.header .picto-nav img {
  height: 24px;
}
.header .picto-nav.picto-nav-bigger-1 img {
  height: 25px;
  margin-top: -1px;
}
.header .picto-nav.picto-nav-bigger-2 img {
  height: 27px;
  margin-top: -3px;
}
.header .picto-nav.picto-nav-smaller img {
  height: 21px;
  margin-top: 3px;
}
.header .picto-nav.btn {
  padding: 0;
}
.header .picto-nav.btn:after {
  top: 12px;
}
.header .picto-nav .counter-chip {
  position: absolute;
  top: -6px;
  right: 12px;
}
.header .picto-nav:after {
  content: '';
  display: block;
  width: 100%;
  height: 4px;
  background: none;
  position: relative;
  top: 11px;
}
.header .picto-nav:hover:not(.disabled):after {
  background: #37a8db;
}
.header .picto-nav.active:after {
  background: #37a8db;
}
.header .profile-nav-item {
  position: relative;
  padding: 0;
  margin: 0;
}
.header .profile-nav-item.active:after {
  content: '';
  display: block;
  width: 80%;
  height: 4px;
  background: white;
  position: absolute;
  bottom: -10px;
  right: 0;
}
.header .profile-pic {
  position: relative;
  height: 34px;
  width: 34px;
  margin-left: 10px;
}
.header .global-search {
  position: absolute;
  top: -7px;
  left: 0;
  width: 100%;
  animation: fadein 0.3s;
  border-bottom: 7px solid #121e2c;
  border-top: 7px solid #121e2c;
}
.header .global-search input {
  width: 100%;
  padding-right: 40px;
}
.header .global-search .search-loader,
.header .global-search .btn-close {
  width: 22px;
  height: 22px;
  position: absolute;
  top: calc( 50% - 22px/2 );
  right: 14px;
}
.header .global-search .btn-close {
  right: -22px;
  margin: 0;
}
.header .global-search .btn-close .picto {
  transform: rotate(0);
  transition: transform 0.2s linear;
}
.header .global-search .btn-close:hover .picto {
  transform: rotate(90deg);
}
@media (max-width: 960px) {
  .header .navbar {
    justify-content: center;
  }
  .header .navbar-center {
    margin: inherit;
  }
  .header .navbar-right {
    margin-left: inherit;
  }
  .header .navbar-right .menu {
    width: 30px;
  }
}
.footer {
  background: #070f18;
  text-align: center;
  color: white;
  padding: 40px 0 0 0;
  clear: both;
}
.footer .logo {
  width: 200px;
}
.footer .liveinspired {
  margin-top: 10px;
  margin-bottom: 20px;
}
.footer nav ul {
  list-style-type: none;
  font-size: 0;
  padding: 30px 20px;
}
.footer nav ul li {
  display: inline-block;
  font-weight: 300;
  font-size: 12px;
  padding: 0 10px;
  margin: 0;
}
.footer nav ul li + li {
  border-left: 1px solid white;
}
.footer nav ul li a {
  color: white;
  text-decoration: none;
}
.footer nav ul li a:hover {
  text-decoration: underline;
}
.footer .copyright {
  background: black;
  padding: 40px 20px;
  font-size: 12px;
  font-weight: 300;
  color: #6a7581;
  margin: 0;
}
.section.create-your-paanteon {
  background-color: #121e2c;
  background-image: url('../img/create-your-paanteon-bg.png');
  background-position: center 50px;
  background-repeat: no-repeat;
  background-size: contain;
  height: 1660px;
}
.section.create-your-paanteon .logo {
  width: 300px;
  max-width: 80%;
}
.section.create-your-paanteon .welcome {
  color: white;
  font-size: 20px;
  font-weight: 100;
}
.section.create-your-paanteon .discover {
  position: relative;
  top: -85px;
  padding-left: calc(50% + 50px);
  color: white;
  font-weight: 100;
  font-size: 22px;
  line-height: 2em;
}
.section.create-your-paanteon .discover ul {
  position: relative;
  padding-left: 0;
  letter-spacing: 0.07em;
}
.section.create-your-paanteon .discover ul:before {
  position: absolute;
  display: inline-block;
  content: '';
  width: 1px;
  top: 20px;
  left: -17px;
  bottom: 20px;
  background: white;
}
.most-engraved {
  padding-bottom: 150px;
}
.section.inspiration-feed {
  position: relative;
}
.section.inspiration-feed svg {
  fill: white;
  position: absolute;
  top: -100px;
  width: 100%;
  height: 100px;
}
.section.inspiration-feed .mockup-mobile {
  position: absolute;
  left: 0;
  top: -100px;
  width: 280px;
}
.section.inspiration-feed .section-title {
  padding-left: 150px;
}
.section.inspiration-feed .inspiration-flow {
  display: inline-block;
  margin-top: 20px;
  width: 430px;
}
.section.inspiration-feed .inspiration-feed {
  display: inline-block;
  width: 92%;
  margin-top: -140px;
}
.public-home-layout {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100vw;
  min-height: 100vh;
  display: flex;
}
.public-home-layout .container {
  padding: 0 5rem;
  align-self: center;
  max-width: 390px;
}
.public-home-layout .input {
  height: 50px;
  border-width: 1px;
}
.public-home-layout .btn-big {
  padding: 14px 0;
  width: 200px;
}
.public-home-layout .btn-big.btn-outlined {
  padding: 12px 0;
  width: 196px;
}
.public-home-layout .card-square {
  position: relative;
  background-color: white;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.5);
}
.public-home-layout .card-square .card-header {
  background-color: #121e2c;
  text-align: center;
  padding: 1.5rem 0;
}
.public-home-layout .card-square .card-header .welcome {
  color: white;
  font-size: 23px;
  font-weight: 300;
}
.public-home-layout .card-square .card-header .logo {
  width: 15rem;
}
.public-home-layout .card-square .card-header .ptn-em {
  font-size: 22px;
}
.public-home-layout .card-square .card-body {
  padding: 2rem 2.5rem 4rem 2.5rem;
}
.public-home-layout .card-square .card-body .label-strong {
  font-weight: 800;
  font-size: 20px;
}
.public-home-layout .card-square .card-body .lost-password-link {
  color: #37a8db;
}
.public-home-layout .card-square .card-body .lost-password-link:hover {
  color: #a3d7ee;
}
.public-home-layout .card-square .card-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #121e2c;
  text-align: center;
}
.public-home-layout .card-square .card-footer .links-list {
  list-style-type: none;
  padding: 0;
  margin: 0.5rem 0;
}
.public-home-layout .card-square .card-footer .links-list li {
  display: inline-block;
  font-size: 12px;
  padding: 0 5px;
  margin: 0;
  color: #e0e4ee;
}
.public-home-layout .card-square .card-footer .links-list li a {
  text-decoration: none;
}
.public-home-layout .card-square .card-footer .links-list li a:hover {
  text-decoration: underline;
}
.public-home-layout .img-copyright {
  font-size: 1rem;
}
.public-home-layout .disclaimer {
  font-size: 12px;
}
.public-home-layout .disclaimer a {
  text-decoration: underline;
}
@media (max-width: 639px) {
  .public-home-layout {
    background-image: none !important;
    background-color: white;
  }
  .public-home-layout .container {
    padding: 0;
    height: 100vh;
    max-width: 100%;
  }
  .public-home-layout .card-square {
    min-height: 100vh;
  }
  .public-home-layout .img-copyright {
    display: none;
  }
}
.notification-container {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999;
  width: 320px;
  padding: 0px 15px;
  max-height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
}
.notification {
  box-sizing: border-box;
  padding: 15px;
  border-radius: 5px;
  color: #fff;
  background-color: #ccc;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  position: relative;
  opacity: 0.9;
  margin-top: 15px;
}
.notification .title {
  font-weight: 400;
  font-size: 16px;
}
.notification:hover,
.notification:focus {
  opacity: 1;
}
.notification-enter {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
}
.notification-enter.notification-enter-active {
  visibility: visible;
  transform: translate3d(0, 0, 0);
  transition: all 0.4s;
}
.notification-leave {
  visibility: visible;
  transform: translate3d(0, 0, 0);
}
.notification-leave.notification-leave-active {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
  transition: all 0.4s;
}
.notification:before {
  position: absolute;
  top: 50%;
  left: 15px;
  margin-top: -14px;
  display: block;
  font-family: 'Notification';
  width: 28px;
  height: 28px;
  font-size: 28px;
  text-align: center;
  line-height: 28px;
}
.notification-info {
  background-color: #074377;
}
.notification-info:before {
  content: "";
}
.notification-success {
  background-color: #37a8db;
}
.notification-warning {
  background-color: #f89406;
}
.notification-error {
  background-color: #cd5c5c;
}
.react-autosuggest__container {
  position: relative;
}
.react-autosuggest__input--focused {
  outline: none;
}
.react-autosuggest__input::-ms-clear {
  display: none;
}
.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.react-autosuggest__suggestions-container {
  display: none;
  color: #074377;
}
.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 50px;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}
.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}
.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}
.react-autosuggest__section-container {
  border-top: 1px dashed #ccc;
}
.react-autosuggest__section-container--first {
  border-top: 0;
}
.react-autosuggest__section-title {
  padding: 10px 0 0 10px;
  font-size: 12px;
  color: #777;
}
.msg-layout {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.msg-layout .msg-content {
  display: flex;
  flex: 1 1 auto;
  align-items: stretch;
}
.msg-layout .msg-content .msg-list {
  width: 33%;
}
.msg-layout .msg-content .msg-list .btn {
  padding: 15px 20px;
  text-align: left;
  line-height: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.2s linear;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0;
  background-color: white;
  color: #121e2c;
}
.msg-layout .msg-content .msg-list .btn:not(:last-child) {
  margin-bottom: 1px;
}
.msg-layout .msg-content .msg-list .btn:hover {
  color: #37a8db;
}
.msg-layout .msg-content .msg-list .btn .profile-pic {
  position: relative;
  top: 4px;
  width: 27px;
  height: 27px;
  margin: 0 10px 0 0;
}
.msg-layout .msg-content .msg-list .btn.new-msg-btn {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #37a8db;
  text-transform: uppercase;
  color: white;
  height: 80px;
  box-sizing: border-box;
}
.msg-layout .msg-content .msg-list .btn.new-msg-btn:hover {
  background-color: #074377;
  color: white;
}
.msg-layout .msg-content .msg-list .btn.active {
  color: #37a8db;
}
.msg-layout .msg-content .msg-list .btn.unread {
  background-color: #d7eef8;
}
.msg-layout .msg-content .msg-conversation {
  flex: 1;
  background: transparent;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  margin-left: 1px;
}
.msg-layout .msg-content .conversation-head {
  background: white;
  height: 80px;
  display: flex;
  align-items: center;
  margin-bottom: 1px;
  padding: 10px 20px;
  box-sizing: border-box;
}
.msg-layout .msg-content .conversation-head .profile-pic {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}
.msg-layout .msg-content .conversation-head .username {
  font-weight: 400;
  font-size: 16px;
  font-weight: 600;
  color: #37a8db;
}
.msg-layout .msg-content .conversation-head .latest-msg {
  font-weight: 400;
  font-size: 16px;
  font-size: 12px;
  color: #121e2c;
}
.msg-layout .msg-content .conversation-head .latest-msg.smaller,
.msg-layout .msg-content .conversation-head .latest-msg .smaller {
  font-weight: 100;
}
.msg-layout .msg-content .conversation-feed {
  background: white;
  color: #121e2c;
  padding: 10px 20px;
  margin-bottom: 1px;
  max-height: 600px;
  overflow-y: auto;
}
.msg-layout .msg-content .conversation-feed .msg {
  display: flex;
  padding: 20px 0 0;
  padding-right: 40px;
}
.msg-layout .msg-content .conversation-feed .msg .profile-pic {
  flex-shrink: 0;
  width: 27px;
  height: 27px;
  margin-right: 10px;
}
.msg-layout .msg-content .conversation-feed .msg .msg-container {
  flex: 1;
  padding-bottom: 20px;
}
.msg-layout .msg-content .conversation-feed .msg .msg-content {
  font-size: 16px;
  font-weight: 400;
  color: #121e2c;
  flex-grow: 0;
  display: block;
}
.msg-layout .msg-content .conversation-feed .msg .msg-image:not(.lightbox) {
  margin-top: 20px;
  height: 200px;
  cursor: pointer;
}
.msg-layout .msg-content .conversation-feed .msg .msg-image:not(.lightbox) .close {
  display: none;
}
.msg-layout .msg-content .conversation-feed .msg .msg-image img {
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
}
.msg-layout .msg-content .conversation-feed .msg .msg-image .close {
  color: white;
}
.msg-layout .msg-content .conversation-feed .msg .msg-date {
  font-size: 16px;
  font-size: 12px;
  font-weight: 400;
  color: #074377;
  margin-top: 10px;
  display: block;
}
.msg-layout .msg-content .conversation-feed .msg .msg-date.smaller,
.msg-layout .msg-content .conversation-feed .msg .msg-date .smaller {
  font-weight: 100;
}
.msg-layout .msg-content .conversation-feed .msg:not(:last-child) .msg-container {
  border-bottom: 1px solid #d2d3d5;
}
.msg-layout .msg-content .conversation-feed .msg.from-me {
  flex-direction: row-reverse;
  padding-right: 0;
  padding-left: 40px;
}
.msg-layout .msg-content .conversation-feed .msg.from-me .profile-pic {
  margin-right: 0;
  margin-left: 10px;
}
.msg-layout .msg-content .conversation-feed .msg.from-me .msg-content,
.msg-layout .msg-content .conversation-feed .msg.from-me .msg-date,
.msg-layout .msg-content .conversation-feed .msg.from-me .msg-image {
  text-align: right;
}
.msg-layout .msg-content .conversation-feed:empty {
  display: none;
}
.msg-layout .msg-content .conversation-input {
  background: white;
  padding: 20px;
}
.msg-layout .msg-content .conversation-input textarea {
  width: 100%;
}
.msg-layout .msg-content .conversation-input .custom-upload {
  margin-top: 4px;
}
.msg-layout .msg-content .conversation-input .uploaded-img {
  text-align: center;
}
.msg-layout .msg-content .conversation-input .uploaded-img img {
  max-width: 100%;
}
.msg-layout .msg-content .conversation-input .btn {
  margin-top: 10px;
}
.msg-layout .msg-content .new-msg-user-search {
  flex: 1;
  height: 80px;
  background: white;
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.msg-layout .msg-content .new-msg-user-search input {
  border: 1px solid #d2d3d5;
  flex-grow: 1;
}
.msg-layout .msg-content .new-msg-user-search .react-autosuggest__suggestions-container--open {
  box-sizing: border-box;
  border-color: #d2d3d5;
}
.msg-layout .msg-content .new-msg-user-search .react-autosuggest__suggestions-list li {
  font-weight: 400;
  font-size: 16px;
  font-size: 12px;
  color: #121e2c;
}
.msg-layout .msg-content .new-msg-user-search .react-autosuggest__suggestions-list li.smaller,
.msg-layout .msg-content .new-msg-user-search .react-autosuggest__suggestions-list li .smaller {
  font-weight: 100;
}
.msg-layout .msg-content .new-msg-user-search .react-autosuggest__suggestions-list li:hover {
  background: #d7eef8;
  color: #37a8db;
}
.search-results {
  position: absolute;
  top: 54px;
  left: 0;
  width: 100%;
  background: white;
  z-index: 6;
  padding: 0 0 40px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.5);
}
.search-results .results-section {
  padding: 40px 0;
}
.search-results .results-section:not(:first-child) {
  border-top: 1px solid #d2d3d5;
}
.search-results .results-section .category-title {
  margin: 0 0 40px;
}
.search-results .results-section .section-footer {
  margin-top: 40px;
  text-align: right;
}
.search-results .user-result {
  text-align: center;
  color: inherit;
  text-decoration: none;
}
.search-results .user-result .profile-pic {
  width: 100%;
  height: auto;
}
.search-results .user-result .user-name {
  overflow: hidden;
}
.search-results .user-result:hover {
  color: #37a8db;
}
.search-results .no-result {
  font-weight: 600;
  font-size: 18px;
  border-bottom: 1px solid black;
  border-color: inherit;
  padding-bottom: 0.8em;
  border: none;
  padding-bottom: 0;
  color: #37a8db;
  margin-top: 40px;
}
.parallax {
  position: relative;
  width: 100%;
  height: 1080px;
  overflow: hidden;
  top: 0;
  left: 0;
  background: #121e2c;
}
.parallax .layer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
  /*enable 3D rendering*/
  transform: translateZ(0);
  will-change: transform;
}
html,
body {
  margin: 0;
  padding: 0;
}
