// Name:            Visibility
// Description:     Utilities to show or hide content on breakpoints, hover or touch
//
// Component:       `uk-hidden-*`
//                  `uk-visible-*`
//                  `uk-invisible`
//                  `uk-visible-toggle`
//                  `uk-hidden-hover`
//                  `uk-invisible-hover`
//                  `uk-hidden-touch`
//                  `uk-hidden-notouch`
//
// ========================================================================


/* ========================================================================
   Component: Visibility
 ========================================================================== */

/*
 * Hidden
 * `hidden` attribute also set here to make it stronger
 */

[hidden],
.uk-hidden { display: none !important; }

/* Phone landscape and bigger */
@media (min-width: @breakpoint-small) {

    .uk-hidden\@s { display: none !important; }

}

/* Tablet landscape and bigger */
@media (min-width: @breakpoint-medium) {

    .uk-hidden\@m { display: none !important; }

}

/* Desktop and bigger */
@media (min-width: @breakpoint-large) {

    .uk-hidden\@l { display: none !important; }

}

/* Large screen and bigger */
@media (min-width: @breakpoint-xlarge) {

    .uk-hidden\@xl { display: none !important; }

}

/*
 * Visible
 */

/* Phone portrait and smaller */
@media (max-width: @breakpoint-xsmall-max) {

    .uk-visible\@s { display: none !important; }

}

/* Phone landscape and smaller */
@media (max-width: @breakpoint-small-max) {

    .uk-visible\@m { display: none !important; }

}

/* Tablet landscape and smaller */
@media (max-width: @breakpoint-medium-max) {

    .uk-visible\@l { display: none !important; }

}

/* Desktop and smaller */
@media (max-width: @breakpoint-large-max) {

    .uk-visible\@xl { display: none !important; }

}


/* Visibility
 ========================================================================== */

.uk-invisible { visibility: hidden !important; }


/* Based on the State of the Parent Element
 ========================================================================== */

/*
 * Can't use `display: none` nor `visibility: hidden` because both are not focusable.
 * The target stays visible if any element within receives focus through keyboard.
 */

/*
 * Discard space when hidden.
 */

.uk-visible-toggle:not(:hover):not(:focus) .uk-hidden-hover:not(:focus-within) {
    position: absolute !important;
    width: 0 !important;
    height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: hidden !important;
}

/*
 * Keep space when hidden.
 */

.uk-visible-toggle:not(:hover):not(:focus) .uk-invisible-hover:not(:focus-within) { opacity: 0 !important; }


/* Based on Hover Capability of the Pointing Device
 ========================================================================== */

/*
 * Hover
 */

/* Hide if primary pointing device doesn't support hover, e.g. touch screens. */
@media (hover: none) {
    .uk-hidden-touch { display: none !important; }
}

/* Hide if primary pointing device supports hover, e.g. mice. */
@media (hover) {
    .uk-hidden-notouch { display: none !important; }
}


// Hooks
// ========================================================================

.hook-visibility-misc();

.hook-visibility-misc() {}
