.parallax {
    position: relative;
    width: 100%;
    height: 1080px;
    overflow: hidden;
    top: 0;
    left: 0;
    background: #121e2c;

    .layer {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-repeat: no-repeat;
        background-position: top center;
        background-size: contain;

        /*enable 3D rendering*/
        transform: translateZ(0);
        will-change: transform;
        //transition: transform 300ms linear;
    }
}
