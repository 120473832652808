@header-inner-height: 34px;
@header-padding: 10px;

.shadowed-header {
    position: relative;
    z-index: 3;
    box-shadow: 0 5px 15px 0 rgba(0,0,0,0.5);
}

.header {
    background-color: @dark;
    padding: @header-padding 0;

    .navbar {
        display: flex;
        position: relative;
    }

    .navbar-left, .navbar-right, .navbar-center {
        display: flex;
        align-items: center;
    }

    .navbar-center {
        position: relative;
        margin: 0 auto;
        padding: 0 5px;
    }

    .navbar-right {
        margin-left: auto;
    }

    .navbar-item {
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
    }

    img.logo {
        height: @header-inner-height;
    }

    .picto-nav {
        display: inline-block;
        position: relative;
        width: 80px;
        text-align: center;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
        img {
            height: @header-inner-height - 10px;
        }
        &.picto-nav-bigger-1 {
            img {
                height: @header-inner-height - 9px;
                margin-top: -1px;
            }
        }
        &.picto-nav-bigger-2 {
            img {
                height: @header-inner-height - 7px;
                margin-top: -3px;
            }
        }
        &.picto-nav-smaller {
            img {
                height: @header-inner-height - 13px;
                margin-top: 3px;
            }
        }
        &.btn {
            padding: 0;
            &:after {
                top: 12px;
            }
        }
        .counter-chip {
            position: absolute;
            top: -6px;
            right: 12px;
        }
        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 4px;
            background: none;
            position: relative;
            top: @header-padding + 1px;
        }
        &:hover:not(.disabled) {
            &:after {
                background: @light-blue;
            }
        }
        &.active {
            &:after {
                background: @light-blue;
            }
        }
    }

    .profile-nav-item {
        position: relative;
        padding: 0;
        margin: 0;
        &.active:after {
            content: '';
            display: block;
            width: 80%;
            height: 4px;
            background: white;
            position: absolute;
            bottom: -@header-padding;
            right: 0;
        }
    }

    .profile-pic {
        position: relative;
        height: @header-inner-height;
        width: @header-inner-height;
        margin-left: 10px;
    }

    .global-search {
        position: absolute;
        top: -7px;
        left: 0;
        width: 100%;
        animation: fadein 0.3s;
        border-bottom: 7px solid @dark;
        border-top: 7px solid @dark;

        input {
            width: 100%;
            padding-right: 40px;
        }

        .search-loader, .btn-close {
            @size: 22px;
            width: @size;
            height: @size;
            position: absolute;
            top: ~"calc( 50% - @{size}/2 )";
            right: 14px;
        }
        .btn-close {
            right: -22px;
            margin: 0;

            .picto {
                transform: rotate(0);
                transition: transform 0.2s linear;
            }

            &:hover .picto {
                transform: rotate(90deg);
            }
        }
    }
}

@media (max-width: @breakpoint-medium) {
    .header {
        .navbar {
            justify-content: center;
        }

        .navbar-center {
            margin: inherit;
        }

        .navbar-right {
            margin-left: inherit;

            .menu {
                width: 30px;
            }
        }
    }
}
