.main-font {
	font-family: 'Work Sans', Helvetica, Arial, sans-serif;
}

.thin {
	font-weight: 100;
}

.light {
	font-weight: 300;
}

.regular {
	font-weight: 400;
}

.semibold {
	font-weight: 600;
}

.bold {
	font-weight: 800;
}

.title-1 {
	text-align: center;
	.regular();
	font-size: 50px;
	letter-spacing: 2px;

	&.smaller, .smaller {
		.thin();
		font-size: 0.7em;
	}
}

.title-1-light {
	.title-1();
	.light();
}

.title-2 {
	.semibold();
	font-size: 30px;
	letter-spacing: 1px;
}

.title-3 {
	.bold();
	font-size: 22px;
	letter-spacing: 2px;
}

.title-4 {
	.semibold();
	font-size: 18px;
	border-bottom: 1px solid black;
	border-color: inherit;
	padding-bottom: 0.8em;
}

.title-5 {
	.title-4();
	border: none;
	padding-bottom: 0;
}

.title-6 {
	.title-5();
	.regular;
	line-height: 2em;
}

.title-7 {
	.bold();
	font-size: 20px;
}

.pre-title {
	display: block;
	font-size: 0.5em;
	.light();
}

.body {
	.regular();
	font-size: 16px;
}

.body-m {
	.body();
	font-size: 14px;
}

.body-s {
	.body();
	font-size: 12px;
	&.smaller, .smaller {
		.thin();
	}
}

.body-l{
	.body();
	font-size: 18px
}

.headline {
	.regular();
	font-size: 22px;
	line-height: 1.5em;
	margin: 1em;
}

blockquote, q, .quote {
	position: relative;
	.body();
	font-style: italic;
	padding-left: 50px;
	padding-top: 30px;
	margin-inline-start: 0;
	quotes: "“" "”";

	&:before {
		position: relative;
		margin-left: -50px;
		font-size: 80px;
		line-height: 0;
		color: @light-blue;
		content: open-quote;
		display: block;
	}
}

.spaced-body {
	p {
		margin-bottom: 1em;
	}
}


.article-title {
	.title-2();
	margin-bottom: 0.7em;
}

.article-inner-title {
	font-size: 20px;
	.semibold();
	color: @dark-blue;
}

.article-body {
	.body();
}

.section-title {
	position: relative;
	display: inline-block;
	margin: 40px 0 70px !important;
	width: auto;
	.bold();
	text-transform: uppercase;
	font-size: 32px;
	line-height: 1.6em;
	letter-spacing: 0.2em;

	&:before {
		content: '';
		position: absolute;
		right: 0;
		bottom: -30px;
		display: inline-block;
		width: 5000px;
		height: 1px;
		border-bottom: 1px solid @light-grey;
	}

	.text-finish();
	&:after {
		bottom: 15px;
	}
}

.page-title {
	text-align: center;
	.title-1();
	padding-top: 0.5em;
	padding-bottom: 0.2em;

	.thin, .light {
		font-size: 0.8em;
	}
}

.page-title + .page-subtitle {
	.title-3();
	.thin();
	.h-center();
	margin-top: -2em;
	margin-bottom: 2em;
}

.tile-title {
	text-align: center;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 15px;
	letter-spacing: 0.1em;
	color: white;
	&:after:not(.no-after) {
		content: '';
		display: block;
		width: 150px;
		border-bottom: 1px solid white;
		margin: 20px auto;
	}
}

.section-subtitle {
	position: relative;
	font-size: 16px;
	.light();
	top: -80px;
}

.gallery-title {
	padding: 0;
	margin: 0;
	text-transform: uppercase;
	font-size: 30px;
	.semibold();
	text-align: center;
	letter-spacing: 0.2em;
	flex: 1;

	.gallery-subtitle {
		font-size: 16px;
		.light();
		text-transform: none;
		display: block;
		text-align: center;
		letter-spacing: 0;
	}
}

.collection-title {
	.light();
	text-transform: uppercase;
	font-size: 20px;
	color: @light-blue;
	margin: 40px 0 2px;
}

.collection-subtitle {
	.light();
	font-size: 12px;
	margin-bottom: 24px;
	padding-bottom: 12px;
	border-bottom: 1px solid white;
}

.category-title {
	.body-m();
	text-transform: uppercase;
}

.text-finish {
	position: relative;
	&:after {
		content: '';
		width: 32px;
		height: 10px;
		background-color: @light-blue;
		position: absolute;
		z-index: 1;
		bottom: 3px;
		right: -45px;
		transform: skew(-20deg);
	}
}

.liveinspired {
	font-size: 26px;
	span:first-child {
		.thin();
	}
	span:nth-child(2) {
		.regular();
	}

	&.dark {
		color: @dark-blue;

		&.boxed {
			border: solid 1px @light-blue;
		}
	}
}

*, html, body {
	.main-font();
}

p {
	.body();
	margin: 0;
	padding: 0;
}

a {
	color: inherit;
	cursor: pointer;

	&, &>strong {
		transition: none;
	}

	&:hover {
		color: @light-blue;
	}
}

.ptn-em { /* special ptn emphasize style. keep default <em> for RTE edition style */
	color: @light-blue;
	.semibold();
	font-style: normal;
}

strong, b {
	.semibold();
}

.link {
	.body();
	color: @light-blue;
	text-decoration: none;
	background: none;
	border: none;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}

	&.light {
		.light();
	}
}

.grey-label {
	color: @grey;
	font-size: 13px;
}

.help-section {
	p {
		text-align: justify;
	}
}

.text-red {
	color: @red;
}

.text-green {
	color: @green;
}

.text-light-blue {
	color: @light-blue;
}

.text-center {
	text-align: center;
}

.text-dark {
	color: @dark;
}

.text-right {
	text-align: right;
}

.text-uppercase {
	text-transform: uppercase;
}

ol {
	::marker {
		color: @light-blue;
		font-weight: bold;
	}
}

.text-underline {
	text-decoration: underline;
}
