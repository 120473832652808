@card-radius: 25px;

.card {
    display: inline-block;
    position: relative;
    top: 0;
    width: 250px;
    height: 350px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: @card-radius;
    box-shadow: 0 3px 5px -2px rgba(0,0,0,0.7);
    transition: box-shadow 0.3s, top 0.3s;

    .chip {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    &:hover {
        top: -5px;
        box-shadow: 0 5px 8px -1px rgba(0,0,0,0.5);
    }

    .card-footer {
        position: absolute;
        bottom: -2px;
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        background-color: @dark;
        padding: 14px 20px;
        border-bottom-left-radius: @card-radius;
        border-bottom-right-radius: @card-radius;
        font-size: 16px;

        .card-name {
            color: white;
            .regular();
            line-height: 1.4em;
            margin: 0;
            .cap-first-letter();
            hyphens: auto;
            box-sizing: border-box;
            width: 100%;
            word-break: break-word;
        }
        .card-title {
            color: @light-blue;
            .light();
            text-transform: uppercase;
            font-size: 12px;
            line-height: 1.4em;
            margin: 0;

            &.big {
                font-size: 16px;
            }
        }
    }

    &.small {
        .card-footer {
            font-size: 13px;
            padding: 8px 14px 10px;
            .card-name {
                line-height: 1.2em;
            }
        }
    }

    &.blue {
        .card-footer {
            background-color: @dark-blue;
        }
    }

    &.empty {
        box-shadow: none;
        background-color: darken(@dark, 3%);
    }

    &.no-hover-anim {
        &:hover {
            top: 0;
        }
    }

    canvas {
        display: none; // use only with .expand modifier
    }
    &.expand {
        width: 100%;
        height: 100%;
        canvas {
            display: block;
            width: 100%;
        }
    }

    &:focus {
        outline: none;
    }
}

.plus-card {
    display: inline-block;
    border: none;
    border-radius: @card-radius;
    width: 100px;
    height: 150px;
    background-color: @dark;
    cursor: pointer;

    &:after {
        content: '+';
        display: inline-block;
        line-height: 150px;
        font-size: 60px;
        color: @light-grey;
        font-weight: 100;
        transform: rotate(0deg);
        transition: transform 0.2s;
    }

    &.small {
        width: 70px;
        height: 100px;
        background: @light-grey;
        border-radius: @card-radius * 2 / 3;

        &:after {
            line-height: 100px;
            color: white;
        }
    }

    &:hover {
        &:after {
            transform: rotate(90deg);
        }
    }

    &:focus {
        outline: none;
    }
}

.framed-pic-card {
    position: relative;
    display: inline-block;
    width: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    outline: 1px solid white;
    outline-offset: -15px;
    margin-bottom: 80px;
    box-shadow: 0 3px 5px -2px rgba(0,0,0,0.7);
    transition: outline-offset 0.2s;

    .card-ratio {
        width: 100%;
    }

    .card-footer {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        margin-top: 7px;
        color: @dark;
        text-align: center;
        font-size: 14px;
        .card-title, .card-subtitle {
            margin: 2px 0;
        }
        .card-title() {
            .semibold();
        }
        .card-subtitle {
            .thin();
        }
    }

    &:hover {
        outline-color: @light-blue;
        outline-offset: -25px;
    }
}

.section-dark, .sidebar-block.night-blue {
    .framed-pic-card .card-footer {
        color: white;
    }
}

.tile {
    position: relative;
    background-color: white;
    color: black;

    &.sep + &.sep {
        margin-top: 1px;
    }
    .tile-head {
        padding: 20px 30px;
        background-color: @dark-blue;
        color: white;

        a {
            color: white;
            text-decoration: none;
            font-size: 13px;
            line-height: 2em;

            &:hover {
                color: @light-blue;
            }
        }
    }

    .tile-body {
        position: relative;
        padding: 20px 30px;

        &.compact {
            padding: 15px;
        }
    }

    &.tile-notif {
        .picto-notif {
            flex: 0;
            width: 30px;
            height: 30px;
            margin: 15px 20px 0 10px;
        }
        .tile-body {
            display: flex;
            padding: 10px;
        }
        .notif-body {
            flex: 1;
        }
        .notif-date {
            .body-m();
            margin: 5px 0 7px 15px;
        }
        .notif-desc {
            margin-left: 15px;
            .body();
        }
        .notif-content {
            color: black;
            .body-m();
            margin-top: 2px;
            white-space: pre-wrap;

            &:before {
                content: '«';
                display: inline-block;
                margin-right: 5px;
            }
            &:after {
                content: '»';
                display: inline-block;
                margin-left: 5px;
            }
        }

        a {
            .ptn-em();
            text-decoration: none;

            &:hover {
                text-decoration: underline;

                .notif-content {
                    color: @light-blue;
                }
            }

        }
        .profile-pic {
            width: 60px;
            height: 60px;
        }

        &.unread {
            background: @lighter-grey;
        }
    }

    .colored(@color) {
        background-color: @color;

        &:not(.switchable) .tile-head {
            background-color: white;
            color: @color;
            a {
                color: @color;
            }
        }
        .tile-title {
            color: white;
            &:after {
                border-color: white;
            }
        }
    }

    &.dark-blue {
        .colored(@dark-blue);

        &.edit-mode {
            .tile-head {
                .tile-title:after {
                    display: none;
                }

                & + .tile-body {
                    padding-top: 30px;
                    padding-bottom: 30px;
                }
            }
            .tile-body {
                background: white;
                color: @dark;
            }

        }
    }

    &.light-blue {
        .colored(@light-blue);
    }

    &:not(.shared-feed) .tile {
        border: 1px solid @light-grey;
    }

    .info {
        margin: 20px 0;
    }

    .feed-notice {
        @offset : 80px;
        padding-left: @offset;
        padding-bottom: 0;
        color: @medium-grey;

        .picto {
            margin-left: -28px;
        }

        & + .tile .tile-body {
            padding-top: 0;
        }

        & + .info {
            margin-left: @offset;
        }
    }

    &.shared-feed .tile .tile-body {
        padding-left: 0;
        padding-right: 0;
    }

    &.content-detail-tile {
        height: 370px;
    }

    &.parent .tile {
        border-radius: 10px;

        .options-menu {
            z-index: 1;
        }
    }

    &.condensed {
        .tile-body {
            padding: 12px;

            .feed-notice {
                margin-bottom: 10px;
                padding-left: 60px;

                & + .tile.condensed .tile-body {
                    padding-left: 0;
                    padding-right: 0;

                    .feed-item {
                        .profile-pic {
                            width: 50px;
                            height: 50px;
                        }
                        & > * {
                            width: calc(~"100% - 60px");
                        }
                    }

                    .feed-footer .tile-body {
                        padding-left: 10px;
                        padding-right: 10px;
                    }


                }
            }

            .feed-item {
                margin-top: 0;
                margin-bottom: 0;

                .profile-pic {
                    width: 50px;
                    height: 50px;
                }

                & > * {
                    width: calc(~"100% - 60px");
                }
            }

            .feed-footer {
                .tile-body {
                    padding: 10px;
                    .feed-item {

                        overflow: visible;

                        .profile-pic {
                            margin-top: 5px;
                        }

                        .options-menu {
                            top: 0;
                            right: -5px;

                            &.open {
                                right: 0;
                            }
                        }

                        .feed-item { /* nested feed-item (comments history in inspiration feed for example */
                            margin: 10px 0;
                        }
                    }
                }
            }

            .tile-body { /* nested feeds */
                padding: 10px;
                .feed-item {
                    .profile-pic {
                        width: 40px;
                        height: 40px;
                    }
                    & > * {
                        width: calc(~"100% - 47px");
                    }
                }
            }
        }

        .options-menu.open {
            right: 15px;
        }
    }

    &.tile-padding-xl {
        padding: @small @xl;

        .tile-body > div + * {
            margin-top: 20px;
        }
    }
}

.help-section {
    .tile:not(:last-child) {
        border-bottom: 1px solid @dark;
    }
}

.my-ptn-tile {
    color: @dark;
    text-align: center;

    .tile-title {
        .title-1();
        font-size: 32px;
        padding: 40px 0 5px;
        line-height: 1em;
        color: @dark;

        &:after {
            display: none;
        }
    }
    .tile-subtitle {
        .body();
        .regular();
        .h-center();
        margin-bottom: 20px;
    }
    .tile-body {
        padding-left: 100px;
        padding-right: 100px;
    }
    .thumbs-grid {
        display: flex;
        justify-content: center;
        margin-bottom: 48px;

        .card {
            max-width: 25%;

            & + .card {
                margin-left: 20px;
            }
        }
    }
    .tile-footer {
        .h-center();
        padding: 20px 0 10px;
    }
}

.user-tile {
    .h-center();

    .profile-pic {
        display: inline-block;
        width: 75px;
        height: 75px;
    }

    .user-name {
        .body-m();
        .semibold();
        color: black;
        margin: 10px 0;
    }
}

.parameters-tile {
    .tile-title {
        color: black;
        text-align: left;
        border-bottom: 1px solid @medium-grey;
        padding-bottom: @normal;
        margin: @large 0 0 0;
    }
    .parameter-list-item {
        padding-top: @normal;
        padding-bottom: @normal;

        &:not(:last-child) {
            border-bottom: 1px solid @light-grey;
        }
    }
    .parameter-name {
        color: black;
        text-align: left;
        padding: @small 0;
        margin: 0;
        border: none;

        .picto {
            margin-left: 0;
        }
    }
    .uk-grid > [class^="uk-width"] {
        padding-top: @xs;
        padding-bottom: @xs;
    }
}

.content-card {

    @card-padding: 20px;
    @card-footer-height: 58px;

    background: white;
    padding: @card-padding;
    color: black;
    text-align: left;

    position: relative;

    .picto.action {
        position: absolute;
        top: 10px;
        right: 5px;
    }

    .thumbnail {
        display: block;
        height: 130px;
        text-align: center;
        margin-bottom: @card-padding;

        & > * {
            display: inline-block;
            max-height: 100%;
            box-shadow: 2px 2px 5px 0 rgba(0,0,0,0.1);
        }

        &.no-fixed-height {
            height: auto;

            img {
                max-height: 130px;
                max-width: 100%;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    .card-title {
        .title-4();
        font-size: 16px;
        padding: 0;
        border: none;

        &:after {
            content: "";
            display: block;
            width: 80px;
            height: 0;
            margin: 0.5em 0 @card-padding / 2;
            border-top: 1px solid black;
        }
    }

    .category {
        .body();
        text-transform: uppercase;
        color: @light-blue;
        margin: 0;
    }

    a {
        color: @light-blue;
    }

    .author {
        .body-m();
        margin: 0;
    }

    .text {
        margin-top: @card-padding / 2;
        white-space: pre-wrap;
    }

    .card-footer {
        box-sizing: border-box;
        @icon-size: @card-footer-height * 0.4;
        position: relative;
        margin-top: @card-padding;

        & > * {
            .body-m();
        }

        .action {
            display: flex;
            align-items: center;
            cursor: pointer;
            position: absolute;
            margin: 0;
            bottom: 0;
            top: auto;
            right: 0;

            .picto {
                position: relative;
                height: @icon-size;
                top: -1px;
                margin: 0;
            }

            *:not(:first-child) {
                margin-left: 3px;
            }
        }

        .btn-icon-plus {
            position: absolute;
            padding: 0;
            bottom: 0;
            left: 50%;
            margin-bottom: -10px;
            margin-left: -12px;

            &:after {
                margin-left: 0;
            }
        }

    }

    &.fixed-size {
        padding-bottom: @card-footer-height + @card-padding;
        .card-body {
            height: 350px;
            overflow: hidden;
        }
        .card-footer {
            height: @card-footer-height;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding: @card-padding/2 @card-padding;

            &:before {
                content: '';
                display: block;
                position: absolute;
                height: 80px;
                width: 100%;
                left: 0;
                top: -80px;
                background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #fff 100%);;
            }

            .action {
                bottom: @card-padding / 2;
                right: @card-padding;
            }
        }
    }

    &.list-display {
        display: flex;
        margin-bottom: 1px;

        .thumbnail {
            width: 25%;
            max-width: 250px;
            min-width: 150px;
            height: auto;
            margin: 0 20px 0 0;
            display: flex;
            justify-content: center;

            & > * {
                max-width: 100%;
                max-height: 130px;
            }
        }

        .card-body {
            width: 100%;
        }

        .card-title {
            margin-top: 0;
        }

        .card-footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    &.block-display {
        display: flex;
        height: 370px;
        position: relative;
        overflow-y: auto;
        .custom-scroll();

        .picto.action {
            right: 70px;
        }

        .options-menu {
            right: 40px;
        }

        .btn-icon-close {
            position: absolute;
            top: 0;
            right: 5px;
        }

        .card-head {
            width: 25%;
            max-width: 250px;
            min-width: 150px;
            display: flex;
            flex-direction: column;
            padding: 20px 0;

            .thumbnail {
                margin: 0 auto 10px;
                height: auto;
                max-height: 130px;
                max-width: 100%;
            }

            .author, .btn {
                margin-bottom: 20px;
            }
        }
        .card-body {
            padding: 20px;
        }
        .content {
            padding-bottom: 20px;
            // keeping that just in case... clients are sometimes versatile///
            // height: 100%;
            // overflow-x: hidden;
            // overflow-y: auto;
            // .custom-scroll();
        }
    }
}

.payment-card {
    background-color: fade(@light-blue, 5%);
    padding: 1rem;

    & > * {
        border: 2px solid fade(desaturate(@light-blue, 50%), 15%);
        border-top: 0;
        padding: 1rem;
    }

    & > *:last-child {
        border-radius: 0 0 5px 5px;
    }

    .card-header {
        .title-5;
        border: 2px solid fade(desaturate(@light-blue, 50%), 15%);
        border-radius: 5px 5px 0 0;
        padding: 1rem;
    }

    .StripeElement {
        background-color: #fff;
        border: 1px solid fade(desaturate(@light-blue, 50%), 25%);
        padding: 1rem ;
    }
}

.tribe-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: @normal;
    padding: 0 @medium;
    justify-content: space-between;

    .tribe-card-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #fff;
        background: linear-gradient(to right, @dark-blue, @light-blue);
        border-radius: @card-radius;
        width: 100%;
        padding: @medium;
        
        &.active {
            border-bottom: solid 3px @night-blue;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        .tribe-name {
            font-size: 24px;
            font-weight: 600;
            margin: 0 @normal;
        }

        .tribe-nb-members {
            font-size: 24px;
        }
        
        .member-picto {
            width: 24px;
            height: 24px;
            margin: 0 @xs;
        }
    }
    

    .tribe-card-body {
        width: 100%;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
        background-color: white;
        padding: 0 30px 30px;

        .tribe-card-member-list{ 
            margin: 0 20px;
            max-height: 400px;
            .tile-body {
                padding: @normal @small;
                width: 7em;
            }
            .user-name {
                text-decoration: none;
                &:hover {
                    p {
                        color: @light-blue;
                    }
                }
            }
            .affinity {
                display: flex;
                align-items: center;
                justify-content: center;
                .icon {
                    width: 1em;
                    height: 1em;
                    margin: 0 @xs;
                }
            }
            &.scroll {
                overflow-y: scroll;
            }
        }
        
    }
}
