.picto {
    display: inline-block !important;
    position: relative;
    height: 1.3em;
    margin: 0 0.4em;
    top: 0.2em;

    &.small {
        height: 0.8em;
        top: 2px;
    }

    &.blue {
        fill: @light-blue;
    }
}

.flex-center .picto {
    top: 0;
}

.banner-img {
    height: 400px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    text-align: center;

    &.cover {
        background-size: cover;
    }
}

.banner-img-2 {
    text-align: center;

    .img-container {
        position: relative;
        display: inline-block;
        .picto {
            position: absolute;
            display: inline-block;
            width: 20px;
            top: 20px;
            right: 20px;
            cursor: pointer;
        }
    }

    .main-img {
        max-height: 500px;
        max-width: 100%;
    }
}

.lightbox {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: fade(@dark, 90%);
    z-index: 1000;
    animation: fadein 0.2s linear;

    cursor: pointer;

    padding: 10vh 10vw;
    text-align: center;

    .main-img {
        display: inline-block;
        max-height: 80vh;
        max-width: 80vw;
    }

    .close {
        position: absolute;
        top: 40px;
        right: 40px;
        font-size: 20px;
    }
}

.social-logo-bar {
    display: inline-block;

    .social-logo {
        .margin-left-s();

        width: 30px;
        margin-bottom: -10px;
    }
}
