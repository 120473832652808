.section.create-your-paanteon {
    background-color: @dark;
    background-image: url('../img/create-your-paanteon-bg.png');
    background-position: center 50px;
    background-repeat: no-repeat;
    background-size: contain;
    height: 1660px;

    .logo {
        width: 300px;
        max-width: 80%;
    }

    .welcome {
        color: white;
        font-size: 20px;
        .thin();
    }

    .discover {
        position: relative;
        top: -85px;
        padding-left: calc(~"50% + 50px");
        color: white;
        .thin();
        font-size: 22px;
        line-height: 2em;
        ul {
            position: relative;
            padding-left: 0;
            letter-spacing: 0.07em;
            &:before {
                position: absolute;
                display: inline-block;
                content: '';
                width: 1px;
                top: 20px;
                left: -17px;
                bottom: 20px;
                background: white;
            }
        }
    }
}

.most-engraved {
    padding-bottom: 150px;
}

.section.inspiration-feed {

    position: relative;

    svg {
        fill: white;
        position: absolute;
        top: -100px;
        width: 100%;
        height: 100px;
    }

    .mockup-mobile {
        position: absolute;
        left: 0;
        top: -100px;
        width: 280px;
    }

    .section-title {
        padding-left: 150px;
    }

    .inspiration-flow {
        display: inline-block;
        margin-top: 20px;
        width: 430px;
    }

    .inspiration-feed {
        display: inline-block;
        width: 92%;
        margin-top: -140px;
    }
}

.public-home-layout {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100vw;
    min-height: 100vh;
    display: flex;
    //.bottom-overlay();

    .container {
        padding: 0 5rem;
        align-self: center;
        max-width: 390px;
    }

    .input {
        height: 50px;
        border-width: 1px;
    }

    .btn-big {
        padding: 14px 0;
        width: 200px;

        &.btn-outlined {
            padding: 12px 0;
            width: 196px;
        }
    }

    .card-square {
        position: relative;
        background-color: white;
        box-shadow: 0 10px 20px 0 rgba(0,0,0,0.5);

        .card-header {
            background-color: @dark;
            text-align: center;
            padding: 1.5rem 0;

            .welcome {
                color: white;
                font-size: 23px;
                .light();
            }

            .logo {
                width: 15rem;
            }

            .ptn-em {
                font-size: 22px;
            }
        }

        .card-body {
            padding: 2rem 2.5rem 4rem 2.5rem;

            .label-strong {
                .title-7();
            }

            .lost-password-link {
                color: @light-blue;

                &:hover {
                    color: lighten(@light-blue, 25%);
                }
            }
        }

        .card-footer {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: @dark;
            text-align: center;

            .links-list {
                list-style-type: none;
                padding: 0;
                margin: 0.5rem 0;

                li {
                    display: inline-block;
                    font-size: 12px;
                    padding: 0 5px;
                    margin: 0;
                    color: @lighter-grey;

                    a {
                        text-decoration: none;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

    .img-copyright {
        font-size: 1rem;
    }

    .disclaimer {
        font-size: 12px;
        a {
            text-decoration: underline;
        }
    }
}

@media (max-width: @breakpoint-xsmall-max) {
    .public-home-layout {
        background-image: none !important;
        background-color: white;

        .container {
            padding: 0;
            height: 100vh;
            max-width: 100%;
        }

        .card-square {
            min-height: 100vh;
        }

        .img-copyright {
            display: none;
        }
    }
}
