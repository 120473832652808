@import "sidebar.less";

.main-content {
    padding-top: 20px;
    padding-bottom: 40px;
}

.main-content-s {
    .main-content;
    max-width: 700px;
}

.page-head {
    position: relative;
    background-position: center center;
    background-size: cover;
    height: 300px;
    background-color: @light-grey;

    &:not(.simple-head) {
        .bottom-overlay();
    }

    h1 {
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: @sidebar-width;
        width: ~"calc( 100% - @{sidebar-width} )";
        text-align: center;
        color: white;
        font-size: 40px;
        letter-spacing: 0.1em;
        .light();
        text-transform: uppercase;
        margin-bottom: 15px;
        text-shadow: 1px 1px rgba(0,0,0,0.5);

        .text-finish:after {
            bottom: 10px;
        }

        .ptn-of {
            .thin();
            text-transform: initial;
            font-size: 0.6em;
        }
    }
    /* profile cover image upload component */
    input[type=file] {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;

        & + label {
            position: absolute;
            top: 15px;
            left: 15px;
            height: 15px;
            width: 20px;
            height: 20px;
            cursor: pointer;
            img {
                width: 100%;
            }
        }
    }

    &.simple-head {
        background-color: @dark;
        height: 200px;
        h1 {
            .thin();
            font-size: 40px;
            bottom: 20%;
            left: 0;
            width: 100%;
        }
    }
    .caption {
        bottom: 30px;
        left: 30px;
    }
}
.section {
    padding: 60px 0;
    background-color: white;
    color: @night-blue;

    &.section-condensed {
        padding-top: 30px;
    }

    &.section-dark {
        background-color: @dark;
        color: white;

        &.with-gradient {
            background-image: radial-gradient(fade(@light-blue,25%), rgba(255,255,255,0) 70%);
        }

        .section-title:before {
            border-color: white;
        }
    }

    &.section-light-blue {
        background-color: @light-blue;
        color: white;
    }


    &.small-section {
        padding: 20px 0;
    }

    &+.section:not(.force-padding) {
        padding-top: 0;
    }

    &.--no-select {
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
    }
}
