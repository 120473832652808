.ui-kit {
    .dark-bg {
        margin-top: 20px;
        background-color: @dark;
        padding: 30px 20px;
        color: white;
    }
    .night-bg {
        .dark-bg();
        background-color: @night-blue;
    }
    .ui-kit-logo {
        width: 400px;
    }

    pre {
        .semibold();
        margin: 5px 0 0;
        font-size: 14px;
        & + pre {
            margin-top: 0;
            font-weight: normal;
        }
    }

    .component {
        display: inline-block;
        text-align: center;
        margin-right: 10px;
    }

    .color-sample {
        width: 100px;
        height: 100px;

        &.dark {
            background-color: @dark;
        }
        &.grey {
            background-color: @grey;
        }
        &.medium-grey {
            background-color: @medium-grey;
        }
        &.light-blue {
            background-color: @light-blue;
        }
        &.dark-blue {
            background-color: @dark-blue;
        }
        &.night-blue {
            background-color: @night-blue;
        }
    }

    .button {
        display: inline-block;
        text-align: center;
    }
}