.footer {
    background: @night-blue;
    text-align: center;
    color: white;
    padding: 40px 0 0 0;
    clear: both;
    .logo {
        width: 200px;
    }
    .liveinspired {
        margin-top: 10px;
        margin-bottom: 20px;
    }
    nav ul {
        list-style-type: none;
        font-size: 0;
        padding: 30px 20px;
        li {
            display: inline-block;
            .light();
            font-size: 12px;
            padding: 0 10px;
            margin: 0;
            &+li {
                border-left: 1px solid white;
            }
            a {
                color: white;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .copyright {
        background: black;
        padding: 40px 20px;
        font-size: 12px;
        .light();
        color: @grey;
        margin: 0;
    }
}