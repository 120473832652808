@head-height: 80px;

.msg-layout {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .msg-content {
        display: flex;
        flex: 1 1 auto;
        align-items: stretch;

        .msg-list {
            width: 33%;

            .btn {
                padding: 15px 20px;
                text-align: left;
                line-height: 30px;
                .truncate();
                transition: all 0.2s linear;
                display: flex;
                align-items: center;
                width: 100%;
                margin: 0;
                background-color: white;
                color: @dark;

                &:not(:last-child) {
                    margin-bottom: 1px;
                }

                &:hover {
                    color: @light-blue;
                }

                .profile-pic {
                    position: relative;
                    top: 4px;
                    width: 27px;
                    height: 27px;
                    margin: 0 10px 0 0;
                }

                &.new-msg-btn {
                    padding-top: 20px;
                    padding-bottom: 20px;
                    background-color: @light-blue;
                    text-transform: uppercase;
                    color: white;
                    height: @head-height;
                    box-sizing: border-box;

                    &:hover {
                        background-color: @dark-blue;
                        color: white;
                    }
                }

                &.active {
                    color: @light-blue;
                }

                &.unread {
                    background-color: lighten(@light-blue, 37%);
                }
            }
        }

        .msg-conversation {
            flex: 1;
            background: transparent;
            align-self: stretch;
            display: flex;
            flex-direction: column;
            margin-left: 1px;
        }

        .conversation-head {
            background: white;
            height: @head-height;
            display: flex;
            align-items: center;
            margin-bottom: 1px;
            padding: 10px 20px;
            box-sizing: border-box;

            .profile-pic {
                width: 50px;
                height: 50px;
                margin-right: 10px;
            }

            .username {
                .body();
                .semibold();
                color: @light-blue;
            }

            .latest-msg {
                .body-s();
                color: @dark;
            }
        }

        .conversation-feed {
            background: white;
            color: @dark;
            padding: 10px 20px;
            margin-bottom: 1px;
            max-height: 600px;
            overflow-y: auto;

            .msg {
                display: flex;
                padding: 20px 0 0;
                padding-right: 40px;

                .profile-pic {
                    flex-shrink: 0;
                    width: 27px;
                    height: 27px;
                    margin-right: 10px;
                }

                .msg-container {
                    flex: 1;
                    padding-bottom: 20px;
                }

                .msg-content {
                    .body();
                    .regular();
                    color: @dark;
                    flex-grow: 0;
                    display: block;
                }

                .msg-image {

                    &:not(.lightbox) {
                        margin-top: 20px;
                        height: 200px;
                        cursor: pointer;

                        .close {
                            display: none;
                        }
                    }

                    img {
                        max-width: 100%;
                        max-height: 100%;
                        display: inline-block;
                    }

                    .close {
                        color: white;       
                    }
                }

                .msg-date {
                    .body-s();
                    .regular();
                    color: @dark-blue;
                    margin-top: 10px;
                    display: block;
                }

                &:not(:last-child) {
                    .msg-container {
                        border-bottom: 1px solid @light-grey;
                    }
                }

                &.from-me {
                    flex-direction: row-reverse;
                    padding-right: 0;
                    padding-left: 40px;

                    .profile-pic {
                        margin-right: 0;
                        margin-left: 10px;
                    }

                    .msg-content, .msg-date, .msg-image {

                        text-align: right;
                    }
                }
            }

            &:empty {
                display: none;
            }
        }

        .conversation-input {
            background: white;
            padding: 20px;

            textarea {
                width: 100%;
            }

            .custom-upload {
                margin-top: 4px;
            }

            .uploaded-img {
                text-align: center;

                img {
                    max-width: 100%;
                }
            }

            .btn {
                margin-top: 10px;
            }
        }

        .new-msg-user-search {
            flex: 1;
            height: @head-height;
            background: white;
            box-sizing: border-box;
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            input {
                border: 1px solid @light-grey;
                flex-grow: 1;
            }

            .react-autosuggest__suggestions-container--open {
                box-sizing: border-box;
                border-color: @light-grey;
            }

            .react-autosuggest__suggestions-list li {
                .body-s();
                color: @dark;

                &:hover {
                    background: lighten(@light-blue, 37%);
                    color: @light-blue;
                }
            }
        }
    }
}
