.h-center {
    text-align: center;
}

.block {
    width: 100%;
}

.block-m{
    width: 50%;
}

.block-l{
    width: 75%;
}

.break-word {
    word-break: break-word;
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.flex {
    display: flex;
}

.flex-colomn{
    display: flex;
    flex-direction: column;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-baseline {
    align-items: baseline;
}

.space-between {
    justify-content: space-between;
}

.align-center {
    align-items: center;
}

.pointer {
    cursor: pointer;
}

.right {
    text-align: right;
}

.dark-bg {
    background-color: @dark;
    color: white;

    .tile-body {
        color: black;
    }
}

.black-bg {
    background-color: black;
    color: white;
}

.ptn-shaped {
    border-radius: 50%;
    border-bottom-right-radius: 0;
}

@small: 10px;
@xs: @small/2;
@normal: 20px;
@medium: 30px;
@large: 40px;
@xl: 60px;

.margin {
    .margin-top();
    .margin-bottom();
}
.margin-xs {
    margin-top: @xs;
    margin-bottom: @xs;
}
.margin-s {
    margin-top: @small;
    margin-bottom: @small;
}
.margin-l {
    margin-top: @large;
    margin-bottom: @large;
}
.margin-xl {
    margin-top: @xl;
    margin-bottom: @xl;
}
.margin-top {
    margin-top: @normal !important;
}
.margin-top-m {
    margin-top: @normal*3/4 !important;
}
.margin-top-s {
    margin-top: @normal/2 !important;
}
.margin-top-l {
    margin-top: @large;
}
.margin-top-xl {
    margin-top: @xl;
}
.margin-bottom-s {
    margin-bottom: @small;
}
.margin-bottom {
    margin-bottom: @normal;
}
.margin-bottom-m {
    margin-bottom: @medium;
}
.margin-bottom-xl {
    margin-bottom: @xl;
}
.margin-left-s {
    margin-left: @small;
}
.margin-left {
    margin-left: @normal;
}
.margin-right {
    margin-right: @normal;
}
.margin-right-l {
    margin-right: @large;
}
.margin-large {
    margin-top: @large;
    margin-bottom: @large;
}
.margin-large-top {
    margin-top: @large;
}
.margin-large-bottom {
    margin-bottom: @large;
}

.padding-v-xs {
    padding-top: @xs;
    padding-bottom: @xs;
}

.padding-h {
    padding-left: @normal;
    padding-right: @normal;
}

.padding-h-xl {
    padding-left: @xl;
    padding-right: @xl;
}

.no-margin {
    margin: 0 !important;
}

.no-margin-top {
    margin-top: 0 !important;
}

.no-margin-bottom {
    margin-bottom: 0 !important;
}

.padding-top {
    padding-top: @normal;
}
.padding-left {
    padding-left: @normal;
}

.no-padding {
    padding: 0 !important;
}

.no-padding-top {
    padding-top: 0 !important;
}

.no-padding-bottom {
    padding-bottom: 0 !important;
}

.padding-bottom {
    padding-bottom: @normal;
}

.padding {
    .padding-top();
    .padding-bottom();
}

.bottom-overlay {
    &:before { // bottom overlay
        content: '';
        position: absolute;
        width: 100%;
        bottom: 0;
        height: 70px;
        background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.7));
    }
}

.custom-scroll {
    padding-right: 10px;

    scrollbar-color: @light-blue #eee;

    &::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #eee;
        border-radius: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: @light-blue;
        border-radius: 5px;
    }
}

.cols-3 {
    columns: 3;
}

.cap-first-letter {
    display: inline-block;
    &:first-letter {
        text-transform: uppercase;
    }
}

@media (max-width: @breakpoint-xsmall-max) {
    .no-scroll-xs {
        overflow-y: hidden;
    }
}

.text-blue{
    color: @light-blue
}
