.article {
    color: black;

    .published {
        font-size: 13px;
        color: @dark-blue;
        margin-bottom: 2em;
    }

    h1 {
        .article-inner-title();
    }

    p {
        .article-body();
    }

    .article-footer {
        border-top: 1px solid @light-grey;
        margin-top: 30px;
        padding-top: 1em;
        text-align: center;
        & > span {
            padding: 1em 3em 0;
        }
    }
}

// FEEDS (comments, etc...)
.feed-item {
    position: relative;
    display: flex;
    justify-content: space-between;
    color: black;
    margin: 20px 0;
    overflow: hidden;

    &.let-overflow {
        overflow: visible;
    }

    &>* {
        width: calc(~"100% - 80px");
    }

    /* TODO: mutualize (cf. tribute-writing-bloc for example) ? */
    .action-icons {
        @icon-width: 20px;
        position: absolute;
        top: 0;
        right: 0;
        width: @icon-width;
        text-align: right;

        a, button {
            padding: 0;
            display: inline-block;
             &:not(:first-child) {
                 margin-top: 10px;
             }

             img {
                 width: @icon-width;
             }
        }
    }

    .certification {
        padding-left: 7px;
        width: 1rem;
        height: 1rem;
    }

    &.write-comment {
        margin-bottom: 20px;
        overflow: visible;
        .textarea {
            width: 100%;
            font-size: 16px;
            margin-top: 3px;
        }
        .comment-form {
            position: relative;

            .custom-upload {
                /*position: absolute;
                top: 14px;
                right: 14px;
                width: 25px;*/
                margin-top: 4px;
                height: 25px;
            }
        }


    }

    .profile-pic {
        width: 60px;
        height: 60px;
    }

    .username {
        margin-left: 8px;
        color: @grey;
    }

    .item-title {
        .title-4();
        border: none;
        margin-bottom: 0;
    }

    p {
        margin: 4px 0;
        white-space: pre-wrap;
    }

    p + .item-title {
        margin-top: 0;
    }

    a:hover {
        color: @dark-blue;
    }

    .feed-footer {
        margin-top: 10px;
        border-top: 1px solid black;
        padding-top: 10px;

        &>*:not(:last-child) {
            display: inline-block;
            margin-right: 70px;
        }

        .tile {
            /* an embedded tile in feed-footer = comments -> reduce padding to get more room for content*/
            margin-top: 10px;
            box-sizing: border-box;
            .tile-body {
                padding: 0 15px;
            }
        }

    }

    &.small {
        margin: 0;
        justify-content: flex-start;
        .profile-pic {
            @size: 35px;
            width: @size;
            height: @size;
            margin-right: 10px;
        }
        p {
            margin-bottom: 0;

            & + p {
                margin-top: 0;
            }
        }

    }
    &.card-display {
        display: block;
        margin: 0;
        .body-m();

        & > * {
            width: 100%;
        }

        .item-head {
            display: flex;
            align-items: center;

            .profile-pic {
                margin-right: 10px;
            }
        }

        .item-title {
            font-size: 16px;
        }

        p {
            .body-m();
        }

        .feed-footer {
            display: flex;
            justify-content: space-between;

            border-color: @light-grey;

            & > * {
                margin: 0;
            }
        }
    }
}

.feed-list {
    .feed-item {
        position: relative;
        background: white;
        padding: 20px;
        margin: 0 0 1px;
    }

    & > a:hover {
        text-decoration: none;
    }
}

.bio {
    .body-s();
    display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; 
  overflow: hidden;
}