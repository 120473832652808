.notification-container {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999999;
    width: 320px;
    padding: 0px 15px;
    max-height: calc(100% - 30px);
    overflow-x: hidden;
    overflow-y: auto;
}

.notification {
    box-sizing: border-box;
    padding: 15px;
    border-radius: 5px;
    color: #fff;
    background-color: #ccc;
    cursor: pointer;
    .body();
    position: relative;
    opacity: 0.9;
    margin-top: 15px;
}

.notification .title {
    .body();
}

.notification:hover, .notification:focus {
    opacity: 1;
}

.notification-enter {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
}

.notification-enter.notification-enter-active {
    visibility: visible;
    transform: translate3d(0, 0, 0);
    transition: all 0.4s;
}

.notification-leave {
    visibility: visible;
    transform: translate3d(0, 0, 0);
}

.notification-leave.notification-leave-active {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
    transition: all 0.4s;
}

.notification:before {
    position: absolute;
    top: 50%;
    left: 15px;
    margin-top: -14px;
    display: block;
    font-family: 'Notification';
    width: 28px;
    height: 28px;
    font-size: 28px;
    text-align: center;
    line-height: 28px;
}

.notification-info {
    background-color: @dark-blue;
}

.notification-info:before {
    content: "";
}

.notification-success {
    background-color: @light-blue;
}

.notification-warning {
    background-color: #f89406;
}

.notification-error {
    background-color: @red;
}
