@sidebar-width: 300px;

.sidebar {
    box-sizing: border-box;
    width: @sidebar-width;
    float: left;
    padding: 0;

    & + .main-content {
        padding-left: @sidebar-width + 20px;
    }

    &.sidebar-right {
        float: right;
    }

    &.sidebar-ptn {
        margin-top: -45px;
    }

    &.sidebar-collapsible {
        &.collapsed {
            width: 45px;
            transition: width 0.2s linear;
            & > *:not(.sidebar-profile) {
                display: none;
            }
            .sidebar-profile {
                height: 45px;
                box-sizing: border-box;
                &> *:not(.collapse-btn) {
                    display: none;
                }
            }
            .collapse-btn {
                img {
                    transform: rotate(180deg);
                }
            }
            & + .main-content {
                padding-left: 65px;
                padding-right: 65px;
            }
        }
    }
}

.main-content.with-sidebar-right {
    padding-right: @sidebar-width + 20px;
}

.sidebar-head {
    width: 100%;
    height: 250px;
    background-position: center center;
    background-size: cover;
}

.sidebar-block {
    position: relative;
    background-color: white;
    padding: 20px;
    color: @dark;

    &+.sidebar-block {
        margin-top: 20px;
    }

    &.fixed-ratio {

        .block-ratio {
            width: 100%;
        }
        .block-content {
            position: absolute;
            top: 20px;
            right: 20px;
            bottom: 20px;
            left: 20px;
            border: 1px solid white;
            padding: 20px;
            display: flex;
            align-items: center;
        }
        .block-title {
            border: none;
        }
    }

    .block-title {
        text-transform: uppercase;
        font-size: 16px;
        text-align: center;
        letter-spacing: 0.1em;
        .semibold();
        padding-bottom: 10px;
        border-bottom: 1px solid @dark;
        margin-bottom: 10px;

        &.no-border {
            border: none;
        }
        &.left-aligned {
            text-align: left;
        }
        & + .block-title {
            margin-top: 20px;
        }

        a {
            display: block;
            margin: 7px 0;
            .body-s();
            letter-spacing: initial;
            text-transform: none;
            text-decoration: none;
        }

        & + .content-card {
            margin-top: 20px;
        }
    }

    &.dark {
        background-color: @dark;
        color: white;

        .block-title {
            border-color: white;
        }
    }

    &.dark-blue {
        .dark();
        background-color: @dark-blue;
    }

    &.night-blue {
        .dark();
        background-color: @night-blue;
    }

    &.secondary {
        text-align: center;
        padding: 40px;
        .block-title {
            color: @light-blue;
            border-color: @light-blue;
            margin-bottom: 20px;
        }
        p {
           font-size: 14px;
           color: block;
           .regular();
           margin-bottom: 20px;
        }
    }

    .content-card {
        &:not(:first-of-type) {
            margin-top: 20px;
        }

        & + .btn {
            margin-top: 20px;
        }
    }

}

.sidebar-sep {
    height: 1px;
    width: 100%;
    background-color: #ddd;
    margin: 20px 0;
}

.sidebar-profile {
    position: relative;
    z-index: 2;

    .profile-pic {
        margin: -40% auto 20px;
    }
    .collapse-btn {
        display: inline-block;
        position: absolute;
        padding: 0;
        top: 8px;
        right: 6px;
        cursor: pointer;

        img {
            height: 25px !important;
            transform: rotate(0);
            transition: transform 0.3s linear;
        }
    }
    .name {
        .semibold();
        font-size: 18px;
        text-align: center;

        img {
            padding-left: 7px;
            width: 1rem;
            height: 1rem;
        }
    }
    .username {
        text-align: center;
        font-size: 14px;
        color: grey;
        margin: 4px;
    }
    .city {
        font-size: 14px;
        .light();
        text-transform: uppercase;
        text-align: center;
    }
    .desc {
        font-size: 14px;
        .light();
        line-height: 1.5em;
    }
    .socials {
        margin-top: 14px;

        & > div {
            margin: 5px 0;
        }

        a {
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            .body-m();
            &:not(:first-child) {
                margin-left: 12px;
            }
        }

        img {
            width: 24px;
        }

        span {
            display: inline-block;
            width: 100%;
        }
    }
    .stats {
        margin-bottom: 40px;
        p {
            color: @dark-blue;
            text-transform: uppercase;
            .regular();
            font-size: 14px;
            line-height: 1.5em;
            margin: 0;
        }
        a {
            text-decoration: none;
        }
    }
    .affinity {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 @xs;
        font-size: 14px;
        .icon {
            width: 1em;
            height: 1em;
            margin: 0 @xs;
        }
    }
}
