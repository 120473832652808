/* ui-kit framework */
@import "ui-kit-css-framework/ui-kit-variables.less";
@import "ui-kit-css-framework/ui-kit-container.less";
@import "ui-kit-css-framework/ui-kit-grid.less";
@import "ui-kit-css-framework/ui-kit-width.less";
@import "ui-kit-css-framework/ui-kit-visibility.less";

/* Generic */
@import "layout.less";
@import "colors.less";
@import "fonts.less";
@import "typos.less";
@import "buttons.less";
@import "form.less";
@import "helpers.less";
@import "animation.less";
@import "carousel.less";
@import "cards.less";
@import "tabs.less";
@import "components.less";
@import "images.less";
@import "contents.less";
@import "popup.less";


/* Specific */
@import "ui-kit.less";
@import "header.less";
@import "footer.less";
@import "home.less";
@import "notification.less";
@import "autosuggest.less";
@import "messages.less";
@import "search.less";
@import "parallax.less";

html, body {
    margin: 0;
    padding: 0;
}
