/* slick js library overrides*/
.slick-slider div { transition: none; } // important fix: infinite navigation bug on slick.js library

@arrow-size: 60px;

.slick-slider {
    .slick-prev, .slick-next {
        background-size: cover;
        z-index: 2;
        width: @arrow-size;
        height: 60px;
        background-color: @dark;
        background-position: center center;
        &:before {
            display: none;
        }
        &:hover {
            background-color: @light-blue;
        }
        &.slick-disabled {
            pointer-events: none;
            opacity: 0.2;
        }
    }
    
    .slick-prev {
        left: 0;
        background-image: url('../img/slider-prev.png');
        &:hover {
            background-position: -5px;
        }
    }
    .slick-next {
        right: 0;
        background-image: url('../img/slider-next.png');
        &:hover {
            background-position: 5px;
        }
    }

    @dots-bottom: 40px;
    &.slick-dotted {
        margin-bottom: @dots-bottom + 40px;
    }
    
    .slick-dots {
        bottom: -@dots-bottom;
        li {
            width: 10px;
            height: 10px;
            border: 1px solid white;
            border-radius: 50%;
    
            &:hover {
                background: white;
            }
    
            &.slick-active {
                background: @light-blue;
                border-color: @light-blue;
            }
    
            button:before { 
                display: none;
            }
        }
    }

    &.block-content-slider {
        padding: 0 10px;
        .slick-prev, .slick-next {
            background-color: transparent;
        }
        .slick-prev {
            left: -35px;
        }
        .slick-next {
            right: -35px;
        }
        .slick-slide {
            padding: 0 5px 20px;
        }
        .cta {
            height: 450px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.featured-carousel {

    @height: 540px;

    position: relative;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.5);

    height: @height;
    overflow: hidden;

    @bottom-spacing: 30px;
    .caption {
        left: @arrow-size + 20px;
        top: 50%;
        margin-top: -@arrow-size/2;

        &.simple-caption {
            bottom: @bottom-spacing;
            left: 40px;
            top: auto;
            margin-top: 0;
        }
    }

    

    .slick-prev, .slick-next {
        opacity: 0.6;

        &:hover {
            opacity: 1;
        }
    }

    &.slick-dotted {
        margin-bottom: 0;
    }

    .slide {
        position: relative;
        height: @height;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }

    .uk-container {
        position: relative;
        height: 100%;
    }

    /* slick js library overrides*/
    .slick-dots {
        bottom: @bottom-spacing;
        z-index: 1;
    }
}

.thumbs-carousel {
    .card + .card {
        margin-left: 20px;
    }
    .slick-track {
        padding-bottom: 5px; // create room for the thumbs' box shadows
        padding-top: 20px; // create room for the thumbs' hover animation
    }
}